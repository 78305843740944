import { AfterViewChecked, AfterViewInit, Component, DestroyRef, ElementRef, ViewChild } from '@angular/core';
import { PresetsService } from '../../service/presets.service';
import { MatIconModule } from '@angular/material/icon';
import { DisplayType, Pickable, UpdateDestination, UpdateType } from '../../vo/Pickers';
import { fromEvent } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AppService } from '../../service/app.service';
import { MatMenuModule, MatMenuTrigger } from '@angular/material/menu';
import { ColorChromeModule } from 'ngx-color/chrome';
import { ColorEvent } from 'ngx-color';
import { MatSliderModule } from '@angular/material/slider';
import { FormsModule } from '@angular/forms';
import { MatDividerModule } from '@angular/material/divider';
import { HistoryRecordType, HistoryService } from '../../service/history.service';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import { ToolbarService } from '../../service/toolbar.service';
import { FontPickerComponent } from '../font-picker/font-picker.component';
import { ScrubberComponent } from '../scrubber/scrubber.component';
import { MediaType } from '../../vo/GridSection';
import { DragDrop, DragDropModule, DragRef } from '@angular/cdk/drag-drop';
import { TextDecorationComponent } from '../text-decoration/text-decoration.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-bottom-toolbar',
  standalone: true,
  imports: [TextDecorationComponent, DragDropModule, ScrubberComponent, FontPickerComponent, MatButtonToggleModule, MatIconModule, MatMenuModule, ColorChromeModule, MatSliderModule, FormsModule, MatDividerModule],
  templateUrl: './bottom-toolbar.component.html',
  styleUrl: './bottom-toolbar.component.scss'
})
export class BottomToolbarComponent implements AfterViewInit {

  public readonly MediaType = MediaType

  public readonly UpdateType = UpdateType
  public readonly DisplayType = DisplayType
  @ViewChild('secondaryNavigation', { read: ElementRef }) secondaryNavigation?: ElementRef
  @ViewChild('bottomToolbar', { read: ElementRef }) bottomToolbar?: ElementRef
  public hasDragged : boolean = false

  constructor(private matDialog : MatDialog, private dragDrop : DragDrop, private toolbarService : ToolbarService, private historyService : HistoryService, public appService : AppService, public presetsService : PresetsService, private elRef : ElementRef, private destroyRef : DestroyRef){
    this.presetsService.currentNavigationSubject.asObservable().pipe(takeUntilDestroyed(destroyRef)).subscribe((value : Pickable[]) => {
      if(value && value.length > 0){
        this.openSecondaryAction = undefined
        if(this.activeTrigger)this.activeTrigger.closeMenu()
          value.forEach((pickable : Pickable) => {
            if(pickable.isDefault){
              setTimeout(() => {
                this.setActivePrimaryNavgiation(pickable, undefined, false)
              }, 100);
            }
          })
        }else{
          this.setActivePrimaryNavgiation(undefined)
        }
    })
  }

  ngAfterViewInit(): void {
    
    fromEvent<MouseEvent>(this.elRef.nativeElement.parentElement.parentElement.parentElement, 'click').pipe(
      takeUntilDestroyed(this.destroyRef)
    ).subscribe((e : MouseEvent) => {
      const target : HTMLElement = e.target as HTMLElement
      const me = this.elRef.nativeElement as HTMLElement;
      if(target){
        if(!me.contains(target)){
          if(this.matDialog.openDialogs.length == 0)this.setActivePrimaryNavgiation(undefined)
        }else{
          if(this.activeTrigger)this.activeTrigger.closeMenu()
        }
      }
    });
    
  }

  private _activePrimaryNavgiation ?: Pickable
  public setActivePrimaryNavgiation(val : Pickable | undefined, div ?: HTMLDivElement, setToUndefined : boolean = true){
      div?.scrollIntoView({ behavior: 'smooth', inline: 'center' })
      this.openSecondaryAction = undefined
      const old = this._activePrimaryNavgiation
      
      setTimeout(() => {
        if(val == old){
          if(setToUndefined)this._activePrimaryNavgiation = undefined
        }else{
          if(val?.action && val.action.callback && !val.data){
            val.action.callback.call(val.action.scope, val.data)
          }else{
            this._activePrimaryNavgiation = val
          }
        }
      }, this._activePrimaryNavgiation != undefined ? 0 : 0);
      if(setToUndefined)this._activePrimaryNavgiation = undefined
  }

  public get showSecondary():boolean{
    return this.openSecondaryAction != undefined && (this.openSecondaryAction.action?.updateType == UpdateType.SLIDER || this.openSecondaryAction.action?.updateType == UpdateType.BUTTON_GROUP)
  }

  public openSecondaryAction ?: Pickable
  private activeTrigger ?: MatMenuTrigger
  public secondaryNavigationClicked(pickable : Pickable, colorTrigger?:MatMenuTrigger, multipleChoiceTrigger?:MatMenuTrigger, fontTrigger?:MatMenuTrigger, div ?: HTMLDivElement){
    div?.scrollIntoView({ behavior: 'smooth', inline: 'center' })
    //if there is an action, but no data, we'll assume that they want the value of a change sent back to the callback
    if(pickable?.action){
      if(pickable.action.callback && pickable.data){
        pickable.action.callback.call(pickable.action.scope, pickable.data)
        setTimeout(() => {
          this.setActivePrimaryNavgiation(undefined)
        }, 100);
      }else if(pickable.action.updateType){
        if(this.activeTrigger)this.activeTrigger.closeMenu()
          //this.openSecondaryAction = undefined
        this.openSecondaryAction = pickable
        switch(pickable.action.updateType){
          case UpdateType.COLORS:
            this.activeTrigger = colorTrigger
            colorTrigger?.openMenu()
            break
          case UpdateType.SLIDER:
            break
          case UpdateType.MENU:
          case UpdateType.FONT_DECORATIONS:
            this.activeTrigger = multipleChoiceTrigger
            multipleChoiceTrigger?.openMenu()
            break
          case UpdateType.FONT_PICKER:
            this.activeTrigger = fontTrigger
            fontTrigger?.openMenu()
            break
        }
      }
    }else{
      throw new Error('You forgot to config an action for your pickable')
    }
  }

  getPropByPickable(pickable ?: Pickable): any {
    if (!pickable)return null
    // Access the property based on the pickable object
    switch (pickable.action?.updateDestination) {
      case UpdateDestination.GRID:
        return pickable.action.updateProp ? (this.appService.gridSection as any)[pickable.action.updateProp] : null
      case UpdateDestination.ASSET:
        const toReturn = pickable.action.updateProp ? (this.appService.gridSection?.activeAsset?.data as any)[pickable.action.updateProp] : null
        return toReturn
        break
    }
  }

  public updatePropWithPickable(pickable ?: Pickable, val ?: any){
    if(!pickable || !val)return
    this.historyService.createHistoryEntry(HistoryRecordType.ASSET_PROPERTY, pickable.action?.updateProp)
    switch(pickable.action?.updateDestination){
      case UpdateDestination.ASSET:
        if(pickable.action.updateProp && !pickable.action.callback && !pickable.action.scope){
          if(this.appService.gridSection?.activeAsset)if (pickable.action.updateProp == 'textStrokeColor' && (this.appService.gridSection?.activeAsset.data.textStrokeWidth == 0 || this.appService.gridSection.activeAsset.data.textStrokeWidth == undefined)) this.appService.gridSection.activeAsset.data.textStrokeWidth = 1
          if(this.appService.gridSection?.activeAsset)if (pickable.action.updateProp == 'borderColor' && (this.appService.gridSection?.activeAsset.data.borderWidth == 0 || this.appService.gridSection?.activeAsset.data.borderWidth == undefined)) this.appService.gridSection.activeAsset.data.borderWidth = 1
          if(this.appService.gridSection?.activeAsset?.data)(this.appService.gridSection?.activeAsset.data as any)[pickable.action.updateProp] = val
          this.toolbarService.assetPropertyUpdatedSubject.next({ prop: pickable.action?.updateProp, value: val })
        }else if(pickable.action.callback && pickable.action.scope){
          pickable.action.callback.call(pickable.action.scope, val)
        }
        break
      case UpdateDestination.GRID:
        if(pickable.action.updateProp && !pickable.action.callback && !pickable.action.scope){
          (this.appService.gridSection as any)[pickable.action.updateProp] = val
        }else if(pickable.action.callback && pickable.action.scope){
          pickable.action.callback.call(pickable.action.scope, val)
        }
        break
      default:
        if(pickable.action?.callback && pickable.action.scope){
          pickable.action.callback.call(pickable.action.scope, val)
        }else{
          throw new Error("You forgot to set an update destination, you also have no scope or callback defined")
        }
    }
  }

  public getRGBWithAlphaFromColorChangeEvent(event: ColorEvent) {
    let hexValue = event.color.hex || '';
    
    if (hexValue.length === 7) {
      const alphaHex = Math.round(event.color.rgb.a * 255).toString(16).padStart(2, '0');
      hexValue += alphaHex;
    }
    
    return hexValue;
  }

  public get activePrimaryNavgiation() : Pickable | undefined{
    return this._activePrimaryNavgiation
  }

  public get secondaryNavigationCanScrollLeft(): boolean {
    if (!this.secondaryNavigation) return false;

    const element = this.secondaryNavigation.nativeElement as HTMLElement;
    return element.scrollLeft > 0;
  }

  public get secondaryNavigationCanScrollRight(): boolean {
    if (!this.secondaryNavigation) return false;

    const element = this.secondaryNavigation.nativeElement as HTMLElement;
    return element.scrollWidth > element.clientWidth + element.scrollLeft;
  }

  public get bottomToolbarCanScrollLeft(): boolean {
    if (!this.bottomToolbar) return false;

    const element = this.bottomToolbar.nativeElement as HTMLElement;
    return element.scrollLeft > 0;
  }

  public get bottomToolbarCanScrollRight(): boolean {
    if (!this.bottomToolbar) return false;

    const element = this.bottomToolbar.nativeElement as HTMLElement;
    return element.scrollWidth > element.clientWidth + element.scrollLeft;
  }

}