import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DankTankAuthService } from './dank-tank-auth.service';
import { APIName, HTTPMethods, ServiceLookupService } from './service-lookup.service';
import { catchError, map, Observable, of, take } from 'rxjs';
import { ServiceResult, transformError } from './result';

export interface IntentResponse {
  clientSecret: string
}

export enum SubscriptionType{
  PRO_YEARLY = "Pro Yearly",
  PRO_MONTHLY = "Pro Monthly",
  BASIC_YEARLY = "Basic Yearly",
  BASIC_MONTHLY = "Basic Monthly",
  NONE = 'None'
}

export enum SubscriptionStatus{
  ACTIVE = 'active',
  PAST_DUE = 'past_due',
  PENDING = 'pending',
  CANCELED = 'canceled',
  FREE = 'free'
}

export interface Subscription{
  type : SubscriptionType,
  monthlyPrice ?: number,
  status ?: SubscriptionStatus
}

@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  

  constructor(private http: HttpClient, private authService: DankTankAuthService, private serviceLookup: ServiceLookupService) { }

  //these allow authThen to work as it takes an array of subscription types that can use a feature
  public getAllPaid() : SubscriptionType[]{
    return [SubscriptionType.BASIC_MONTHLY, SubscriptionType.BASIC_YEARLY, SubscriptionType.PRO_MONTHLY, SubscriptionType.PRO_YEARLY]
  }

  public getPro() : SubscriptionType[]{
    return [SubscriptionType.PRO_MONTHLY, SubscriptionType.PRO_YEARLY]
  }

  public static getSubscription(type : SubscriptionType, status ?: string) : Subscription{
    let subStatus : SubscriptionStatus | undefined = undefined
    if(status){
      switch(status){
        case 'active':
          subStatus = SubscriptionStatus.ACTIVE
          break
        case 'past_due':
          subStatus = SubscriptionStatus.PAST_DUE
          break
        case 'pending':
          subStatus = SubscriptionStatus.PENDING
          break
        case 'canceled':
          subStatus = SubscriptionStatus.CANCELED
          break
        case 'free':
          subStatus = SubscriptionStatus.FREE
          break
      }
    }
    switch(type){
      case SubscriptionType.PRO_MONTHLY:
        return {
          type : SubscriptionType.PRO_MONTHLY,
          monthlyPrice : 6.95,
          status : subStatus
        }
        break
      case SubscriptionType.PRO_YEARLY:
        return {
          type : SubscriptionType.PRO_YEARLY,
          monthlyPrice : 5.95,
          status : subStatus
        }
        break
      case SubscriptionType.BASIC_MONTHLY:
        return {
          type : SubscriptionType.BASIC_MONTHLY,
          monthlyPrice : 3.95,
          status : subStatus
        }
        break
      case SubscriptionType.BASIC_YEARLY:
        return {
          type : SubscriptionType.BASIC_YEARLY,
          monthlyPrice : 3.35,
          status : subStatus
        }
        break
      default:
        return{
          type : SubscriptionType.NONE,
          status : subStatus
        }
    }
  }

  public get isPremium() : boolean{
    return (this.authService.subscriptionUserSubject && this.authService.subscriptionUserSubject.value && (this.authService.subscriptionUserSubject.value.data?.subscription.status == SubscriptionStatus.ACTIVE || this.authService.subscriptionUserSubject.value.data?.subscription.status == SubscriptionStatus.FREE) && (this.authService.subscriptionUserSubject.value.data?.subscription.status == SubscriptionStatus.ACTIVE || this.authService.subscriptionUserSubject.value.data?.subscription.type != SubscriptionType.NONE)) || false
  }

  public initiate(subscription : Subscription) : Observable<ServiceResult<IntentResponse>> | undefined {
    const uri = this.serviceLookup.lookup(APIName.INITIATE_PAYMENT, HTTPMethods.GET)
    //this will try it three times if theres an error
    const token = this.authService.sessionSubject.value?.access_token;

    if (!token) return undefined

    const headers = new HttpHeaders({
      Authorization: `Bearer ${token}`
    });

    const body = {
      plan : subscription.type.toString()
    }

    return this.http.post<ServiceResult<IntentResponse>>(uri, body, { headers })
      .pipe(
        take(1),
        map(response => ({data : response.data})),
        catchError(error => of(transformError<IntentResponse>(error)))
      );
  }

  public upgrade(subscription : Subscription) : Observable<ServiceResult<boolean>> | undefined {
    const uri = this.serviceLookup.lookup(APIName.UPGRADE_SUBSCRIPTION, HTTPMethods.POST)

    const token = this.authService.sessionSubject.value?.access_token;

    if (!token) return undefined

    const headers = new HttpHeaders({
      Authorization: `Bearer ${token}`
    });

    const body = {
      plan : subscription.type.toString()
    }

    return this.http.post<ServiceResult<boolean>>(uri, body, { headers })
      .pipe(
        take(1),
        map(response => ({data : response.data})),
        catchError(error => of(transformError<boolean>(error)))
      );
  }

  public getPortalURL() : Observable<ServiceResult<string>> | undefined {
    const uri = this.serviceLookup.lookup(APIName.GET_PORTAL_URL, HTTPMethods.POST)
    
    const token = this.authService.sessionSubject.value?.access_token;

    if (!token) return undefined

    const headers = new HttpHeaders({
      Authorization: `Bearer ${token}`
    });

    return this.http.post<ServiceResult<string>>(uri, {}, { headers })
    .pipe(
      take(1),
      map(response => ({data : response.data})),
      catchError(error => of(transformError<string>(error)))
    );

  }


  public applyDiscountCode(code : string) : Observable<ServiceResult<string>> | undefined {
    const uri = this.serviceLookup.lookup(APIName.APPLY_DISCOUNT, HTTPMethods.POST)
    
    const token = this.authService.sessionSubject.value?.access_token;

    if (!token) return undefined

    const headers = new HttpHeaders({
      Authorization: `Bearer ${token}`
    });

    return this.http.post<ServiceResult<string>>(uri, {code : code}, { headers })
    .pipe(
      take(1),
      map(response => ({data : response.data})),
      catchError(error => of(transformError<string>(error)))
    );

  }
}
