var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
import { resolveFetch } from './helper';
import { FunctionsFetchError, FunctionsHttpError, FunctionsRelayError, FunctionRegion } from './types';
export class FunctionsClient {
  constructor(url, {
    headers = {},
    customFetch,
    region = FunctionRegion.Any
  } = {}) {
    this.url = url;
    this.headers = headers;
    this.region = region;
    this.fetch = resolveFetch(customFetch);
  }
  /**
   * Updates the authorization header
   * @param token - the new jwt token sent in the authorisation header
   */
  setAuth(token) {
    this.headers.Authorization = `Bearer ${token}`;
  }
  /**
   * Invokes a function
   * @param functionName - The name of the Function to invoke.
   * @param options - Options for invoking the Function.
   */
  invoke(functionName, options = {}) {
    var _a;
    return __awaiter(this, void 0, void 0, function* () {
      try {
        const {
          headers,
          method,
          body: functionArgs
        } = options;
        let _headers = {};
        let {
          region
        } = options;
        if (!region) {
          region = this.region;
        }
        if (region && region !== 'any') {
          _headers['x-region'] = region;
        }
        let body;
        if (functionArgs && (headers && !Object.prototype.hasOwnProperty.call(headers, 'Content-Type') || !headers)) {
          if (typeof Blob !== 'undefined' && functionArgs instanceof Blob || functionArgs instanceof ArrayBuffer) {
            // will work for File as File inherits Blob
            // also works for ArrayBuffer as it is the same underlying structure as a Blob
            _headers['Content-Type'] = 'application/octet-stream';
            body = functionArgs;
          } else if (typeof functionArgs === 'string') {
            // plain string
            _headers['Content-Type'] = 'text/plain';
            body = functionArgs;
          } else if (typeof FormData !== 'undefined' && functionArgs instanceof FormData) {
            // don't set content-type headers
            // Request will automatically add the right boundary value
            body = functionArgs;
          } else {
            // default, assume this is JSON
            _headers['Content-Type'] = 'application/json';
            body = JSON.stringify(functionArgs);
          }
        }
        const response = yield this.fetch(`${this.url}/${functionName}`, {
          method: method || 'POST',
          // headers priority is (high to low):
          // 1. invoke-level headers
          // 2. client-level headers
          // 3. default Content-Type header
          headers: Object.assign(Object.assign(Object.assign({}, _headers), this.headers), headers),
          body
        }).catch(fetchError => {
          throw new FunctionsFetchError(fetchError);
        });
        const isRelayError = response.headers.get('x-relay-error');
        if (isRelayError && isRelayError === 'true') {
          throw new FunctionsRelayError(response);
        }
        if (!response.ok) {
          throw new FunctionsHttpError(response);
        }
        let responseType = ((_a = response.headers.get('Content-Type')) !== null && _a !== void 0 ? _a : 'text/plain').split(';')[0].trim();
        let data;
        if (responseType === 'application/json') {
          data = yield response.json();
        } else if (responseType === 'application/octet-stream') {
          data = yield response.blob();
        } else if (responseType === 'text/event-stream') {
          data = response;
        } else if (responseType === 'multipart/form-data') {
          data = yield response.formData();
        } else {
          // default to text
          data = yield response.text();
        }
        return {
          data,
          error: null
        };
      } catch (error) {
        return {
          data: null,
          error
        };
      }
    });
  }
}
