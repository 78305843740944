import { Directive, ElementRef, Input, OnInit, Renderer2, OnDestroy } from '@angular/core';

@Directive({
  selector: '[appLazyLoad]',
  standalone: true
})
export class LazyLoadDirective implements OnInit, OnDestroy {
  @Input('appLazyLoad') src?: string;
  @Input('rootElement') rootElement: Element | null = null; // Input for custom root element
  private observer?: IntersectionObserver;
  private timeoutId?: number; // For managing the delay

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngOnInit() {
    if (this.src) {
      this.initObserver();
    } else {
      console.warn('LazyLoadDirective: src is not defined.');
    }
  }

  ngOnDestroy() {
    if (this.observer) {
      this.observer.disconnect();
    }
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
  }

  private initObserver() {
    const options = {
      root: this.rootElement,
      rootMargin: '100px', // Margin before image is in view
      threshold: 0.1
    };

    this.observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          // Set a timeout to delay loading
          this.timeoutId = window.setTimeout(() => {
            if (entry.isIntersecting) { // Double check that the element is still in view
              console.log('Image is in view for a sufficient time, loading:', this.src);
              this.loadImage();
              this.observer?.disconnect();
            }
          }, 200); // Adjust the delay time as needed
        } else {
          // Clear timeout if image is not in view
          if (this.timeoutId) {
            clearTimeout(this.timeoutId);
          }
        }
      });
    }, options);

    this.observer.observe(this.el.nativeElement);
  }

  private loadImage() {
    if (this.src) {
      this.renderer.setAttribute(this.el.nativeElement, 'src', this.src);
    } else {
      console.warn('LazyLoadDirective: src is not defined when trying to load the image.');
    }
  }
}