import { HttpInterceptorFn, HttpRequest, HttpHandlerFn, HttpEvent, HttpResponse } from '@angular/common/http';
import { inject } from '@angular/core';
import { Observable, of } from 'rxjs';
import { delay, tap } from 'rxjs/operators';
import { APIName, HTTPMethods, ServiceLookupService } from '../service/service-lookup.service';

const cache = new Map<string, HttpResponse<any>>();

export const cacheInterceptor: HttpInterceptorFn = (req: HttpRequest<any>, next: HttpHandlerFn): Observable<HttpEvent<any>> => {

  const serviceLookup: ServiceLookupService = inject(ServiceLookupService);

  const dontCache: String[] = [serviceLookup.lookup(APIName.GET_SUBSCRIPTION_USER, HTTPMethods.GET), serviceLookup.lookup(APIName.ALL_USER_IMAGES, HTTPMethods.GET)];

  if (req.method !== 'GET') {
    return next(req);
  }

  const shouldNotCache = dontCache.some((url : any) => req.urlWithParams.includes(url));
  if (shouldNotCache) {
    return next(req);
  }

  const cachedResponse = cache.get(req.urlWithParams);
  if (cachedResponse) {
    return of(cachedResponse.clone()).pipe(delay(75));
  }

  return next(req).pipe(
    tap(event => {
      if (event instanceof HttpResponse) {
        cache.set(req.urlWithParams, event.clone());
      }
    })
  );
};