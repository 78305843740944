import { AfterViewInit, Component, Input, input, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDivider } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { LoggedInUserComponent } from '../logged-in-user/logged-in-user.component';
import { AppMenuComponent } from '../app-menu/app-menu.component';
import { HistoryService } from '../../service/history.service';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SaveAndGenerateComponent } from '../save-and-generate/save-and-generate.component';
import { AppService } from '../../service/app.service';
import { Router } from '@angular/router';
import { ScreenAction } from '../../service/analytics.service';
import { MatDialog } from '@angular/material/dialog';
import { ImageGenerationInfo, ImageGeneratorService } from '../../service/image-generator.service';
import { take } from 'rxjs';
import { MediaType, Mode } from '../../vo/GridSection';
import { DownloadImageComponent } from '../download-image/download-image.component';
import { ToastService } from '../../service/toast.service';

@Component({
  selector: 'app-top-toolbar',
  standalone: true,
  imports: [SaveAndGenerateComponent, MatTooltipModule, AppMenuComponent, LoggedInUserComponent, MatToolbarModule, MatIconModule, MatButtonModule, MatDivider],
  templateUrl: './top-toolbar.component.html',
  styleUrl: './top-toolbar.component.scss'
})
export class TopToolbarComponent implements AfterViewInit {

  @ViewChildren('appMenu') appMenus?: QueryList<AppMenuComponent>

  public readonly Math = Math

  public collapsed: boolean = false

  constructor(private toast: ToastService, private imageGenerator: ImageGeneratorService, private dialog: MatDialog, public historyService: HistoryService, public appService: AppService, private router: Router) {

  }

  private _isLandingToolbar: boolean = false
  @Input() public set isLandingToolbar(val: boolean) {
    this._isLandingToolbar = val
    //if(this.appMenu)this.appMenu.isLandingToolbar = val
  }

  public get isLandingToolbar(): boolean {
    return this._isLandingToolbar
  }

  ngAfterViewInit(): void {
    if (this.appMenus) {
      this.appMenus.changes.subscribe(() => {
        if (this.appMenus) this.appMenus.forEach((menu) => {
          menu.isLandingToolbar = this.isLandingToolbar
        })
      })
      this.appMenus.forEach((menu) => {
        menu.isLandingToolbar = this.isLandingToolbar
      })
    }
  }

  public goHome() {
    this.router.navigate(['landing'])
  }



}
