import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot, Routes } from '@angular/router';
import { DashboardComponent } from './component/dashboard/dashboard.component';
import { MetaService } from './service/meta.service';
import { firstValueFrom, Observable, of, switchMap, take } from 'rxjs';
import { Injectable } from '@angular/core';
import { backAndForwardGuard } from './util/back-and-forward.guard';
import { LandingPageComponent } from './component/landing-page/landing-page.component';
import { MmodularDashboardComponent } from './component/mmodular-dashboard/mmodular-dashboard.component';


export const routes: Routes = [
    {path: 'landing', component:LandingPageComponent},
    //{path: 'generator/:id', component:DashboardComponent},
    //{path: 'generator/meme', component:DashboardComponent, canDeactivate: [backAndForwardGuard]},
    //{path: 'generator/meme/:id', component:DashboardComponent, canDeactivate: [backAndForwardGuard]},
    {path: 'generator/meme', component:MmodularDashboardComponent, canDeactivate: [backAndForwardGuard]},
    {path: 'generator/meme/:id', component:MmodularDashboardComponent, canDeactivate: [backAndForwardGuard]},
    {path: '', redirectTo: 'landing', pathMatch: 'full'},
    { path: '**', redirectTo: 'landing', pathMatch: 'full' },
];
