<div class="top-row pointer" [style.height.px]="collapsed || minimized ? 35 : ((scrubberService.info.value?.activeGrid?.assets?.length || 0) * 31.5) + 35">
  
    <div class="asset-header" cdkDragHandle>
      <mat-icon class="icon-spacer">drag_indicator</mat-icon>
      @if((scrubberService.info.value?.activeGrid?.assets?.length || 0) > 0){
        <strong class="icon-spacer">Assets</strong>
        @if(!minimized){
          @if(!collapsed){
            <mat-icon class="pointer icon-spacer" (click)="collapsed = !collapsed">unfold_less</mat-icon>
          }
          @if(collapsed){
            <mat-icon class="pointer icon-spacer" (click)="collapsed = !collapsed">unfold_more</mat-icon>
          }
          @if(!scrubberService.info.value?.activeGrid?.activeAsset){
            <mat-chip class="timer-chip icon-spacer">
              <small class="timer">{{scrubberService.info.value?.friendlyTime}} / {{scrubberService.info.value?.friendlyDuration}}</small>
            </mat-chip>
          }
          @if(scrubberService.info.value?.activeGrid?.activeAsset){
            <div class="spacer"></div>
            <mat-chip class="timer-chip icon-spacer">
              <small class="timer">Asset: {{scrubberService.formatTime((scrubberService.info.value?.video?.duration || 0) * ((scrubberService.info.value?.activeGrid?.activeAsset?.shownFrom || 0) / 100))}} - {{scrubberService.formatTime((scrubberService.info.value?.video?.duration || 0) * ((scrubberService.info.value?.activeGrid?.activeAsset?.shownUntil || 0) / 100))}}</small>
            </mat-chip>
          }
        <div class="flex-expand"></div>
        <mat-icon class="icon-spacer" [class]="!minimized ? 'minimize-icon' : 'maximize-icon'" (click)="minimized = !minimized">{{!minimized ? 'minimize' : 'open_in_full'}}</mat-icon>
        }@else{
          <div class="spacer"></div>
          <mat-chip class="timer-chip icon-spacer">
            <small class="timer">{{scrubberService.info.value?.friendlyTime}} / {{scrubberService.info.value?.friendlyDuration}}</small>
          </mat-chip>
          <div class="flex-expand"></div>
          <mat-icon class="icon-spacer" [class]="!minimized ? 'minimize-icon' : 'maximize-icon'" (click)="minimized = !minimized">{{!minimized ? 'minimize' : 'open_in_full'}}</mat-icon>
        }
      }@else{
        <strong class="icon-spacer">Controller</strong>
        <div class="spacer"></div>
        <mat-chip class="timer-chip icon-spacer">
          <small class="timer">{{scrubberService.info.value?.friendlyTime}} / {{scrubberService.info.value?.friendlyDuration}}</small>
        </mat-chip>
        <div class="flex-expand"></div>
        <mat-icon class="icon-spacer" [class]="!minimized ? 'minimize-icon' : 'maximize-icon'" (click)="minimized = !minimized">{{!minimized ? 'minimize' : 'open_in_full'}}</mat-icon>
      }
    </div>
    <mat-divider class="header-divider"></mat-divider>
  @for (asset of getReversedAssets(); track asset; let i = $index;) {
    @if(asset.type == AssetType.TEXT){
      <div class="asset" [class]="scrubberService.info.value?.activeGrid?.activeAsset == asset ? 'selected-asset' : null" (click)="setActiveAsset(asset)">
        <div class="asset-content-holder">
          <div class="ellipsis" [style.font-family]="asset.data.fontFamily">
            {{asset.data.text}}
          </div>
        </div>
        <div #assetTimeline class="asset-timeline">
          <div  class="asset-bg" [style.left.px]="assetBgStart(assetTimeline, asset)" [style.width.px]="assetBgEnd(assetTimeline, asset)"></div>
        </div>
        <div class="invisible-border"></div>
      </div>
    }@else if(asset.type == AssetType.STICKER || asset.type == AssetType.MEME || asset.type == AssetType.EMOJI || asset.type == AssetType.UPLOAD_IMAGE || asset.type == AssetType.SHAPE){
      <div class="asset" [class]="scrubberService.info.value?.activeGrid?.activeAsset == asset ? 'selected-asset' : null" (click)="setActiveAsset(asset)">
        <div class="asset-content-holder">
          <img #assetImg class="asset-img fade-img" (load)="assetImg.style.opacity = '1'" [src]="asset.data.location">
        </div>
        <div #assetTimeline class="asset-timeline">
          <div  class="asset-bg" [style.left.px]="assetBgStart(assetTimeline, asset)" [style.width.px]="assetBgEnd(assetTimeline, asset)"></div>
        </div>
        <div class="invisible-border"></div>
      </div>
    }@else if(asset.type == AssetType.TEXT_BUBBLE){
      <div class="asset" [class]="scrubberService.info.value?.activeGrid?.activeAsset == asset ? 'selected-asset' : null" (click)="setActiveAsset(asset)">
        <div class="asset-content-holder">
          <mat-icon style="color: white;" >chat_bubble</mat-icon>
        </div>
        <div #assetTimeline class="asset-timeline">
          <div  class="asset-bg" [style.left.px]="assetBgStart(assetTimeline, asset)" [style.width.px]="assetBgEnd(assetTimeline, asset)"></div>
        </div>
        <div class="invisible-border"></div>
      </div>
    }
    @if (i < (scrubberService.info.value?.activeGrid?.assets?.length || 0) - 1) {
      <mat-divider></mat-divider>
    }
  }
</div>
<div class="bottom-row" [style.height.px]="minimized ? 0 : 50">
  <div class="border"></div>
  @if((scrubberService.info | async)?.isPlaying){
    <!--
  <div class="svg-container pointer" (click)="scrubberService.pause()">
    <svg
    class="pause-icon"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="xMidYMid meet"
  >
    <rect x="4" y="4" width="4" height="16" class="pause-bar" />
    <rect x="12" y="4" width="4" height="16" class="pause-bar" />
  </svg>
  </div>
  -->
  <mat-icon class="pointer svg-container" (click)="scrubberService.pause()">pause_circle</mat-icon>
  }@else{
    <!--
  <div class="svg-container pointer" (click)="scrubberService.play()">
    <svg
    class="play-icon"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="xMidYMid meet"
  >
    <polygon points="6,4 18,12 6,20" class="play-shape" />
  </svg>
  </div>
  -->
  <mat-icon class="pointer svg-container" (click)="scrubberService.play()">play_circle</mat-icon>
  }
  <div #scrubberArea class="scrubber-area">
      <div #leftScrubLocation class="scrub-location-bar left" [style.display]="scrubberService.info.value?.activeGrid?.activeAsset ? null : 'none'">
        <mat-icon class="">drag_indicator</mat-icon>
      </div>
      <div #rightScrubLocation class="scrub-location-bar right" [style.display]="scrubberService.info.value?.activeGrid?.activeAsset ? null : 'none'">
        <mat-icon class="">drag_indicator</mat-icon>
      </div>
      <div class="scrubber-bg" [class]="isTouching ? null : 'scrubber-bg-animated'" [style.width.px]="scrubberWidth"></div>
  </div>
  <div class="border"></div>
  <!--
      {{scrubberService.info.value?.currentTime}} / {{scrubberService.info.value?.video?.duration}} {{scrubberService.info.value?.currentFrame}}
  -->
</div>