import { AfterViewInit, Component, HostListener } from '@angular/core';
import { GeneralJsonLoaderService, HowTo } from '../../service/general-json-loader.service';
import { take } from 'rxjs';
import { ServiceResult } from '../../service/result';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { LazyLoadDirective } from '../../directive/lazy-load.directive';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule } from '@angular/forms';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { MatButtonModule } from '@angular/material/button';

enum ImageLoadStatus {
  LOADED = "LOADED",
  ERROR = "ERROR",
}

type ImageStatusMap = Record<string, ImageLoadStatus>

@Component({
  selector: 'app-how-to',
  standalone: true,
  imports: [MatButtonModule, FormsModule, MatFormFieldModule, MatInputModule, MatTooltipModule, LazyLoadDirective, MatDialogModule, MatIconModule, MatGridListModule, MatProgressSpinnerModule],
  templateUrl: './how-to.component.html',
  styleUrl: './how-to.component.scss'
})
export class HowToComponent implements AfterViewInit {

  public readonly images: ImageStatusMap = {}
  public readonly ImageLoadStatus = ImageLoadStatus

  private _howTos ?: HowTo[]
  public howTos ?: HowTo[]

  public columns : number = 3

  private _selectedHowTo ?: HowTo
  public sanitizedVideo ?: SafeResourceUrl
  public set selectedHowTo( val : HowTo | undefined){
    this._selectedHowTo = val
    if(val)this.sanitizedVideo = this.sanitizer.bypassSecurityTrustResourceUrl(val.video)
  }
  public get selectedHowTo():HowTo | undefined{
    return this._selectedHowTo
  }

  private _searchTerm: string = ''
  public set searchTerm(t: string) {
    this._searchTerm = t
    this.howTos = this.filterVideos(t)
  }

  public get searchTerm(): string {
    return this._searchTerm
  }


  constructor(private jsonService : GeneralJsonLoaderService, public sanitizer : DomSanitizer){
    jsonService.loadHowTos()?.pipe(take(1)).subscribe((howTos : ServiceResult<HowTo[]>) => {
      this._howTos = howTos.data
      this.searchTerm = ''
    })
  }

  ngAfterViewInit(): void {
    this.onResize()
  }

  @HostListener('window:resize', ['$event'])
  onResize(event?: Event): void {
    this.columns = window.innerWidth >= 1024 ? 4 : (window.innerWidth >= 850 ? 3 : 2);
  }

  private filterVideos(searchTerm: string): HowTo[] {
    const lowerCaseTerm = searchTerm.toLowerCase();
    
    // Return the entire array if the search term is less than 3 characters
    if (searchTerm.length < 3) {
      return this._howTos || [];
    }

    // Filter items based on the search term
    const filtered = this?._howTos?.filter(howTo =>
      howTo.name.toLowerCase().includes(lowerCaseTerm) ||
      howTo.description.toLowerCase().includes(lowerCaseTerm) ||
      howTo.tags.toLowerCase().includes(lowerCaseTerm)
    );

    // If no matches, return the entire array
    return (filtered?.length || 0) > 0 ? filtered || [] : (this._howTos || [])
  }

}

