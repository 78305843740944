<!--[bounds]='{"left":0,"top":0,"right":0,"bottom":0, "position" : "css"}'-->
@if(data?.section?.mode == Mode.DEVELOPMENT && data?.section?.activeAsset == data && !data?.data?.editing && moveableAsset){
    <ngx-moveable #moveable
    #tt="matTooltip"
    [matTooltip]="tooltip"

    class="add-pointer-events"
    [target]="moveableAsset"
    [origin]="true"
    [edge]="true"

    [edgeDraggable]="false"
    [snappable]="true"
    

    [draggable]="true"
   
    [keepRatio]="assetChild?.keepRatio"

    [renderDirections]='["nw","n","ne","w","e","sw","s","se"]'

    [resizable]="true"
    [throttleResize]="0.5"


    [scalable]="false"

    [rotatable]="true"

    [warpable]="false"

    [pinchable]="true"
    
    (drag)="show(tt); onDrag($event)"
    (resize)="show(tt); onResize($event)"
    (rotate)="onRotate($event)"
    (pinch)="onPinch($event)"
    

    (dragStart)="show(tt); startInteracting('drag')"
    (dragEnd)="tt.hide(); endInteracting()"
    (rotateStart)="startInteracting()"
    (rotateEnd)="endInteracting()"
    (scaleStart)="show(tt); startInteracting('resize')"
    (scaleEnd)="tt.hide(); endInteracting()"
    (resizeStart)="show(tt); startInteracting('resize')"
    (resizeEnd)="tt.hide(); endInteracting()"
    (pinchStart)="startInteracting()"
    (pinchEnd)="endInteracting()"

    [snappable]="true"
    [snapDirections]="snapDirections"
    [verticalGuidelines]="verticalGuidelines"
    [horizontalGuidelines]="horizontalGuidelines"
    
    [useResizeObserver]="true"
    [useMutationObserver]="true"

   ></ngx-moveable>
}
@if(data?.section?.mode == Mode.DEVELOPMENT && data?.section?.activeAsset == data && !data?.data?.editing && moveableAsset && data?.data?.display != 'none'){
    <button mat-mini-fab class="clone-button"
    matTooltip="Clone" matTooltipPosition="below"
    (mousedown)="$event.stopImmediatePropagation();" (touchstart)="$event.stopImmediatePropagation();"
    (click)="$event.stopImmediatePropagation(); this.data && this.data.section?.cloneAsset(this.data, this.toolbarService)"
    [ngStyle]="{
        'transform': getTransform(),
        'position': 'absolute' 
      }"
    >
        <mat-icon>control_point_duplicate</mat-icon>
    </button>
}
<ng-content #assetChild></ng-content>