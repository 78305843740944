<mat-label style="color: white; margin-top: -50px">Edit Text</mat-label>
<mat-divider></mat-divider>
<br />
<mat-form-field appearance="outline" style="width: 100%;" (click)="$event.stopPropagation()">
  <mat-label>Text</mat-label>
  <textarea
    [(ngModel)]="data.data.text"
    #textInput
    matInput
    cdkTextareaAutosize
    cdkAutosizeMinRows="1"
    cdkAutosizeMaxRows="3"
  ></textarea>
</mat-form-field>
<mat-divider style="margin-bottom: 10px;"></mat-divider>
<button (click)="close()"
  mat-mini-fab
  style="float: right;"
  class="toolbar-button"
  matTooltip="Looks Good"
  matTooltipPosition="below"
>
  <mat-icon>check_circle</mat-icon>
</button>
