import { Injectable } from '@angular/core';
import { Asset, AssetType, GridSection, MediaType, Orientation } from '../vo/GridSection';
import { AspectRatio, DisplayType, Pickable, UpdateDestination, UpdateType } from '../vo/Pickers';
import { DomSanitizer } from '@angular/platform-browser';
import { AppService } from './app.service';
import { HistoryRecordType, HistoryService } from './history.service';
import { EditMode, EditModeData, ToolbarService } from './toolbar.service';
import { ToastService } from './toast.service';
import { MatDialog } from '@angular/material/dialog';
import { StickerBrowserComponent } from '../component/sticker-browser/sticker-browser.component';
import { BehaviorSubject, take } from 'rxjs';
import { MediaAttacherComponent } from '../component/media-attacher/media-attacher.component';
import { UploadType } from '../component/file-uploader/file-uploader.component';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { settings } from 'paper/dist/paper-core';

@Injectable({
  providedIn: 'root'
})
export class PresetsService {

  public currentNavigationSubject : BehaviorSubject<Pickable[]> = new BehaviorSubject<Pickable[]>([])

  public currentNavigation ?: Pickable[]

  public readonly primaryNavigation : Pickable<Pickable[]>[]
  public stickerNavigation : Pickable<Pickable[]>[]
  public textNavigation : Pickable<Pickable[]>[]
  public textBubbleNavigation : Pickable<Pickable[]>[]

  public readonly gridSections: Pickable<GridSection>[]

  public readonly gridColors : Pickable[]

  public readonly gridStyles : Pickable[]

  public readonly addableAssets : Pickable[]

  public readonly textColors : Pickable[]

  public readonly textSettings : Pickable[]

  public stickerSettings : Pickable[]


  public readonly aspectRatios: Pickable<AspectRatio>[]

  public readonly layouts : Pickable[] = []


  constructor(private bottomSheet : MatBottomSheet, private dialog : MatDialog, private toast : ToastService, private sanitizer : DomSanitizer, private appService : AppService, private historyService : HistoryService, private toolbarService : ToolbarService) { 
    //setup our collections
    this.gridSections  = this.setupGridsections()
    this.aspectRatios = this.setupAspectRatios()
    this.gridColors = this.setupGridColors()
    this.gridStyles = this.setupGridStyles()
    this.addableAssets = this.setupAddableAssets()
    this.stickerSettings = this.setupStickerSettings()
    this.textColors = this.setupTextColors()
    this.textSettings = this.setupTextSettings()
    this.textBubbleNavigation = this.setupTextBubbleNavigation()
    this.stickerNavigation = this.setupStickerNavigation()
    this.textNavigation = this.setupTextNavigation()
    this.primaryNavigation = this.setupPrimaryNavigation()

    //set the initial navigation
    this.currentNavigation = this.primaryNavigation

    this.toolbarService.editModeSubject.asObservable().pipe().subscribe({
      next: (data: EditModeData) => {
        switch(data.mode){
          case EditMode.BASIC:
            this.currentNavigation = this.primaryNavigation
            break
          case EditMode.STICKER:
            //regenerate these menus live since theres live data in them
            this.stickerNavigation = this.setupStickerNavigation()
            this.currentNavigation = this.stickerNavigation
            break
          case EditMode.TEXT:
            this.textNavigation = this.setupTextNavigation()
            this.currentNavigation = this.textNavigation
            break
          case EditMode.TEXT_BUBBLE:
            this.textBubbleNavigation = this.setupTextBubbleNavigation()
            this.currentNavigation = this.textBubbleNavigation
            break
        }
        if(this.currentNavigation)this.currentNavigationSubject.next(this.currentNavigation)
      }
    })
  }

  private setupTextBubbleNavigation() : Pickable<Pickable[]>[]{
    const toReturn : Pickable<Pickable[]>[] = []

    const colorSettingsArray : Pickable[] = []
    const otherSettingsArray : Pickable[] = []


    
    const backgroundColor = new Pickable('Background Color', 'palette', undefined, {updateType : UpdateType.COLORS, updateDestination : UpdateDestination.ASSET, updateProp : 'backgroundColor'})

    const opacity = new Pickable('Opacity', 'opacity', undefined, {updateType : UpdateType.SLIDER, updateDestination : UpdateDestination.ASSET, updateProp : 'opacity',  updateMin : 0, updateMax : 100, updateStep : 1})
    
    const borderWidth = new Pickable('Border Width', 'border_all', undefined, {updateType : UpdateType.SLIDER, updateDestination : UpdateDestination.ASSET, updateProp : 'borderWidth',  updateMin : 0, updateMax : 10, updateStep : .5})

    const stackingOrder = new Pickable('Stacking Order', 'layers', undefined, {updateType : UpdateType.SLIDER, updateProp : 'stackingOrder', updateDestination : UpdateDestination.ASSET, scope: this, callback: this.updateAssetStackingOrder, updateMin : 1, updateMax : this.appService.gridSection?.assets.length, updateStep : 1, shouldShowCallback : this.shouldShowStackingOrder, shouldShowScope : this})


    const bubbleStyles : Pickable<string>[] = [new Pickable<string>('Speech', 'chat_bubble', 'speech'), new Pickable<string>('Shout', 'chat_bubble', 'shout'), new Pickable<string>('Ellipse', 'chat_bubble', 'ellipse')]
    const bubbleStyle : Pickable<Pickable[]> = new Pickable('Bubble Style', 'chat_bubble', bubbleStyles)
    bubbleStyle.action = {updateType : UpdateType.MENU, updateDestination : UpdateDestination.ASSET, updateProp : 'bubbleStyle'}

    colorSettingsArray.push(backgroundColor)

    otherSettingsArray.push(opacity)
    otherSettingsArray.push(borderWidth)
    otherSettingsArray.push(bubbleStyle)
    otherSettingsArray.push(stackingOrder)


    const back : Pickable = new Pickable('Back', 'reply', undefined, {scope : this, callback : this.back})

    const colorSettings : Pickable<Pickable[]> = new Pickable('Color', 'palette', colorSettingsArray)

    const otherSettings : Pickable<Pickable[]> = new Pickable('Settings', 'tune', otherSettingsArray, undefined, true)

    const trash = new Pickable('Delete', 'delete', undefined, {updateType : UpdateType.DELETE, scope : this, callback: this.delete})

    
    
    toReturn.push(back)
    toReturn.push(colorSettings)
    toReturn.push(otherSettings)
    toReturn.push(trash)

    return toReturn
  }

  private setupTextSettings() : Pickable[] {
    const toReturn : Pickable[] = []

    const stackingOrder = new Pickable('Stacking Order', 'layers', undefined, {updateType : UpdateType.SLIDER, updateProp : 'stackingOrder', updateDestination : UpdateDestination.ASSET, scope: this, callback: this.updateAssetStackingOrder, updateMin : 1, updateMax : this.appService.gridSection?.assets.length, updateStep : 1, shouldShowCallback : this.shouldShowStackingOrder, shouldShowScope : this})

    const textSize = new Pickable('Text Size', 'text_increase', undefined, {updateType : UpdateType.SLIDER, updateProp : 'size', updateDestination : UpdateDestination.ASSET, updateMin : 2, updateMax : 200, updateStep : 1})

    const font = new Pickable('Font Picker', 'font_download', undefined, {updateType : UpdateType.FONT_PICKER})
    
    const decoration = new Pickable('Font Decorations', 'text_format', undefined, {updateType : UpdateType.FONT_DECORATIONS})

    const fontWeight = new Pickable('Text Thickness', 'line_weight', undefined, {updateType : UpdateType.SLIDER, updateProp : 'fontWeight', updateDestination : UpdateDestination.ASSET, updateMin : 100, updateMax : 500, updateStep : 100})

    const padding = new Pickable('Padding', 'padding', undefined, {updateType : UpdateType.SLIDER, updateProp : 'padding', updateDestination : UpdateDestination.ASSET, updateMin : 0, updateMax : 150, updateStep : 1})

    const borderWidth = new Pickable('Border Width', 'border_all', undefined, {updateType : UpdateType.SLIDER, updateProp : 'borderWidth', updateDestination : UpdateDestination.ASSET, updateMin : 0, updateMax : 30, updateStep : 1})

    const lineSpacing = new Pickable('Line Spacing', 'format_line_spacing', undefined, {updateType : UpdateType.SLIDER, updateProp : 'lineHeight', updateDestination : UpdateDestination.ASSET, updateMin : 1, updateMax : 5, updateStep : .1})
    
    const wordSpacing = new Pickable('Word Spacing', 'line_style', undefined, {updateType : UpdateType.SLIDER, updateProp : 'wordSpacing', updateDestination : UpdateDestination.ASSET, updateMin : 0, updateMax : 200, updateStep : 1})

    const letterSpacing = new Pickable('Letter Spacing', 'title', undefined, {updateType : UpdateType.SLIDER, updateProp : 'letterSpacing', updateDestination : UpdateDestination.ASSET, updateMin : 0, updateMax : 200, updateStep : 1})

    const textStroke = new Pickable('Text Stroke', 'border_color', undefined, {updateType : UpdateType.SLIDER, updateProp : 'textStrokeWidth', updateDestination : UpdateDestination.ASSET, updateMin : 0, updateMax : 5, updateStep : .1})

    const horizontalAlignOptions : Pickable[] = [new Pickable(undefined, 'format_align_left', 'left'),
                                                new Pickable(undefined, 'format_align_center', 'center'),
                                                new Pickable(undefined, 'format_align_right', 'right')]

    const horizontalAlign =  new Pickable('Horizontal Align', 'format_align_center', horizontalAlignOptions, {updateType : UpdateType.BUTTON_GROUP, updateDestination : UpdateDestination.ASSET, updateProp : 'horizontalAlign'})

    const verticalAlignOptions : Pickable[] = [new Pickable(undefined, 'vertical_align_top', 'start'),
      new Pickable(undefined, 'vertical_align_center', 'center'),
      new Pickable(undefined, 'vertical_align_bottom', 'end')]

    const verticalAlign =  new Pickable('Vertical Align', 'vertical_align_center', verticalAlignOptions, {updateType : UpdateType.BUTTON_GROUP, updateDestination : UpdateDestination.ASSET, updateProp : 'verticalAlign'})



    toReturn.push(stackingOrder)
    toReturn.push(textSize)
    toReturn.push(font)
    toReturn.push(decoration)
    toReturn.push(fontWeight)
    toReturn.push(padding)
    toReturn.push(borderWidth)
    toReturn.push(lineSpacing)
    toReturn.push(wordSpacing)
    toReturn.push(letterSpacing)
    toReturn.push(textStroke)
    toReturn.push(horizontalAlign)
    toReturn.push(verticalAlign)

    return toReturn
  }

  private setupTextColors() : Pickable[]{
    const toReturn : Pickable[] = []

    const textColor : Pickable = new Pickable('Text Color', 'format_color_text')
    textColor.action = {updateType : UpdateType.COLORS, updateDestination : UpdateDestination.ASSET, updateProp : 'color'}
    const textStroke : Pickable = new Pickable('Text Stroke', 'border_color')
    textStroke.action = {updateType : UpdateType.COLORS, updateDestination : UpdateDestination.ASSET, updateProp : 'textStrokeColor'}
    const backgroundColor : Pickable = new Pickable('Background Color', 'format_color_fill')
    backgroundColor.action = {updateType : UpdateType.COLORS, updateDestination : UpdateDestination.ASSET, updateProp : 'backgroundColor'}
    const borderColor : Pickable = new Pickable('Border Color', 'format_paint')
    borderColor.action = {updateType : UpdateType.COLORS, updateDestination : UpdateDestination.ASSET, updateProp : 'borderColor'}

    toReturn.push(textColor)
    toReturn.push(textStroke)
    toReturn.push(backgroundColor)
    toReturn.push(borderColor)

    return toReturn
  }

  private setupTextNavigation() : Pickable<Pickable[]>[]{
    const toReturn : Pickable<Pickable[]>[] = []

    const back : Pickable = new Pickable('Back', 'reply', undefined, {scope : this, callback : this.back})

    const color : Pickable<Pickable[]> = new Pickable('Colors', 'palette', this.textColors)

    const settings : Pickable<Pickable[]> = new Pickable('Settings', 'tune', this.textSettings, undefined, true)

    const trash = new Pickable('Delete', 'delete', undefined, {updateType : UpdateType.DELETE, scope : this, callback: this.delete})


    toReturn.push(back)
    toReturn.push(color)
    toReturn.push(settings)
    toReturn.push(trash)

    return toReturn
  }

  private setupStickerSettings() : Pickable[]{
    const toReturn : Pickable[] = []

    const stackingOrder = new Pickable('Stacking Order', 'layers', undefined, {updateType : UpdateType.SLIDER, updateProp : 'stackingOrder', updateDestination : UpdateDestination.ASSET, scope: this, callback: this.updateAssetStackingOrder, updateMin : 1, updateMax : this.appService.gridSection?.assets.length, updateStep : 1, shouldShowCallback : this.shouldShowStackingOrder, shouldShowScope : this})

    const opacity = new Pickable('Opacity', 'opacity', undefined, {updateType : UpdateType.SLIDER, updateDestination : UpdateDestination.ASSET, updateProp : 'opacity',  updateMin : 0, updateMax : 100, updateStep : 1})
    
    toReturn.push(stackingOrder)
    toReturn.push(opacity)

    return toReturn
  }

  private setupStickerNavigation() : Pickable<Pickable[]>[] {
    this.stickerSettings = this.setupStickerSettings()
    const toReturn : Pickable<Pickable[]>[] = []

    const back : Pickable = new Pickable('Back', 'reply', undefined, {scope : this, callback : this.back})

    const settings : Pickable = new Pickable('Settings', 'tune', this.stickerSettings, undefined, true)

    const trash = new Pickable('Delete', 'delete', undefined, {updateType : UpdateType.DELETE, scope : this, callback: this.delete})

    toReturn.push(back)
    toReturn.push(settings)
    toReturn.push(trash)

    return toReturn
  }

  private setupAddableAssets() : Pickable<AssetType>[]{
    const toReturn : Pickable<AssetType>[] = []
    toReturn.push(new Pickable('Textbox', 'edit', AssetType.TEXT, {scope : this, callback : this.addAsset}))
    toReturn.push(new Pickable('Sticker', 'mood', AssetType.STICKER, {scope : this, callback : this.addAsset}))
    toReturn.push(new Pickable('Emoji', 'add_reaction', AssetType.EMOJI, {scope : this, callback : this.addAsset}))
    toReturn.push(new Pickable('Shape', 'category', AssetType.SHAPE, {scope : this, callback : this.addAsset}))
    toReturn.push(new Pickable('Text Bubble', 'chat_bubble', AssetType.TEXT_BUBBLE, {scope : this, callback : this.addAsset}))
    toReturn.push(new Pickable('Upload Image', 'add_photo_alternate', AssetType.UPLOAD_IMAGE, {scope : this, callback : this.addAsset}))
   return toReturn
  }

  private setupPrimaryNavigation() : Pickable<Pickable[]>[]{
    const toReturn : Pickable<Pickable[]>[] = []

    const layout : Pickable<Pickable<GridSection>[]> = new Pickable('Layouts', 'view_comfy', this.gridSections, {shouldShowCallback : this.shouldShowLayouts, shouldShowScope : this})

    const aspectRatios : Pickable<Pickable<AspectRatio>[]> = new Pickable('Aspect Ratios', 'aspect_ratio', this.aspectRatios)

    const gridColors : Pickable<Pickable<Pickable>[]> = new Pickable('Colors', 'palette', this.gridColors)

    const gridStyles : Pickable<Pickable<Pickable>[]> = new Pickable('Grid Border', 'border_all', this.gridStyles)

    const assets : Pickable<Pickable<Pickable>[]> = new Pickable('Add Asset', 'add_circle', this.addableAssets, undefined, false)

    toReturn.push(layout)
    toReturn.push(aspectRatios)
    toReturn.push(assets)
    toReturn.push(gridColors)
    toReturn.push(gridStyles)


    return toReturn
  }

  private setupGridStyles() : Pickable[]{
    const toReturn : Pickable[] = []

    const borderStyles : Pickable<string>[] = [new Pickable<string>('Solid', 'solid_border_svg', 'solid'), new Pickable<string>('Dotted', 'dotted_border_svg', 'dotted'), new Pickable<string>('Dashed', 'dashed_border_svg', 'dashed')]
    const borderStyle : Pickable<Pickable[]> = new Pickable('Border Style', 'border_clear', borderStyles)
    borderStyle.action = {updateType : UpdateType.MENU, updateDestination : UpdateDestination.GRID, updateProp : 'borderStyle'}

    const borderWidth : Pickable = new Pickable('Border Width', 'border_outer')
    borderWidth.action = {updateType : UpdateType.SLIDER, updateDestination : UpdateDestination.GRID, updateProp : 'borderWidth', updateMax: 50, updateMin: 0, updateStep: 1}

    toReturn.push(borderStyle)
    toReturn.push(borderWidth)

    return toReturn
  }

  private setupGridColors() : Pickable[]{
    const toReturn : Pickable[] = []

    const background : Pickable = new Pickable('Background Color', 'format_color_fill')
    background.action = {updateType : UpdateType.COLORS, updateDestination : UpdateDestination.GRID, updateProp : 'backgroundColor'}
    const border : Pickable = new Pickable('Border Color', 'format_paint')
    border.action = {updateType : UpdateType.COLORS, updateDestination : UpdateDestination.GRID, updateProp : 'borderColor'}

    toReturn.push(background)
    toReturn.push(border)

    return toReturn
  }

  private setupAspectRatios() : Pickable<AspectRatio>[]{
    const toReturn : Pickable<AspectRatio>[] = []
    let ar : AspectRatio
    let pickable : Pickable

    ar = new AspectRatio(1, 1)
    pickable = new Pickable(undefined, this.getAspectRatioSVG(ar), ar)
    pickable.displayType = DisplayType.SVG
    pickable.action = {scope : this, callback : this.setAspectRatio}
    toReturn.push(pickable),

    
    ar = new AspectRatio(2, 1)
    pickable = new Pickable(undefined, this.getAspectRatioSVG(ar), ar)
    pickable.displayType = DisplayType.SVG
    pickable.action = {scope : this, callback : this.setAspectRatio}
    toReturn.push(pickable),


    ar = new AspectRatio(3, 1)
    pickable = new Pickable(undefined, this.getAspectRatioSVG(ar), ar)
    pickable.displayType = DisplayType.SVG
    pickable.action = {scope : this, callback : this.setAspectRatio}
    toReturn.push(pickable),


    ar = new AspectRatio(4, 3)
    pickable = new Pickable(undefined, this.getAspectRatioSVG(ar), ar)
    pickable.displayType = DisplayType.SVG
    pickable.action = {scope : this, callback : this.setAspectRatio}
    toReturn.push(pickable),


    ar = new AspectRatio(4, 5)
    pickable = new Pickable(undefined, this.getAspectRatioSVG(ar), ar)
    pickable.displayType = DisplayType.SVG
    pickable.action = {scope : this, callback : this.setAspectRatio}
    toReturn.push(pickable),


    ar = new AspectRatio(5, 3)
    pickable = new Pickable(undefined, this.getAspectRatioSVG(ar), ar)
    pickable.displayType = DisplayType.SVG
    pickable.action = {scope : this, callback : this.setAspectRatio}
    toReturn.push(pickable),


    ar = new AspectRatio(5, 4)
    pickable = new Pickable(undefined, this.getAspectRatioSVG(ar), ar)
    pickable.displayType = DisplayType.SVG
    pickable.action = {scope : this, callback : this.setAspectRatio}
    toReturn.push(pickable),


    ar = new AspectRatio(7, 5)
    pickable = new Pickable(undefined, this.getAspectRatioSVG(ar), ar)
    pickable.displayType = DisplayType.SVG
    pickable.action = {scope : this, callback : this.setAspectRatio}
    toReturn.push(pickable),


    ar = new AspectRatio(9, 16)
    pickable = new Pickable(undefined, this.getAspectRatioSVG(ar), ar)
    pickable.displayType = DisplayType.SVG
    pickable.action = {scope : this, callback : this.setAspectRatio}
    toReturn.push(pickable),

    ar = new AspectRatio(16, 9)
    pickable = new Pickable(undefined, this.getAspectRatioSVG(ar), ar)
    pickable.displayType = DisplayType.SVG
    pickable.action = {scope : this, callback : this.setAspectRatio}
    toReturn.push(pickable),

    ar = new AspectRatio(24, 10)
    pickable = new Pickable(undefined, this.getAspectRatioSVG(ar), ar)
    pickable.displayType = DisplayType.SVG
    pickable.action = {scope : this, callback : this.setAspectRatio}
    toReturn.push(pickable)
    
    return toReturn
  }

  private setupGridsections() : Pickable<GridSection>[]{
    const toReturn : Pickable<GridSection>[] = []


    const seven = new GridSection()
    const sevenPickable : Pickable<GridSection> = new Pickable(undefined, this.getLayoutSVG(seven), seven)
    sevenPickable.displayType = DisplayType.SVG
    sevenPickable.action = {scope : this, callback : this.setGridSection}
    toReturn.push(sevenPickable)

    const one = new GridSection(Orientation.VERTICAL)
    one.addChild(new GridSection())
    one.addChild(new GridSection())
    const onePickable : Pickable<GridSection> = new Pickable(undefined, this.getLayoutSVG(one), one)
    onePickable.displayType = DisplayType.SVG
    onePickable.action = {scope : this, callback : this.setGridSection}
    toReturn.push(onePickable)


    const two = new GridSection(Orientation.HORIZONTAL)
    two.addChild(new GridSection())
    two.addChild(new GridSection())
    const twoPickable : Pickable<GridSection> = new Pickable(undefined, this.getLayoutSVG(two), two)
    twoPickable.displayType = DisplayType.SVG
    twoPickable.action = {scope : this, callback : this.setGridSection}
    toReturn.push(twoPickable)


    const three = new GridSection(Orientation.HORIZONTAL)
    const threeOne = new GridSection(Orientation.VERTICAL)
    three.addChild(threeOne)
    threeOne.addChild(new GridSection())
    threeOne.addChild(new GridSection())
    const threeTwo = new GridSection(Orientation.VERTICAL)
    three.addChild(threeTwo)
    threeTwo.addChild(new GridSection())
    threeTwo.addChild(new GridSection())
    const threePickable : Pickable<GridSection> = new Pickable(undefined, this.getLayoutSVG(three), three)
    threePickable.displayType = DisplayType.SVG
    threePickable.action = {scope : this, callback : this.setGridSection}
    toReturn.push(threePickable)

    const four = new GridSection(Orientation.VERTICAL)
    four.addChild(new GridSection())
    four.addChild(new GridSection())
    four.addChild(new GridSection())
    const fourPickable : Pickable<GridSection> = new Pickable(undefined, this.getLayoutSVG(four), four)
    fourPickable.displayType = DisplayType.SVG
    fourPickable.action = {scope : this, callback : this.setGridSection}
    toReturn.push(fourPickable)

    const five = new GridSection(Orientation.HORIZONTAL)
    const fiveTwo = new GridSection(Orientation.VERTICAL)
    five.addChild(new GridSection())
    five.addChild(fiveTwo)
    fiveTwo.addChild(new GridSection())
    fiveTwo.addChild(new GridSection())
    fiveTwo.addChild(new GridSection())
    const fivePickable : Pickable<GridSection> = new Pickable(undefined, this.getLayoutSVG(five), five)
    fivePickable.displayType = DisplayType.SVG
    fivePickable.action = {scope : this, callback : this.setGridSection}
    toReturn.push(fivePickable)

    const six = new GridSection(Orientation.HORIZONTAL)
    six.addChild(new GridSection())
    six.addChild(new GridSection())
    six.addChild(new GridSection())
    const sixPickable : Pickable<GridSection> = new Pickable(undefined, this.getLayoutSVG(six), two)
    sixPickable.displayType = DisplayType.SVG
    sixPickable.action = {scope : this, callback : this.setGridSection}
    toReturn.push(sixPickable)


    const eight = new GridSection(Orientation.VERTICAL)
    const eightTop = eight.addChild(new GridSection(Orientation.HORIZONTAL))
    eightTop.addChild(new GridSection())
    eightTop.addChild(new GridSection())
    eight.addChild(new GridSection())
    const eightPickable : Pickable<GridSection> = new Pickable(undefined, this.getLayoutSVG(eight), eight)
    eightPickable.displayType = DisplayType.SVG
    eightPickable.action = {scope : this, callback : this.setGridSection}
    toReturn.push(eightPickable)

    return toReturn
  }
  


  //ACTIONS
  private setGridSection(value : GridSection){
    this.appService.gridSection = value
  }

  private setAspectRatio(ar: AspectRatio) {
    if (this.appService.gridSection) {
      this.appService.gridSection.horizontalAspectRatio = ar.horizontalAspectRatio
      this.appService.gridSection.verticalAspectRatio = ar.verticalAspectRatio
      //this.a('Aspect Ratio', `${ar.horizontalAspectRatio} / ${ar.verticalAspectRatio}`)
    }
  }

  addAsset(assetType: AssetType) {
    this.historyService.createHistoryEntry(HistoryRecordType.ASSET)
    setTimeout(() => {

      const added = this.appService.gridSection?.addAsset(assetType, this.toolbarService)
      //this.createHistoryEntry(HistoryRecordType.ASSET)
      if (assetType == AssetType.TEXT) {
        this.toast.show([{ message: "Double click to edit!", action: "Got it!" }, { message: "Use the buttons on the toolbar to style!", action: "Got it!" }])
        //this.toolbarService.editModeSubject.next({ mode :EditMode.TEXT, asset : added})
      } else if (assetType == AssetType.STICKER || assetType == AssetType.MEME || assetType == AssetType.EMOJI || assetType == AssetType.SHAPE) {
        //this.secondaryReady = false
        this.dialog.open(StickerBrowserComponent, {
          data: added,
          panelClass: 'panel-class',
          minHeight: this.appService.gridSection?.rootGridSectionHeight + 'px',
          minWidth: this.appService.gridSection?.rootGridSectionWidth + 'px',
          maxHeight: '80vh'
        })
      } else if (assetType == AssetType.TEXT_BUBBLE) {
        this.toast.show([{ message: "Drag, resize or rotate!  Also use buttons in toolbar to change other properties!", action: "Got it!" }])
        //this.toolbarService.editModeSubject.next({ mode :EditMode.TEXT_BUBBLE, asset : added})
      } else if (assetType == AssetType.UPLOAD_IMAGE) {
        //this.bottomSheet.open(MediaAttacherComponent, { data: added })
        this.bottomSheet.open(MediaAttacherComponent, { data: { uploadType: UploadType.IMAGE, asset: added } })
      }
      //this.a('Asset Added', asset.data, ScreenAction.CLICK)

    });
  }

  back() {
    if (this.appService.gridSection) this.appService.gridSection.activeAsset = undefined
    this.toolbarService.editModeSubject.next({ mode: EditMode.BASIC })
  }

  updateAssetStackingOrder(i: any) {
    const idx: number = Number(i) - 1
    const assetIdx: number = this.appService.gridSection?.activeAsset ? this.appService.gridSection?.assets.indexOf(this.appService.gridSection?.activeAsset) || 0 : 0
    // Check for valid indexes
    if (assetIdx < 0 || assetIdx >= (this.appService.gridSection?.assets?.length || 0) || idx < 0 || idx >= (this.appService.gridSection?.assets?.length || 0)) {
      return; // Handle invalid indexes (optional)
    }

    // Swap using temporary variable
    let temp: Asset | undefined = this.appService.gridSection?.assets[assetIdx];
    if (this?.appService.gridSection?.assets) this.appService.gridSection.assets[assetIdx] = this.appService.gridSection?.assets[idx]
    if (this?.appService.gridSection?.assets) this.appService.gridSection.assets[idx] = temp || { type: AssetType.TEXT, data: { x: 0, y: 0, visible: true, editing: false }, section: this.appService.gridSection }

    if(this.appService.gridSection?.assets){
      this.appService.gridSection.assets.forEach((asset : Asset, idx : number) => {
        asset.data.stackingOrder = idx + 1
      })
    }
    //this.a("Update Stacking Order", `From ${idx} to ${assetIdx}`)
  }

  private delete(){
    this.appService.gridSection?.removeAsset(this.historyService, this.appService.gridSection.activeAsset)
    this.back()
  }




//SHOULD SHOW METHODS
public shouldShowStackingOrder() : boolean{
  return (this.appService.gridSection?.assets.length || 0) > 1
}

public shouldShowLayouts() : boolean{
  const toReturn = (this.appService.gridSection?.media?.mediaType || MediaType.LOCAL_IMAGE) == MediaType.LOCAL_IMAGE
  return toReturn
}




  //util methods
  private getAspectRatioSVG(ar: AspectRatio) {
    const maxWidthAndHeight: number = 45;
    const maxWidth = maxWidthAndHeight;
    const maxHeight = maxWidthAndHeight;

    // Calculate width and height based on the aspect ratio
    let width = (maxHeight * ar.horizontalAspectRatio) / ar.verticalAspectRatio;
    let height = (maxWidth * ar.verticalAspectRatio) / ar.horizontalAspectRatio;

    // Ensure width and height do not exceed maxWidthAndHeight
    if (width > maxWidth) {
        width = maxWidth;
        height = (width * ar.verticalAspectRatio) / ar.horizontalAspectRatio;
    }

    if (height > maxHeight) {
        height = maxHeight;
        width = (height * ar.horizontalAspectRatio) / ar.verticalAspectRatio;
    }

    return this.sanitizer.bypassSecurityTrustHtml(`
      <svg xmlns="http://www.w3.org/2000/svg" width="${width}" height="${height}">
      <rect width="${width}" height="${height}" fill="grey" stroke="white" stroke-width="2"></rect>
      </svg>`);
}


  private getLayoutSVG(lo: GridSection) {
    const baseDimension: number = 50
    const vertAR: number = 1 || 0
    const horAR: number = 1 || 0
    const vertARCalc: number = baseDimension / vertAR
    const horARCalc: number = baseDimension / horAR
    const border: number = 4
    const borderColor: string = 'white'
    const fillColor: string = 'grey' //this.isSelectedLayout(lo) ? '#560CB8' : 'grey'

    const wrapper = `<svg width="${horARCalc}" height="${vertARCalc}" xmlns="http://www.w3.org/2000/svg">`
    let contents = ''

    if (lo.children && lo.children.length > 0) {
      //first level
      lo.children.forEach((child: GridSection, idx: number) => {
        if (child.children && child.children.length > 0) {
          child.children.forEach((childChild: GridSection, childIdx: number) => {
            if (child.orientation == Orientation.HORIZONTAL) {
              if (lo.orientation == Orientation.HORIZONTAL) {
                //this wouldnt make much sense to do
              } else if (lo.orientation == Orientation.VERTICAL) {
                const x = ((horARCalc / child.children.length) * childIdx)
                const y = (vertARCalc / lo.children.length) * idx
                const w = (horARCalc / child.children.length)
                const h = vertARCalc / lo.children.length
                const contentPart = `<rect x="${x}" y="${y}" width="${w}" height="${h}" fill="${fillColor}" stroke="${borderColor}" stroke-width="${border}"/>`
                contents = `${contents} ${contentPart}`
              }
            } else if (child.orientation == Orientation.VERTICAL) {
              if (lo.orientation == Orientation.HORIZONTAL) {
                const x = (horARCalc / lo.children.length) * idx
                const y = ((vertARCalc / child.children.length) * childIdx)
                const w = horARCalc / lo.children.length
                const h = (vertARCalc / child.children.length)
                const contentPart = `<rect x="${x}" y="${y}" width="${w}" height="${h}" fill="${fillColor}" stroke="${borderColor}" stroke-width="${border}"/>`
                contents = `${contents} ${contentPart}`
              } else if (lo.orientation == Orientation.VERTICAL) {
                //this wouldnt make much sense to do
              }
            }
          })
        } else {
          if (lo.orientation == Orientation.HORIZONTAL) {
            const contentPart = `<rect x="${(horARCalc / lo.children.length) * idx}" y="0" width="${horARCalc / lo.children.length}" height="${vertARCalc}" fill="${fillColor}" stroke="${borderColor}" stroke-width="${border}"/>`
            contents = `${contents} ${contentPart}`
          } else if (lo.orientation == Orientation.VERTICAL) {
            const contentPart = `<rect x="0" y="${(vertARCalc / lo.children.length) * idx}" width="${horARCalc}" height="${vertARCalc / lo.children.length}" fill="${fillColor}" stroke="${borderColor}" stroke-width="${border}"/>`
            contents = `${contents} ${contentPart}`
          }
        }
      })
    } else {
      contents = `<rect x="0" y="0" width="${horARCalc}" height="${vertARCalc}" fill="${fillColor}" stroke="${borderColor}" stroke-width="${border}"/>`
    }

    //<svg xmlns="http://www.w3.org/2000/svg" [attr.viewBox]="'0 0 ' + ar.horizontalAspectRatio + ' ' + ar.verticalAspectRatio">

    const laytout = `${wrapper}
                        ${contents}
                      </svg>`


    return this.sanitizer.bypassSecurityTrustHtml(laytout)
  }
}
