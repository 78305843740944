import { Component, Inject, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';

export interface Alert{
  title : string
  body : string
  buttons : AlertButton[]
}

export interface AlertButton{
  name : string
  closeValue : any
}

@Component({
  selector: 'app-alert',
  standalone: true,
  imports: [MatDialogModule, MatButtonModule],
  templateUrl: './alert.component.html',
  styleUrl: './alert.component.scss'
})
export class AlertComponent {

    constructor(@Inject(MAT_DIALOG_DATA) public data: Alert, private dialogRef: MatDialogRef<Alert>){

    }

    public doAction(button : AlertButton){
      this.dialogRef.close(button.closeValue)
      //if(button.action)button.context ? button.action?.bind(button.context)() : button?.action()
    }
}
