import { Injectable } from '@angular/core'
import { GridSection } from '../vo/GridSection'
import { from, Observable, Subject, take } from 'rxjs'
import { openDB, deleteDB, wrap, unwrap, IDBPDatabase } from 'idb';
import { parseGridSection } from '../util/ParseUtil';


interface CanvasData{
  dataURL : string
  id : string
}

@Injectable({
  providedIn: 'root',
})
export class CanvasSaveService {

  constructor() {
  }

  private openDB() : Observable<IDBPDatabase<unknown>>{
    return from(openDB('Canvas', 1, {
      upgrade(db) {
        // Create a store of objects
        const store = db.createObjectStore('Canvas', {
          // The 'id' property of the object will be the key.
          keyPath: 'id',
          // If it isn't explicitly set, create a value by auto incrementing.
          autoIncrement: true,
        });
        // Create an index on the 'date' property of the objects.
        store.createIndex('id', 'id');
      },
    }))
  }

  add(dataurl : string) : Observable<string>{
    const canvasData : CanvasData = {id : Date.now().toString(), dataURL : dataurl}
    const obs : Subject<string> = new Subject()
    this.openDB().pipe(take(1)).subscribe({
      next : (value : IDBPDatabase<unknown>) => {
        //const canvasData = canvas.toDataURL();
       // canvasData.dataURL = canvas.toDataURL()
        from(value.add('Canvas', canvasData)).pipe(take(1)).subscribe((data : IDBValidKey) => {
          obs.next(dataurl)
        })
      },
      error : (error : any) =>{
        obs.error(error)
      }
    })
    return obs.asObservable()
  }

  /*
  update(canvas : HTMLCanvasElement) : Observable<HTMLCanvasElement>{
    const obs : Subject<HTMLCanvasElement> = new Subject()
    this.delete(canvas).pipe(take(1)).subscribe({
      next : (value : boolean) => {
        this.add(canvas).pipe(take(1)).subscribe({
          next : (value :HTMLCanvasElement) => {
            obs.next(value)
          }
        })
      }
    })
    return obs
  }
    */

  delete(canvas : HTMLCanvasElement) : Observable<boolean>{
    const obs : Subject<boolean> = new Subject()
    this.openDB().pipe(take(1)).subscribe({
      next : (value : IDBPDatabase<unknown>) => {
        from(value.delete('Canvas', canvas.id)).pipe(take(1)).subscribe(() => {
          obs.next(true)
        })
      },
      error : (error : any) =>{
        obs.error(error)
      }
    })
    return obs.asObservable()
  }

  getAll() : Observable<string[]>{
    const obs : Subject<string[]> = new Subject()
    this.openDB().pipe(take(1)).subscribe({
      next : (value : IDBPDatabase<unknown>) => {
        from(value.getAllFromIndex('Canvas', 'id')).pipe(take(1)).subscribe((data : any[]) => {
          const canvases : string[] = []
          data.forEach((unparsed : any) => {
            
            canvases.push(unparsed)
          })
          obs.next(canvases)
        })
      },
      error : (error : any) =>{
        obs.error(error)
      }
    })
    return obs.asObservable()
  }

  deleteAll() : Observable<HTMLCanvasElement[]>{
    const obs : Subject<HTMLCanvasElement[]> = new Subject()
    this.openDB().pipe(take(1)).subscribe({
      next : (value : IDBPDatabase<unknown>) => {
        from(value.getAllFromIndex('Canvas', 'id')).pipe(take(1)).subscribe((data : any[]) => {
          const canvases : HTMLCanvasElement[] = []
          data.forEach((unparsed : any) => {
            this.delete(unparsed)
          })
          obs.next(canvases)
        })
      },
      error : (error : any) =>{
        obs.error(error)
      }
    })
    return obs.asObservable()
  }


}