import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { environment } from '../../environments/environment';

export enum APIName {
  SAVE_GENERATED_CONTENT = 1,
  RETRIEVE_GENERATED_CONTENT = 2,
  GENERATE_IMAGE = 3,
  IMAGE_VIEWED = 4,
  GET_IMAGE_VIEWS = 5,
  GET_EMOJIS = 6,
  GET_SHAPES = 7,
  GET_MEMES = 8,
  GET_STICKERS = 9,
  GET_SEO = 10,
  GET_GIFS = 11,
  GET_SUBSCRIPTION_USER = 12,
  INITIATE_PAYMENT = 13,
  RETRIEVE_PAST_DUE_SUBSCRIPTION = 14,
  GET_PORTAL_URL = 15,
  UPLOAD_IMAGE = 16,
  ALL_USER_IMAGES = 17,
  UPGRADE_SUBSCRIPTION = 18,
  APPLY_DISCOUNT = 19,
  HOW_TOS = 20,
}

export enum HTTPMethods {
  POST = 1,
  GET = 2,
}

interface StringMap { [key: string]: string; }


@Injectable({
  providedIn: 'root'
})

export class ServiceLookupService {

  readonly APIName = APIName;
  readonly HTTPMethods = HTTPMethods;
  

  constructor(@Inject(PLATFORM_ID) private platformId: Object,){}

  lookup(apiName : APIName, method : HTTPMethods, payload?: string) : (string) {
    const baseURI  = this.baseURI();
    const mediaBaseURI  = this.mediaBaseURI();
    let fullURI = baseURI;
    switch(apiName){
      case APIName.SAVE_GENERATED_CONTENT:
        fullURI = `${baseURI}generatedData/save`
        break;
      case APIName.RETRIEVE_GENERATED_CONTENT:
        fullURI = `${baseURI}generatedData/retrieve`
        break;
      case APIName.GENERATE_IMAGE:
        fullURI = `${baseURI}generateImage/generate`
        break;
      case APIName.IMAGE_VIEWED:
        fullURI = `${baseURI}social/imageViews/${payload}`
        break;
      case APIName.GET_IMAGE_VIEWS:
        fullURI = `${baseURI}social/imageViews`
        break;
      case APIName.GET_MEMES:
        fullURI = `${mediaBaseURI}memes.json`
        break;
      case APIName.GET_EMOJIS:
        fullURI = `${mediaBaseURI}emojis.json`
        break;
      case APIName.GET_SHAPES:
        fullURI = `${mediaBaseURI}shapes.json`
        break;
      case APIName.GET_STICKERS:
        fullURI = `${mediaBaseURI}stickers.json`
        break;
      case APIName.GET_SEO:
        fullURI = `${mediaBaseURI}seo.json`
        break;
      case APIName.GET_GIFS:
        fullURI = `${mediaBaseURI}gifs.json`
        break;
      case APIName.GET_SUBSCRIPTION_USER:
        fullURI = `${baseURI}auth/user`
        break;
      case APIName.INITIATE_PAYMENT:
        fullURI = `${baseURI}payments/initiate`
        break;
      case APIName.RETRIEVE_PAST_DUE_SUBSCRIPTION:
        fullURI = `${baseURI}payments/retrieve-past-due-subscription`
        break;
      case APIName.GET_PORTAL_URL:
        fullURI = `${baseURI}payments/get-portal-url`
        break;
      case APIName.UPLOAD_IMAGE:
        fullURI = `${baseURI}user/add-image`
        break;
      case APIName.ALL_USER_IMAGES:
        fullURI = `${baseURI}user/get-images`
        break;
      case APIName.UPGRADE_SUBSCRIPTION:
        fullURI = `${baseURI}payments/upgrade`
        break;
      case APIName.APPLY_DISCOUNT:
        fullURI = `${baseURI}payments/applyDiscountCode`
        break;
      case APIName.HOW_TOS:
        fullURI = `${mediaBaseURI}howto.json`
        break;
    }

    //fullURI = `${fullURI}?auth_id=${user.uid}`


    return fullURI
  }

  private mediaBaseURI(): string {
    if (isPlatformServer(this.platformId)) {
      // Server-side rendering logic
      if (process.env['NODE_ENV'] === 'development') {
        return "../../assets/"; // Local development assets path
      } else {
        return `${environment.storage}media.danktank.cloud/json/`; // Production media URI
      }
    } else if (isPlatformBrowser(this.platformId)) {
      // Browser logic
      if (window.location.href.includes("127.0.0.1") || window.location.href.includes("localhost")) {
        return "../../assets/"; // Local assets path
      } else {
        return `${environment.storage}media.danktank.cloud/json/`; // Production media URI
      }
    }
    return `${environment.storage}media.danktank.cloud/json/`; // Fallback (shouldn't reach here)
  }
  
  private baseURI(): string {
    if (isPlatformServer(this.platformId)) {
      // Server-side rendering logic
      if (process.env['NODE_ENV'] === 'development') {
        return "http://127.0.0.1:5001/meme-creator-app/us-central1/"; // Local development function URI
      } else {
        return environment.endpoint//"https://us-central1-meme-creator-app.cloudfunctions.net/"; // Production function URI
      }
    } else if (isPlatformBrowser(this.platformId)) {
      // Browser logic
      if (window.location.href.includes("127.0.0.1") || window.location.href.includes("localhost")) {
        return "http://127.0.0.1:5001/meme-creator-app/us-central1/"; // Local development function URI
      } else {
        return environment.endpoint//"https://us-central1-meme-creator-app.cloudfunctions.net/"; // Production function URI
      }
    }
    return environment.endpoint//"https://us-central1-meme-creator-app.cloudfunctions.net/"; // Fallback (shouldn't reach here)
  }
}