import { Injectable } from '@angular/core'
import { GridSection } from '../vo/GridSection'
import { from, Observable, Subject, take } from 'rxjs'
import { openDB, deleteDB, wrap, unwrap, IDBPDatabase } from 'idb';
import { parseGridSection } from '../util/ParseUtil';
import { ToolbarService } from './toolbar.service';
import { FontService } from './font.service';


@Injectable({
  providedIn: 'root',
})
export class LocalSaveService {

  constructor(private toolbarService : ToolbarService, private fontService : FontService) {
    console.log()
  }

  private openDB() : Observable<IDBPDatabase<unknown>>{
    return from(openDB('GridSection', 1, {
      upgrade(db) {
        // Create a store of objects
        const store = db.createObjectStore('GridSection', {
          // The 'id' property of the object will be the key.
          keyPath: 'id',
          // If it isn't explicitly set, create a value by auto incrementing.
          autoIncrement: true,
        });
        // Create an index on the 'date' property of the objects.
        store.createIndex('id', 'id');
      },
    }))
  }

  add(gridSection : GridSection) : Observable<GridSection>{
    const obs : Subject<GridSection> = new Subject()
    this.openDB().pipe(take(1)).subscribe({
      next : (value : IDBPDatabase<unknown>) => {
        from(value.add('GridSection', gridSection)).pipe(take(1)).subscribe((data : IDBValidKey) => {
          obs.next(gridSection)
        })
      },
      error : (error : any) =>{
        obs.error(error)
      }
    })
    return obs.asObservable()
  }

  update(gs : GridSection) : Observable<GridSection>{
    const obs : Subject<GridSection> = new Subject()
    this.delete(gs).pipe(take(1)).subscribe({
      next : (value : boolean) => {
        this.add(gs).pipe(take(1)).subscribe({
          next : (value :GridSection) => {
            obs.next(value)
          }
        })
      }
    })
    return obs
  }

  delete(gridSection : GridSection) : Observable<boolean>{
    const obs : Subject<boolean> = new Subject()
    this.openDB().pipe(take(1)).subscribe({
      next : (value : IDBPDatabase<unknown>) => {
        from(value.delete('GridSection', gridSection.id)).pipe(take(1)).subscribe(() => {
          obs.next(true)
        })
      },
      error : (error : any) =>{
        obs.error(error)
      }
    })
    return obs.asObservable()
  }

  getAll() : Observable<GridSection[]>{
    const obs : Subject<GridSection[]> = new Subject()
    this.openDB().pipe(take(1)).subscribe({
      next : (value : IDBPDatabase<unknown>) => {
        from(value.getAllFromIndex('GridSection', 'id')).pipe(take(1)).subscribe((data : any[]) => {
          const gridSections : GridSection[] = []
          data.forEach((unparsed : any) => {
            const gs : GridSection = parseGridSection(unparsed, this.toolbarService, this.fontService)
            gridSections.push(gs)
          })
          obs.next(gridSections)
        })
      },
      error : (error : any) =>{
        obs.error(error)
      }
    })
    return obs.asObservable()
  }


}