import { Component } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
  selector: 'app-authenticating',
  standalone: true,
  imports: [MatProgressSpinnerModule, MatDialogModule],
  templateUrl: './authenticating.component.html',
  styleUrl: './authenticating.component.scss'
})
export class AuthenticatingComponent {

}
