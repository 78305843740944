export class StorageError extends Error {
  constructor(message) {
    super(message);
    this.__isStorageError = true;
    this.name = 'StorageError';
  }
}
export function isStorageError(error) {
  return typeof error === 'object' && error !== null && '__isStorageError' in error;
}
export class StorageApiError extends StorageError {
  constructor(message, status) {
    super(message);
    this.name = 'StorageApiError';
    this.status = status;
  }
  toJSON() {
    return {
      name: this.name,
      message: this.message,
      status: this.status
    };
  }
}
export class StorageUnknownError extends StorageError {
  constructor(message, originalError) {
    super(message);
    this.name = 'StorageUnknownError';
    this.originalError = originalError;
  }
}
