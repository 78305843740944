/*! dom-to-image-more 18-08-2024 */
!function (l) {
  let d = function () {
      let e = 0;
      return {
        escape: function (e) {
          return e.replace(/([.*+?^${}()|[]\/\\])/g, "\\$1");
        },
        isDataUrl: function (e) {
          return -1 !== e.search(/^(data:)/);
        },
        canvasToBlob: function (t) {
          if (t.toBlob) return new Promise(function (e) {
            t.toBlob(e);
          });
          return function (i) {
            return new Promise(function (e) {
              var t = u(i.toDataURL().split(",")[1]),
                n = t.length,
                o = new Uint8Array(n);
              for (let e = 0; e < n; e++) o[e] = t.charCodeAt(e);
              e(new Blob([o], {
                type: "image/png"
              }));
            });
          }(t);
        },
        resolveUrl: function (e, t) {
          var n = document.implementation.createHTMLDocument(),
            o = n.createElement("base"),
            i = (n.head.appendChild(o), n.createElement("a"));
          return n.body.appendChild(i), o.href = t, i.href = e, i.href;
        },
        getAndEncode: function (s) {
          let e = a.impl.urlCache.find(function (e) {
            return e.url === s;
          });
          e || (e = {
            url: s,
            promise: null
          }, a.impl.urlCache.push(e));
          null === e.promise && (a.impl.options.cacheBust && (s += (/\?/.test(s) ? "&" : "?") + new Date().getTime()), e.promise = new Promise(function (t) {
            let e = a.impl.options.httpTimeout,
              i = new XMLHttpRequest();
            if (i.onreadystatechange = function () {
              if (4 === i.readyState) if (300 <= i.status) n ? t(n) : l(`cannot fetch resource: ${s}, status: ` + i.status);else {
                let e = new FileReader();
                e.onloadend = function () {
                  t(e.result);
                }, e.readAsDataURL(i.response);
              }
            }, i.ontimeout = function () {
              n ? t(n) : l(`timeout of ${e}ms occured while fetching resource: ` + s);
            }, i.responseType = "blob", i.timeout = e, 0 < a.impl.options.useCredentialsFilters.length && (a.impl.options.useCredentials = 0 < a.impl.options.useCredentialsFilters.filter(e => 0 <= s.search(e)).length), a.impl.options.useCredentials && (i.withCredentials = !0), a.impl.options.corsImg && 0 === s.indexOf("http") && -1 === s.indexOf(window.location.origin)) {
              var r = "POST" === (a.impl.options.corsImg.method || "GET").toUpperCase() ? "POST" : "GET";
              i.open(r, (a.impl.options.corsImg.url || "").replace("#{cors}", s), !0);
              let t = !1,
                n = a.impl.options.corsImg.headers || {},
                o = (Object.keys(n).forEach(function (e) {
                  -1 !== n[e].indexOf("application/json") && (t = !0), i.setRequestHeader(e, n[e]);
                }), function (e) {
                  try {
                    return JSON.parse(JSON.stringify(e));
                  } catch (e) {
                    l("corsImg.data is missing or invalid", e.toString());
                  }
                }(a.impl.options.corsImg.data || ""));
              Object.keys(o).forEach(function (e) {
                "string" == typeof o[e] && (o[e] = o[e].replace("#{cors}", s));
              }), i.send(t ? JSON.stringify(o) : o);
            } else i.open("GET", s, !0), i.send();
            let n;
            function l(e) {
              console.error(e), t("");
            }
            a.impl.options.imagePlaceholder && (r = a.impl.options.imagePlaceholder.split(/,/)) && r[1] && (n = r[1]);
          }));
          return e.promise;
        },
        uid: function () {
          return "u" + ("0000" + (Math.random() * Math.pow(36, 4) << 0).toString(36)).slice(-4) + e++;
        },
        delay: function (n) {
          return function (t) {
            return new Promise(function (e) {
              setTimeout(function () {
                e(t);
              }, n);
            });
          };
        },
        asArray: function (t) {
          var n = [],
            o = t.length;
          for (let e = 0; e < o; e++) n.push(t[e]);
          return n;
        },
        escapeXhtml: function (e) {
          return e.replace(/%/g, "%25").replace(/#/g, "%23").replace(/\n/g, "%0A");
        },
        makeImage: function (o) {
          return "data:," !== o ? new Promise(function (e, t) {
            let n = new Image();
            a.impl.options.useCredentials && (n.crossOrigin = "use-credentials"), n.onload = function () {
              window && window.requestAnimationFrame ? window.requestAnimationFrame(function () {
                e(n);
              }) : e(n);
            }, n.onerror = t, n.src = o;
          }) : Promise.resolve();
        },
        width: function (e) {
          var t = r(e, "width");
          if (!isNaN(t)) return t;
          var t = r(e, "border-left-width"),
            n = r(e, "border-right-width");
          return e.scrollWidth + t + n;
        },
        height: function (e) {
          var t = r(e, "height");
          if (!isNaN(t)) return t;
          var t = r(e, "border-top-width"),
            n = r(e, "border-bottom-width");
          return e.scrollHeight + t + n;
        },
        getWindow: t,
        isElement: i,
        isElementHostForOpenShadowRoot: function (e) {
          return i(e) && null !== e.shadowRoot;
        },
        isShadowRoot: n,
        isInShadowRoot: o,
        isHTMLElement: function (e) {
          return e instanceof t(e).HTMLElement;
        },
        isHTMLCanvasElement: function (e) {
          return e instanceof t(e).HTMLCanvasElement;
        },
        isHTMLInputElement: function (e) {
          return e instanceof t(e).HTMLInputElement;
        },
        isHTMLImageElement: function (e) {
          return e instanceof t(e).HTMLImageElement;
        },
        isHTMLLinkElement: function (e) {
          return e instanceof t(e).HTMLLinkElement;
        },
        isHTMLScriptElement: function (e) {
          return e instanceof t(e).HTMLScriptElement;
        },
        isHTMLStyleElement: function (e) {
          return e instanceof t(e).HTMLStyleElement;
        },
        isHTMLTextAreaElement: function (e) {
          return e instanceof t(e).HTMLTextAreaElement;
        },
        isShadowSlotElement: function (e) {
          return o(e) && e instanceof t(e).HTMLSlotElement;
        },
        isSVGElement: function (e) {
          return e instanceof t(e).SVGElement;
        },
        isSVGRectElement: function (e) {
          return e instanceof t(e).SVGRectElement;
        },
        isDimensionMissing: function (e) {
          return isNaN(e) || e <= 0;
        }
      };
      function t(e) {
        e = e ? e.ownerDocument : void 0;
        return (e ? e.defaultView : void 0) || l || window;
      }
      function n(e) {
        return e instanceof t(e).ShadowRoot;
      }
      function o(e) {
        return null !== e && Object.prototype.hasOwnProperty.call(e, "getRootNode") && n(e.getRootNode());
      }
      function i(e) {
        return e instanceof t(e).Element;
      }
      function r(t, n) {
        if (t.nodeType === c) {
          let e = m(t).getPropertyValue(n);
          if ("px" === e.slice(-2)) return e = e.slice(0, -2), parseFloat(e);
        }
        return NaN;
      }
    }(),
    i = function () {
      let o = /url\(['"]?([^'"]+?)['"]?\)/g;
      return {
        inlineAll: function (t, o, i) {
          if (!e(t)) return Promise.resolve(t);
          return Promise.resolve(t).then(n).then(function (e) {
            let n = Promise.resolve(t);
            return e.forEach(function (t) {
              n = n.then(function (e) {
                return r(e, t, o, i);
              });
            }), n;
          });
        },
        shouldProcess: e,
        impl: {
          readUrls: n,
          inline: r
        }
      };
      function e(e) {
        return -1 !== e.search(o);
      }
      function n(e) {
        for (var t, n = []; null !== (t = o.exec(e));) n.push(t[1]);
        return n.filter(function (e) {
          return !d.isDataUrl(e);
        });
      }
      function r(n, o, t, e) {
        return Promise.resolve(o).then(function (e) {
          return t ? d.resolveUrl(e, t) : e;
        }).then(e || d.getAndEncode).then(function (e) {
          return n.replace((t = o, new RegExp(`(url\\(['"]?)(${d.escape(t)})(['"]?\\))`, "g")), `$1${e}$3`);
          var t;
        });
      }
    }(),
    e = {
      resolveAll: function () {
        return t().then(function (e) {
          return Promise.all(e.map(function (e) {
            return e.resolve();
          }));
        }).then(function (e) {
          return e.join("\n");
        });
      },
      impl: {
        readAll: t
      }
    };
  function t() {
    return Promise.resolve(d.asArray(document.styleSheets)).then(function (e) {
      let n = [];
      return e.forEach(function (t) {
        if (Object.prototype.hasOwnProperty.call(Object.getPrototypeOf(t), "cssRules")) try {
          d.asArray(t.cssRules || []).forEach(n.push.bind(n));
        } catch (e) {
          console.error("domtoimage: Error while reading CSS rules from " + t.href, e.toString());
        }
      }), n;
    }).then(function (e) {
      return e.filter(function (e) {
        return e.type === CSSRule.FONT_FACE_RULE;
      }).filter(function (e) {
        var t = function (e) {
          e = e.style.getPropertyValue("font-family");
          return e.split(",")[0].trim().replace(/['"]/g, "");
        }(e);
        return a.impl.options.fontList.includes(t) && i.shouldProcess(e.style.getPropertyValue("src"));
      });
    }).then(function (e) {
      return e.map(t);
    });
    function t(t) {
      return {
        resolve: function () {
          var e = (t.parentStyleSheet || {}).href;
          return i.inlineAll(t.cssText, e);
        },
        src: function () {
          return t.style.getPropertyValue("src");
        }
      };
    }
  }
  let n = {
    inlineAll: function t(e) {
      if (!d.isElement(e)) return Promise.resolve(e);
      return n(e).then(function () {
        return d.isHTMLImageElement(e) ? o(e).inline() : Promise.all(d.asArray(e.childNodes).map(function (e) {
          return t(e);
        }));
      });
      function n(o) {
        let e = ["background", "background-image"],
          t = e.map(function (t) {
            let e = o.style.getPropertyValue(t),
              n = o.style.getPropertyPriority(t);
            return e ? i.inlineAll(e).then(function (e) {
              o.style.setProperty(t, e, n);
            }) : Promise.resolve();
          });
        return Promise.all(t).then(function () {
          return o;
        });
      }
    },
    impl: {
      newImage: o
    }
  };
  function o(n) {
    return {
      inline: function (e) {
        if (d.isDataUrl(n.src)) return Promise.resolve();
        return Promise.resolve(n.src).then(e || d.getAndEncode).then(function (t) {
          return new Promise(function (e) {
            n.onload = e, n.onerror = e, n.src = t;
          });
        });
      }
    };
  }
  let s = {
      fontList: [],
      copyDefaultStyles: !0,
      imagePlaceholder: void 0,
      cacheBust: !1,
      useCredentials: !1,
      useCredentialsFilters: [],
      httpTimeout: 3e4,
      styleCaching: "strict",
      corsImg: void 0,
      adjustClonedNode: void 0
    },
    a = {
      toSvg: f,
      toPng: function (e, t) {
        return r(e, t).then(function (e) {
          return e.toDataURL();
        });
      },
      toJpeg: function (e, t) {
        return r(e, t).then(function (e) {
          return e.toDataURL("image/jpeg", (t ? t.quality : void 0) || 1);
        });
      },
      toBlob: function (e, t) {
        return r(e, t).then(d.canvasToBlob);
      },
      toPixelData: function (t, e) {
        return r(t, e).then(function (e) {
          return e.getContext("2d").getImageData(0, 0, d.width(t), d.height(t)).data;
        });
      },
      toCanvas: r,
      impl: {
        fontFaces: e,
        images: n,
        util: d,
        inliner: i,
        urlCache: [],
        options: {}
      }
    },
    c = ("object" == typeof exports && "object" == typeof module ? module.exports = a : l.domtoimage = a, ("undefined" != typeof Node ? Node.ELEMENT_NODE : void 0) || 1),
    m = (void 0 !== l ? l.getComputedStyle : void 0) || ("undefined" != typeof window ? window.getComputedStyle : void 0) || globalThis.getComputedStyle,
    u = (void 0 !== l ? l.atob : void 0) || ("undefined" != typeof window ? window.atob : void 0) || globalThis.atob;
  function f(e, i) {
    let t = a.impl.util.getWindow(e);
    var n = i = i || {};
    void 0 === n.fontList ? a.impl.options.fontList = s.fontList : a.impl.options.fontList = n.fontList, a.impl.options.copyDefaultStyles = (void 0 === n.copyDefaultStyles ? s : n).copyDefaultStyles, a.impl.options.imagePlaceholder = (void 0 === n.imagePlaceholder ? s : n).imagePlaceholder, a.impl.options.cacheBust = (void 0 === n.cacheBust ? s : n).cacheBust, a.impl.options.corsImg = (void 0 === n.corsImg ? s : n).corsImg, a.impl.options.useCredentials = (void 0 === n.useCredentials ? s : n).useCredentials, a.impl.options.useCredentialsFilters = (void 0 === n.useCredentialsFilters ? s : n).useCredentialsFilters, a.impl.options.httpTimeout = (void 0 === n.httpTimeout ? s : n).httpTimeout, a.impl.options.styleCaching = (void 0 === n.styleCaching ? s : n).styleCaching;
    let r = [];
    return Promise.resolve(e).then(function (e) {
      if (e.nodeType === c) return e;
      var t = e,
        n = e.parentNode,
        o = document.createElement("span");
      return n.replaceChild(o, t), o.append(e), r.push({
        parent: n,
        child: t,
        wrapper: o
      }), o;
    }).then(function (e) {
      return function l(t, s, r, u) {
        let e = s.filter;
        if (t === h || d.isHTMLScriptElement(t) || d.isHTMLStyleElement(t) || d.isHTMLLinkElement(t) || null !== r && e && !e(t)) return Promise.resolve();
        return Promise.resolve(t).then(n).then(o).then(function (e) {
          return c(e, a(t));
        }).then(i).then(function (e) {
          return f(e, t);
        });
        function n(e) {
          return d.isHTMLCanvasElement(e) ? d.makeImage(e.toDataURL()) : e.cloneNode(!1);
        }
        function o(e) {
          return s.adjustClonedNode && s.adjustClonedNode(t, e, !1), Promise.resolve(e);
        }
        function i(e) {
          return s.adjustClonedNode && s.adjustClonedNode(t, e, !0), Promise.resolve(e);
        }
        function a(e) {
          return d.isElementHostForOpenShadowRoot(e) ? e.shadowRoot : e;
        }
        function c(n, e) {
          let o = t(e),
            i = Promise.resolve();
          if (0 !== o.length) {
            let t = m(r(e));
            d.asArray(o).forEach(function (e) {
              i = i.then(function () {
                return l(e, s, t, u).then(function (e) {
                  e && n.appendChild(e);
                });
              });
            });
          }
          return i.then(function () {
            return n;
          });
          function r(e) {
            return d.isShadowRoot(e) ? e.host : e;
          }
          function t(e) {
            return d.isShadowSlotElement(e) ? e.assignedNodes() : e.childNodes;
          }
        }
        function f(u, a) {
          return !d.isElement(u) || d.isShadowSlotElement(a) ? Promise.resolve(u) : Promise.resolve().then(e).then(t).then(n).then(o).then(i).then(function () {
            return u;
          });
          function e() {
            if (u instanceof HTMLVideoElement) {
              let e = window.getComputedStyle(a),
                t = document.createElement("canvas"),
                n = (t.width = parseFloat(e.width), t.height = parseFloat(e.height), t.width),
                o = t.height,
                i = t.width / 2 - n / 2,
                r = t.height / 2 - o / 2,
                l = t.getContext("2d"),
                s = (l.drawImage(a, i, r, n, o), document.createElement("img"));
              u = s;
              try {
                s.src = t.toDataURL();
              } catch (e) {
                console.log(e), s.src = "data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==";
              } finally {
                l.clearRect(0, 0, t.width, t.height), t.remove();
              }
              u && [] instanceof HTMLImageElement;
            }
          }
          function t() {
            function o(e, t) {
              t.font = e.font, t.fontFamily = e.fontFamily, t.fontFeatureSettings = e.fontFeatureSettings, t.fontKerning = e.fontKerning, t.fontSize = e.fontSize, t.fontStretch = e.fontStretch, t.fontStyle = e.fontStyle, t.fontVariant = e.fontVariant, t.fontVariantCaps = e.fontVariantCaps, t.fontVariantEastAsian = e.fontVariantEastAsian, t.fontVariantLigatures = e.fontVariantLigatures, t.fontVariantNumeric = e.fontVariantNumeric, t.fontVariationSettings = e.fontVariationSettings, t.fontWeight = e.fontWeight;
            }
            function e(e, t) {
              let n = m(e);
              n.cssText ? (t.style.cssText = n.cssText, o(n, t.style)) : (y(s, e, n, r, t), null === r && (["inset-block", "inset-block-start", "inset-block-end"].forEach(e => t.style.removeProperty(e)), ["left", "right", "top", "bottom"].forEach(e => {
                t.style.getPropertyValue(e) && t.style.setProperty(e, "0px");
              })));
            }
            e(a, u);
          }
          function n() {
            let s = d.uid();
            function t(i) {
              let r = m(a, i),
                l = r.getPropertyValue("content");
              if ("" !== l && "none" !== l) {
                let e = u.getAttribute("class") || "",
                  t = (u.setAttribute("class", e + " " + s), document.createElement("style"));
                function n() {
                  let e = `.${s}:` + i,
                    t = (r.cssText ? n : o)();
                  return document.createTextNode(e + `{${t}}`);
                  function n() {
                    return `${r.cssText} content: ${l};`;
                  }
                  function o() {
                    let e = d.asArray(r).map(t).join("; ");
                    return e + ";";
                    function t(e) {
                      let t = r.getPropertyValue(e),
                        n = r.getPropertyPriority(e) ? " !important" : "";
                      return e + ": " + t + n;
                    }
                  }
                }
                t.appendChild(n()), u.appendChild(t);
              }
            }
            [":before", ":after"].forEach(function (e) {
              t(e);
            });
          }
          function o() {
            d.isHTMLTextAreaElement(a) && (u.innerHTML = a.value), d.isHTMLInputElement(a) && u.setAttribute("value", a.value);
          }
          function i() {
            d.isSVGElement(u) && (u.setAttribute("xmlns", "http://www.w3.org/2000/svg"), d.isSVGRectElement(u)) && ["width", "height"].forEach(function (e) {
              let t = u.getAttribute(e);
              t && u.style.setProperty(e, t);
            });
          }
        }
      }(e, i, null, t);
    }).then(p).then(g).then(function (t) {
      i.bgcolor && (t.style.backgroundColor = i.bgcolor);
      i.width && (t.style.width = i.width + "px");
      i.height && (t.style.height = i.height + "px");
      i.style && Object.keys(i.style).forEach(function (e) {
        t.style[e] = i.style[e];
      });
      let e = null;
      "function" == typeof i.onclone && (e = i.onclone(t));
      return Promise.resolve(e).then(function () {
        return t;
      });
    }).then(function (e) {
      let n = i.width || d.width(e),
        o = i.height || d.height(e);
      return Promise.resolve(e).then(function (e) {
        return e.setAttribute("xmlns", "http://www.w3.org/1999/xhtml"), new XMLSerializer().serializeToString(e);
      }).then(d.escapeXhtml).then(function (e) {
        var t = (d.isDimensionMissing(n) ? ' width="100%"' : ` width="${n}"`) + (d.isDimensionMissing(o) ? ' height="100%"' : ` height="${o}"`);
        return `<svg xmlns="http://www.w3.org/2000/svg"${(d.isDimensionMissing(n) ? "" : ` width="${n}"`) + (d.isDimensionMissing(o) ? "" : ` height="${o}"`)}><foreignObject${t}>${e}</foreignObject></svg>`;
      }).then(function (e) {
        return "data:image/svg+xml;charset=utf-8," + e;
      });
    }).then(function (e) {
      for (; 0 < r.length;) {
        var t = r.pop();
        t.parent.replaceChild(t.child, t.wrapper);
      }
      return e;
    }).then(function (e) {
      return a.impl.urlCache = [], function () {
        h && (document.body.removeChild(h), h = null);
        w && clearTimeout(w);
        w = setTimeout(() => {
          w = null, E = {};
        }, 2e4);
      }(), e;
    });
  }
  function r(i, r) {
    return f(i, r = r || {}).then(d.makeImage).then(function (e) {
      var t = "number" != typeof r.scale ? 1 : r.scale,
        n = function (e, t) {
          let n = r.width || d.width(e),
            o = r.height || d.height(e);
          d.isDimensionMissing(n) && (n = d.isDimensionMissing(o) ? 300 : 2 * o);
          d.isDimensionMissing(o) && (o = n / 2);
          e = document.createElement("canvas");
          e.width = n * t, e.height = o * t, r.bgcolor && ((t = e.getContext("2d")).fillStyle = r.bgcolor, t.fillRect(0, 0, e.width, e.height));
          return e;
        }(i, t),
        o = n.getContext("2d");
      return o.msImageSmoothingEnabled = !1, o.imageSmoothingEnabled = !1, e && (o.scale(t, t), o.drawImage(e, 0, 0)), n;
    });
  }
  let h = null;
  function p(n) {
    return e.resolveAll().then(function (e) {
      var t;
      return "" !== e && (t = document.createElement("style"), n.appendChild(t), t.appendChild(document.createTextNode(e))), n;
    });
  }
  function g(e) {
    return n.inlineAll(e).then(function () {
      return e;
    });
  }
  function y(e, t, r, l, n) {
    let s = a.impl.options.copyDefaultStyles ? function (t, e) {
        var e = function (e) {
            var t = [];
            do {
              if (e.nodeType === c) {
                var n = e.tagName;
                if (t.push(n), v.includes(n)) break;
              }
            } while ((e = e.parentNode, e));
            return t;
          }(e),
          n = function (e) {
            return ("relaxed" !== t.styleCaching ? e : e.filter((e, t, n) => 0 === t || t === n.length - 1)).join(">");
          }(e);
        if (E[n]) return E[n];
        var o = function () {
            if (h) return h.contentWindow;
            var e = document.characterSet || "UTF-8",
              t = document.doctype,
              t = t ? (`<!DOCTYPE ${n(t.name)} ${n(t.publicId)} ` + n(t.systemId)).trim() + ">" : "";
            return (h = document.createElement("iframe")).id = "domtoimage-sandbox-" + d.uid(), h.style.visibility = "hidden", h.style.position = "fixed", document.body.appendChild(h), function (e, t, n, o) {
              try {
                return e.contentWindow.document.write(t + `<html><head><meta charset='${n}'><title>${o}</title></head><body></body></html>`), e.contentWindow;
              } catch (e) {}
              var i = document.createElement("meta");
              i.setAttribute("charset", n);
              try {
                var r = document.implementation.createHTMLDocument(o),
                  l = (r.head.appendChild(i), t + r.documentElement.outerHTML);
                return e.setAttribute("srcdoc", l), e.contentWindow;
              } catch (e) {}
              return e.contentDocument.head.appendChild(i), e.contentDocument.title = o, e.contentWindow;
            }(h, t, e, "domtoimage-sandbox");
            function n(e) {
              var t;
              return e ? ((t = document.createElement("div")).innerText = e, t.innerHTML) : "";
            }
          }(),
          e = function (e, t) {
            let n = e.body;
            do {
              var o = t.pop(),
                o = e.createElement(o);
              n.appendChild(o), n = o;
            } while (0 < t.length);
            return n.textContent = "​", n;
          }(o.document, e),
          o = function (e, t) {
            let n = {},
              o = e.getComputedStyle(t);
            return d.asArray(o).forEach(function (e) {
              n[e] = "width" === e || "height" === e ? "auto" : o.getPropertyValue(e);
            }), n;
          }(o, e);
        return function (e) {
          do {
            var t = e.parentElement;
            null !== t && t.removeChild(e), e = t;
          } while (e && "BODY" !== e.tagName);
        }(e), E[n] = o;
      }(e, t) : {},
      u = n.style;
    d.asArray(r).forEach(function (e) {
      var t,
        n = r.getPropertyValue(e),
        o = s[e],
        i = l ? l.getPropertyValue(e) : void 0;
      u.getPropertyValue(e) || (n !== o || l && n !== i) && (o = r.getPropertyPriority(e), i = u, n = n, o = o, t = 0 <= ["background-clip"].indexOf(e = e), o ? (i.setProperty(e, n, o), t && i.setProperty("-webkit-" + e, n, o)) : (i.setProperty(e, n), t && i.setProperty("-webkit-" + e, n)));
    });
  }
  let w = null,
    E = {},
    v = ["ADDRESS", "ARTICLE", "ASIDE", "BLOCKQUOTE", "DETAILS", "DIALOG", "DD", "DIV", "DL", "DT", "FIELDSET", "FIGCAPTION", "FIGURE", "FOOTER", "FORM", "H1", "H2", "H3", "H4", "H5", "H6", "HEADER", "HGROUP", "HR", "LI", "MAIN", "NAV", "OL", "P", "PRE", "SECTION", "SVG", "TABLE", "UL", "math", "svg", "BODY", "HEAD", "HTML"];
}(this);
