import { Injectable } from '@angular/core';
import { GridSection, Orientation } from '../vo/GridSection';
import { DashboardComponent } from '../component/dashboard/dashboard.component';
import { parseGridSection } from '../util/ParseUtil';
import { EditMode, ToolbarService } from './toolbar.service';
import { FontService } from './font.service';
import { KeyboardService, KeyboardShortcut } from './keyboard.service';
import { AppService } from './app.service';

export enum HistoryRecordType{
  ASSET_PROPERTY = 1,
  MEDIA = 2,
  ASSET = 3,
  SECTION_PROPERTY = 4
}

export interface HistoryRecord{
  type : HistoryRecordType
  property ?: string
  data : GridSection
}

@Injectable({
  providedIn: 'root'
})
export class HistoryService {

  //this needs to be set

  constructor(private appService : AppService, private toolbarService : ToolbarService, private fontService : FontService, private keyboardService : KeyboardService) { 
    this.keyboardService.registerCallback(KeyboardShortcut.UNDO, this.undo, this)
  }

  public history : HistoryRecord[] = []
  public createHistoryEntry(type : HistoryRecordType, property ?: string){
    const gs = this.appService.gridSection
    //this.dashboard.gridSection = new GridSection()
    gs?.prepareSerialize()
    const serialized = JSON.parse(JSON.stringify(this.appService.gridSection))
    const toAdd : GridSection = parseGridSection(serialized, this.toolbarService, this.fontService)
    gs?.reapplyCyclicals();
    toAdd.reapplyCyclicals()
    const lastHistoryItem = this.history[this.history.length - 1]
    if(lastHistoryItem && (lastHistoryItem.type == HistoryRecordType.ASSET_PROPERTY || lastHistoryItem.type == HistoryRecordType.SECTION_PROPERTY) && lastHistoryItem.property == property){
      //this.history.pop()
    }else{
      this.history.push({type : type, data : toAdd || new GridSection(), property : property})
    }
    console.log()
    
  }

  //this method is for things like dragging and rotating which we can't interrupt 
  private queuedHistoryEntry ?: HistoryRecord
  queueHistoryEntry(type : HistoryRecordType, property ?: string){
    if(!this.appService)throw new Error("Dashboard must be set before adding history")
      const gs = this.appService.gridSection
      //this.dashboard.gridSection = new GridSection()
      gs?.prepareSerialize()
      const serialized = JSON.parse(JSON.stringify(this.appService.gridSection))
      const toAdd : GridSection = parseGridSection(serialized, this.toolbarService, this.fontService)
      gs?.reapplyCyclicals()
      this.queuedHistoryEntry = {type : type, data : toAdd || new GridSection(), property : property}
  }

  applyQueuedHistoryEntry(property : string){
    this.queuedHistoryEntry?.data.reapplyCyclicals()
    const lastHistoryItem = this.history[this.history.length - 1]
    if(lastHistoryItem && (lastHistoryItem.type == HistoryRecordType.ASSET_PROPERTY || lastHistoryItem.type == HistoryRecordType.SECTION_PROPERTY) && lastHistoryItem.property == property){
      //this.history.pop()
    }else{
      if(this.queuedHistoryEntry)this.history.push(this.queuedHistoryEntry)
    }
  }

  public undo(){
    if(!this.appService)throw new Error("Dashboard must be set before adding history")
    const gs : GridSection = this.history.pop()?.data || new GridSection(Orientation.HORIZONTAL)
    setTimeout(() => {
      this.appService.gridSectionSimple = gs
      this.toolbarService.editModeSubject.next({mode : EditMode.BASIC})
    }, 100);
  }

  public reverse(){
    for (let i = this.history.length - 1; i >= 0; i--) {
      if (this.history[i].type === HistoryRecordType.ASSET) {
        this.history.length = i
        break
      }
    }
    console.log()
  }


}
