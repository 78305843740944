<div  #imgAsset
    class="add-pointer-events"
    [style.transform]="data?.data?.transform"
    [style.width.px]="data?.data?.width"
    [style.height.px]="data?.data?.height"
    [style.opacity]="(data?.data?.opacity || 0) * .01"
    [class]="interacting ? 'noTransition' : 'transition'"
    [style.mix-blend-mode]="data?.data?.blendMode"
>
    <img #img
    [style.filter]="data?.type == AssetType.SHAPE ? data?.data?.filter : null"
    class="fade-img"
    (load)="data?.loaded?.emit(true); img.style.opacity = '1'; a(data?.type?.toString() || '' + 'Loaded', data?.data?.location, ScreenAction.LOAD)"
     [src]="data?.data?.location" style="object-fit: contain; width: 100%; height: 100%;" >
</div>