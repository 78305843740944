import { Component, Input, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule, MatMenuTrigger } from '@angular/material/menu';
import { AppService } from '../../service/app.service';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DeepLinkService } from '../../service/deep-link.service';
import { ActivatedRoute } from '@angular/router';
import { MatChipsModule } from '@angular/material/chips';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DankTankAuthService } from '../../service/dank-tank-auth.service';
import { PaymentService } from '../../service/payment.service';
import { MatDivider } from '@angular/material/divider';

@Component({
  selector: 'app-app-menu',
  standalone: true,
  imports: [MatDivider, ClipboardModule, MatMenuModule, MatMenuModule, MatIconModule, MatButtonModule, MatTooltipModule, MatChipsModule, MatProgressSpinnerModule],
  templateUrl: './app-menu.component.html',
  styleUrl: './app-menu.component.scss'
})
export class AppMenuComponent {

  @ViewChild('infoButton') infoButton!: MatMenuTrigger

  private _saving : boolean = false
  @Input() public set saving (val : boolean) {
    this._saving = val
    if(val){
      this.infoButton.openMenu()
    }else{
      this.infoButton.closeMenu()
    }
  }

  public get saving() : boolean {
    return this._saving
  }

  @Input() savedMemes : number = 0

  private _isLandingToolbar : boolean = false
  @Input() public set isLandingToolbar ( val : boolean) {
    this._isLandingToolbar = val
  } 

  public get isLandingToolbar() : boolean {
    return this._isLandingToolbar
  }



  constructor(public appService : AppService, public deepLinks : DeepLinkService, route : ActivatedRoute, public authService : DankTankAuthService, public paymentService : PaymentService){
    deepLinks.route = route
  }

  public getDataModel():string{
    return ''
  }

  public contactUs(){
    this.deepLinks.showContactUs()
  }

  public showHowTo(){
    this.deepLinks.showHowTo()
  }

  public showAbout(){
    this.deepLinks.showAbout()
  }

  public startGuidedTour(){

  }

  public showPopularMemes(){
    this.deepLinks.showPopularMemes()
  }

  public showLegal(){
    this.deepLinks.showLegal()
  }

  public showDeveloperTools() : boolean{
    return false
  }

  public showSavedMemes(){
    this.deepLinks.showSavedMemes()
  }

  

}
