import { AfterViewInit, ChangeDetectorRef, Component, DestroyRef, ElementRef, Inject, NgZone, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ImageResponse, SocialService } from '../../service/social.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { DashboardComponent } from '../dashboard/dashboard.component';
import { Asset, GridSection, Media, MediaType, Mode } from '../../vo/GridSection';
import { DialogRef } from '@angular/cdk/dialog';
import { MatMenuModule } from '@angular/material/menu';
import { createAssetsFromJSON } from '../../util/AssetCreator';
import { Sticker, StickerPack, StickerPayload, StickerService, StickerServiceResponse } from '../../service/sticker.service';
import { take } from 'rxjs';
import { LazyLoadDirective } from '../../directive/lazy-load.directive';
import { Router } from '@angular/router';
import { LocalSaveService } from '../../service/local-save.service';
import { MatDividerModule } from '@angular/material/divider';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AutoAnimateModule } from '@formkit/auto-animate/angular'
import { NgAutoAnimateDirective } from 'ng-auto-animate'; 
import { FontService } from '../../service/font.service';
import { ScrubberService } from '../../service/scrubber.service';
import { AppService } from '../../service/app.service';

@Component({
  selector: 'app-saved-memes',
  standalone: true,
  imports: [NgAutoAnimateDirective, MatTooltipModule, MatDividerModule, LazyLoadDirective, MatMenuModule, MatIconModule, MatProgressSpinnerModule, MatProgressBarModule, MatDialogModule, MatFormFieldModule, FormsModule, MatInputModule, MatButtonModule, MatListModule],
  templateUrl: './saved-memes.component.html',
  styleUrl: './saved-memes.component.scss'
})
export class SavedMemesComponent implements AfterViewInit {

  public loaded : boolean = false

  public savedMemes ?: GridSection[]

  constructor(private appService : AppService, private scrubberService : ScrubberService, private fontService : FontService, private localSaveService : LocalSaveService, @Inject(MAT_DIALOG_DATA) public data: DashboardComponent, private dialogRef : DialogRef){

  }

  ngAfterViewInit(): void {
    this.loadSaved()
  }

  private loadSaved(){

    this.localSaveService.getAll().pipe(take(1)).subscribe({
      next : (gridSections : GridSection[]) => {
        this.savedMemes = gridSections
        this.loaded = true
      }
    })
  }

  public openMeme( gs : GridSection){
    gs.mode = Mode.DEVELOPMENT
    this.appService.gridSectionSimple = new GridSection()
    setTimeout(() => {
      this.appService.gridSectionSimple = gs
    });
    setTimeout(() => {
      this.appService.gridSectionSimple = gs
    }, 1500);
    this.dialogRef.close()
  }

  public deleteMeme( gs : GridSection){
    this.localSaveService.delete(gs).pipe(take(1)).subscribe({
      next : (value : boolean) =>{
        this.loadSaved()
      }
    })
  }

}
