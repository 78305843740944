@if(isReady){
    <mat-card class="resizeableCard transitionMe" 
    [style.width.px]="expanded ? getTextWidthFromElement(title, content) : 50">
        <mat-card-header class="dialog-title pointer centered-title" (click)="expanded = !expanded">
            <mat-icon style="margin-bottom: 12px; margin-left: -4px;">{{expanded ? 'cancel' : 'info'}}</mat-icon>
            <div #titleText
            [style.opacity]="expanded ? 1 : 0"
            [style.width]="expanded ? null : '0px'" 
            [style.margin-left.px]="expanded ? 10 : 0" 
            ></div>
        </mat-card-header>
        <mat-card-content #content
        [style.opacity]="expanded ? 1 : 0"
        [style.height.px]="expanded ? 80 : 0"
        [style.padding.px]="expanded ? null : 0"
        [style.margin-top.px]="expanded ? 10 : 0"
        >
            <h1 class="subtle-gradient-text">{{title}}</h1>
            <mat-divider class="divider"></mat-divider>
            <span class="subtle-gradient-text">{{gridSection?.rootGridSectionWidth || 0 | round}} x {{gridSection?.rootGridSectionHeight || 0 | round}}</span>
        </mat-card-content>
    </mat-card>
}