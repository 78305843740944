<div mat-dialog-title class="container dialog-title">
@if(selectedHowTo){
    <div class="title-holder">
        <button class="button" style="margin: 6px !important;" mat-mini-fab (click)="selectedHowTo = undefined; searchTerm = ''" matTooltip="Back" matTooltipPosition="below">
            <mat-icon>reply</mat-icon>
        </button>
        <h2 mat-dialog-title>{{selectedHowTo.name}}</h2>
    </div>
}@else {
    <h2 mat-dialog-title>How To Videos</h2>
}
</div>
<div class="search-holder">
    @if(!selectedHowTo){
        <mat-form-field class="search-field" appearance="outline">
            <mat-label class="subtle-gradient-text">Search for Videos</mat-label>
            <input matInput type="text" [(ngModel)]="searchTerm">
            @if (searchTerm) {
            <button matSuffix mat-icon-button aria-label="Clear" (click)="searchTerm=''">
                <mat-icon>close</mat-icon>
            </button>
            }
        </mat-form-field> 
    } 
</div>
<mat-dialog-content class="centered-dialog-content-vertical-top dialog-content">
  @if(howTos?.length == 0){
  <mat-spinner
    style="margin: 0 auto !important; margin-top: 25px !important"
  ></mat-spinner>
  }
  @if((howTos?.length || 0) > 0){
    @if(selectedHowTo == undefined){
        <mat-grid-list class="grid" [cols]="columns" rowHeight="1.175:1" gutterSize="5px">
            @for (howto of howTos; track howto; let i = $index;) {
            <mat-grid-tile (click)="selectedHowTo = howto" class="grid-tile">
                <div class="icon-holder-grid">
                    <div>
                        @if(!images[howto.name]){<mat-icon>pending</mat-icon>}
                        @if(images[howto.name] ==
                        ImageLoadStatus.ERROR){<mat-icon>error</mat-icon>}
                        @if(images[howto.name] != ImageLoadStatus.ERROR){
                            <img
                                #img
                                [style.position]="
                                images[howto.name] == ImageLoadStatus.LOADED ? 'static' : 'absolute'
                                "
                                [style.width]="images[howto.name] ? null : '0px'"
                                [appLazyLoad]="howto.thumb"
                                (load)="
                                images[howto.name] = ImageLoadStatus.LOADED; img.style.opacity = '1'
                                "
                                (error)="images[howto.name] = ImageLoadStatus.ERROR"
                                class="grid-sticker pointer fade-img"
                            />
                        }
                    </div>
                    <strong  class="grid-name subtle-gradient-text">{{howto.name}}</strong>
                </div>
            </mat-grid-tile>
            }
        </mat-grid-list>
    }@else{
        @if(sanitizedVideo){
            <iframe
                class="fade-in"
                [width]="'100%'"
                [height]="'100%'"
                [src]="sanitizedVideo"
                style="border: 0; "
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen></iframe>
            }
        }
}
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-fab extended mat-dialog-close>That was cool!</button>
  </mat-dialog-actions>
