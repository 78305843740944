<h2 mat-dialog-title class="dialog-title">User Profile</h2>
<mat-dialog-content class="dialog-content">
  <mat-tab-group #tabGroup dynamicHeight="true" (selectedIndexChange)="tabChanged($event)">
    <mat-tab label="Basic Info">
      <div class="profile-info">
        <img #profilePicture (load)="profilePicture.style.opacity = '1'" referrerpolicy="no-referrer"
          class="profile-picture fade-img" [src]="data.user.user_metadata['avatar_url']" alt="Profile Picture">
        <div class="info">
          <div class="info-item subtle-gradient-text">
            <strong>Name:</strong> {{ data.user.user_metadata['full_name'] }}
          </div>
          <div class="info-item subtle-gradient-text">
            <strong>Email:</strong> {{ data.user.user_metadata['email'] }}
          </div>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="Subscription">
      <ng-container>
        @if(data.subscriptionUser?.subscription?.status == SubscriptionStatus.ACTIVE || data.subscriptionUser?.subscription?.status == SubscriptionStatus.FREE){
          <div class="past-due-container">
            <h3 class="subtle-gradient-text">{{ data.subscriptionUser?.subscription?.type }}</h3>
            <p class="subtle-gradient-text">Monthly Price: {{ data.subscriptionUser?.subscription?.monthlyPrice | currency }}</p>
            <p class="subtle-gradient-text">Status: {{ data.subscriptionUser?.subscription?.status | titlecase }}</p>
            @if(authService.subscriptionUserSubject.value?.data?.subscription?.status != SubscriptionStatus.FREE){
              <p class="subtle-gradient-text">Manage your subscription with the button below</p>
            }
            @if(tabGroup.selectedIndex == 1){<app-profile-button/>}
          </div>
        }
        @else if(data.subscriptionUser?.subscription?.status == SubscriptionStatus.PAST_DUE){
         <div class="past-due-container">
            <!-- Custom content for past due status -->
            @if(!portaUrl){
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
            }
            @if(portaUrl){
            <p class="subtle-gradient-text">Your subscription is past due. Please update your payment method.</p>
              @if(authService.subscriptionUserSubject.value?.data?.subscription?.status != SubscriptionStatus.FREE){
                <button mat-fab extended (click)="openPortalUrl()">
                  <mat-icon>settings</mat-icon>
                  Manage Subscription
                </button>
              }
            }
          </div>
        }
        @else if(data.subscriptionUser?.subscription?.type == SubscriptionType.NONE || data.subscriptionUser?.subscription?.status == SubscriptionStatus.CANCELED  || data.subscriptionUser?.subscription?.status == SubscriptionStatus.PENDING){
          <div class="past-due-container social-images-content">
            <strong><p class="subtle-gradient-text">No current subscription found</p></strong>
            <button mat-fab extended (click)="this.authService.authThen(onClose, this, paymentService.getAllPaid())">
              <mat-icon>trending_up</mat-icon>
              Upgrade
            </button>
          </div>
        }
      </ng-container>
    </mat-tab>
    <mat-tab label="Social Images">
      <div class="social-images-content past-due-container">
        @if(!userImages){
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        }@else{
          @if(userImages && userImages.length > 0){
           <mat-grid-list [cols]="3" rowHeight="1:1" gutterSize="15px">
            @for (userImage of userImages; track userImage; let i = $index;) {
              <mat-grid-tile class="icon-holder-grid" (click)="openUserImage(userImage)">
                @if(!images[userImage.fileName]){<mat-icon>pending</mat-icon>}
                @if(images[userImage.fileName] == ImageLoadStatus.ERROR){<mat-icon>error</mat-icon>}
                @if(images[userImage.fileName] != ImageLoadStatus.ERROR){
                <img #img [style.position]="images[userImage.fileName] == ImageLoadStatus.LOADED ? 'static' : 'absolute'"
                  [appLazyLoad]="getUriFromImage(userImage)" [style.width]="images[userImage.fileName] ? null : '0px'"
                  (load)="images[userImage.fileName] =  ImageLoadStatus.LOADED; img.style.opacity = '1'"
                  (error)="images[userImage.fileName] =  ImageLoadStatus.ERROR" class="grid-image pointer fade-img" />
                }
              </mat-grid-tile>
              }
            </mat-grid-list>
          }@else {
            <div>
              <strong><p class="subtle-gradient-text">No images uploaded yet</p></strong>
            </div>
          }
        }
      </div>
    </mat-tab>
  </mat-tab-group>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button (click)="onClose()">Close</button>
</mat-dialog-actions>