import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { AssetComponent } from '../asset.component';
import { EditMode } from '../../../service/toolbar.service';

@Component({
  selector: 'app-sticker-asset',
  standalone: true,
  imports: [],
  templateUrl: './sticker-asset.component.html',
  styleUrl: './sticker-asset.component.scss'
})
export class StickerAssetComponent extends AssetComponent implements AfterViewInit {


  @ViewChild('imgAsset') public imgAsset ?: any

  public getRandom(){
    return Math.random().toString();
  }
  
  override ngAfterViewInit(): void {
    super.ngAfterViewInit()
    this.keepRatio = true
    this.onMoveableAssetSet.emit(this.imgAsset.nativeElement)
  }

  override updateActiveAsset(): void {
    super.updateActiveAsset()
    if(this.data){
      this.toolbarService.editModeSubject.next({mode : EditMode.STICKER, asset : this.data})
    }
  }

}
