import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, of, switchMap } from 'rxjs';
import { APIName, HTTPMethods, ServiceLookupService } from './service-lookup.service';
import { DankTankAuthService } from './dank-tank-auth.service';
import { ServiceResult } from './result';

export interface UploadImage{
  fileName : string
  fileExtension : string,
  uploadedAt : string
}

@Injectable({
  providedIn: 'root'
})
export class ImageUploadService {

  constructor(private http: HttpClient, private serviceLookup: ServiceLookupService, private authService: DankTankAuthService) { }

  /*
  downloadImage(imageUrl: string): Observable<Blob> {
    return this.http.get(imageUrl, { responseType: 'blob' });
  }
    */

  getAllImagesForUser(): Observable<ServiceResult<UploadImage[]>> {
    const uri = this.serviceLookup.lookup(APIName.ALL_USER_IMAGES, HTTPMethods.GET)
    const token = this.authService.sessionSubject.value?.access_token

    if (!token) of(this.transformError(new Error("User is not logged in")))

    const options = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${token}`,
      })
    }

    return this.http.get<ServiceResult<UploadImage[]>>(uri, options).pipe(
      map(response => ({data : response.data})),
      catchError(error => of(this.transformError<UploadImage[]>(error)))
    )
  }

  uploadImage(imageUrl: string): Observable<ServiceResult<UploadImage>> {
    const uri = this.serviceLookup.lookup(APIName.UPLOAD_IMAGE, HTTPMethods.POST)
    const token = this.authService.sessionSubject.value?.access_token

    if (!token) of(this.transformError(new Error("User is not logged in")))

    const filename = `image_${new Date().getTime()}.png`
    const file: File = this.convertToFile(imageUrl, filename)

    const formData = new FormData()
    formData.append('image', file, file.name) // Replace 'image' with your backend field name

    const options = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${token}`,
      })
    }

    return this.http.post<ServiceResult<UploadImage>>(uri, formData, options).pipe(
      map(response => ({data : response.data})),
      catchError(error => of(this.transformError<UploadImage>(error)))
    )
  }
  
  private convertToFile(dataUrl: string, filename: string): File {
    const arr = dataUrl.split(',');
    const mimeMatch = arr[0].match(/:(.*?);/);
  
    if (!mimeMatch) {
      throw new Error('Invalid data URL format');
    }
  
    const mime = mimeMatch[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
  
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
  
    return new File([u8arr], filename, { type: mime });
  }


  private transformError<T>(error: any): ServiceResult<T> {
    return { error };
  }
}
