import { inject, Inject, Injectable, PLATFORM_ID, Renderer2, RendererFactory2 } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { SEODataResponse, SEOPayload, SEOService } from './seo.service';
import { Subject, take } from 'rxjs';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { app } from '../../../server'; // Import the express app
import { Response } from 'express';

// Define the interface for meta tags
interface MetaTags {
  title: string;
  description: string;
  keywords: string;
  image: string;
  url: string;
  logo: string
}

export enum MetaTag {
  TITLE = 1,
  IMAGE = 2,
  DESCRIPTION = 3,
  URL = 4, // Added URL for structured data updates
  KEYWORDS = 5, // Added Keywords for structured data updates
}

@Injectable({
  providedIn: 'root'
})
export class MetaService {

  private seoData?: SEOPayload;
  private renderer: Renderer2;
  private readonly document = inject(DOCUMENT);

  constructor(
    private meta: Meta,
    private title: Title,
    private seo: SEOService,
    @Inject(PLATFORM_ID) private platformId: Object,
    private rendererFactory: RendererFactory2
  ) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }

  public setDefaultMetaTags(): Subject<boolean> {
    const sub: Subject<boolean> = new Subject();
    if (!this.seoData) {
      this.seo.getSEOData().pipe(take(1)).subscribe({
        next: (data: SEODataResponse) => {
          if (data.data) {
            this.seoData = data.data;
            this.localSetDefault();
            sub.next(true);
          } else {
            sub.next(false);
          }
        }
      });
    } else {
      this.localSetDefault();
      sub.next(true);
    }
    return sub;
  }

  public setSpecificTag(tag: MetaTag, value: string) {
    switch (tag) {
      case MetaTag.TITLE:
        const toSetTitle: string = `${value} - DankTank`;
        this.title.setTitle(toSetTitle);
        this.updateOrAddMetaTag('property', 'og:title', toSetTitle);
        this.updateOrAddMetaTag('name', 'twitter:title', toSetTitle);
        this.updateOrAddStructuredData({ title: toSetTitle });
        break;
      case MetaTag.DESCRIPTION:
        const toSetDescription: string = `${value} - ${this.seoData?.default.description || ''}`;
        this.updateOrAddMetaTag('name', 'description', toSetDescription);
        this.updateOrAddMetaTag('property', 'og:description', toSetDescription);
        this.updateOrAddMetaTag('name', 'twitter:description', toSetDescription);
        this.updateOrAddStructuredData({ description: toSetDescription }); 
        break;
      case MetaTag.IMAGE:
        this.updateOrAddMetaTag('property', 'og:image', value);
        this.updateOrAddMetaTag('name', 'twitter:image', value);
        this.updateOrAddStructuredData({ image: value }); 
        break;
      case MetaTag.URL:
        this.updateOrAddStructuredData({ url: value });
        break;
      case MetaTag.KEYWORDS:
        this.updateOrAddStructuredData({ keywords: value });
        break;
    }
  }

  private localSetDefault() {
    this.addMetaTags({
      title: this.seoData?.default.title || '',
      description: this.seoData?.default.description || '',
      keywords: this.seoData?.default.keywords || '',
      image: this.seoData?.default.image || '',
      url: this.seoData?.default.url || '',
      logo: this.seoData?.default.logo || ''
    });
  }

  private addMetaTags(tags: MetaTags) {
    if (!tags.title || (tags.title && tags.title.length === 0)) return;

    this.title.setTitle(tags.title);
    this.updateOrAddMetaTag('name', 'description', tags.description);
    this.updateOrAddMetaTag('name', 'keywords', tags.keywords);
    this.updateOrAddMetaTag('property', 'og:type', 'website');
    this.updateOrAddMetaTag('property', 'og:title', tags.title);
    this.updateOrAddMetaTag('property', 'og:description', tags.description);
    this.updateOrAddMetaTag('property', 'og:image', tags.image);
    this.updateOrAddMetaTag('property', 'og:url', tags.url);
    this.updateOrAddMetaTag('name', 'twitter:card', 'photo');
    this.updateOrAddMetaTag('name', 'twitter:title', tags.title);
    this.updateOrAddMetaTag('name', 'twitter:site', "@danktank");
    this.updateOrAddMetaTag('name', 'twitter:description', tags.description);
    this.updateOrAddMetaTag('name', 'twitter:image', tags.image);

    this.updateOrAddMetaTag('name', 'apple-mobile-web-app-capable', "yes");
    this.updateOrAddMetaTag('name', 'apple-mobile-web-app-status-bar-style', "black");
    this.updateOrAddMetaTag('name', 'apple-mobile-web-app-title', "DankTank");
    this.updateOrAddMetaTag('name', 'theme-color', "#560CB8");
    this.updateOrAddMetaTag('property', 'og:site_name', "DankTank");
    this.updateOrAddMetaTag('name', 'robots' , 'index, follow')

    this.updateOrAddStructuredData(tags);
  }

  private updateOrAddMetaTag(attrName: string, attrValue: string, content: string) {
    const existingTag = this.meta.getTag(`${attrName}="${attrValue}"`);
    if (existingTag) {
      this.meta.updateTag({ [attrName]: attrValue, content });
    } else {
      this.meta.addTag({ [attrName]: attrValue, content });
    }
  }

  private createStructuredData(tags: MetaTags): object | null {
    if (!tags.title || (tags.title && tags.title.length === 0)) return null;

    const structuredData = {
      "@context": "https://schema.org",
      "@type": "WebPage",
      "name": tags.title,
      "description": tags.description,
      "url": tags.url,
      "image": {
        "@type": "ImageObject",
        "url": tags.image
      },
      "keywords": tags.keywords,
      "publisher": {
        "@type": "Organization",
        "name": "DankTank",
        "logo": {
          "@type": "ImageObject",
          "url": tags.logo
        }
      }
    };

    return structuredData;
  }

  private updateOrAddStructuredData(updatedTags: Partial<MetaTags>): void {
    // Get the existing structured data from the script tag if it exists
    let existingStructuredData: MetaTags = {
      title: this.seoData?.default.title || '',
      description: this.seoData?.default.description || '',
      keywords: this.seoData?.default.keywords || '',
      image: this.seoData?.default.image || '',
      url: this.seoData?.default.url || '',
      logo : this.seoData?.default.logo || ''
    };
  
    const ldJsonScriptTag = this.document.head.querySelector(`script[type='application/ld+json']`);
    if (ldJsonScriptTag) {
      try {
        const currentScriptContent = ldJsonScriptTag.textContent;
        if (currentScriptContent) {
          const parsedData = JSON.parse(currentScriptContent);
          // Map existing data to MetaTags based on available properties
          existingStructuredData = {
            title: parsedData.name || existingStructuredData.title,
            description: parsedData.description || existingStructuredData.description,
            keywords: parsedData.keywords || existingStructuredData.keywords,
            image: parsedData.image?.url || existingStructuredData.image,
            url: parsedData.url || existingStructuredData.url,
            logo: parsedData.logo || existingStructuredData.logo
          };
        }
      } catch (error) {
        console.error('Error parsing existing structured data:', error);
      }
    }
  
    // Merge updatedTags into existingStructuredData
    const updatedMetaTags: MetaTags = { ...existingStructuredData, ...updatedTags };
  
    const structuredData = this.createStructuredData(updatedMetaTags);
    if (structuredData) {
      const stringifiedStructuredData = JSON.stringify(structuredData);
      if (ldJsonScriptTag) {
        ldJsonScriptTag.textContent = stringifiedStructuredData;
      } else {
        const newLdJsonScriptTag = this.document.createElement('script');
        newLdJsonScriptTag.setAttribute('type', 'application/ld+json');
        newLdJsonScriptTag.textContent = stringifiedStructuredData;
        this.document.head.appendChild(newLdJsonScriptTag);
      }
    }
  }
}