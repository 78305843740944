import { AfterViewInit, ChangeDetectorRef, Component, NgZone } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { interval, map, of, take, throttleTime } from 'rxjs';

@Component({
  selector: 'app-loader',
  standalone: true,
  imports: [MatProgressBarModule, MatDialogModule, MatButtonModule, MatDividerModule],
  templateUrl: './loader.component.html',
  styleUrl: './loader.component.scss'
})
export class LoaderComponent implements AfterViewInit {
  public percentLoaded : number = 0

  constructor(private dialog : MatDialogRef<LoaderComponent>, private cdr : ChangeDetectorRef, private zone : NgZone ){}

  ngAfterViewInit(): void {
    
  }

  private hasStarted : boolean = false
  public startLoad(){
    this.zone.runOutsideAngular(() =>{
      if(this.hasStarted)return
      this.hasStarted = true
      const observable =  interval(25).pipe(
        take(20),
        map(i => (i + 1) * 5)
      )

      observable.subscribe((val : number) => {
        this.zone.run(() => {
          this.percentLoaded = val
          this.cdr.detectChanges()
        })
        console.log(val)
        if(val == 100){
          setTimeout(() => {
            this.zone.run(() => {
              this.dialog.close()
            })
          }, 200);
        }
      })
    })
  }
    
}
