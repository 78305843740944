<h2 mat-dialog-title class="dialog-title">{{step?.title || tour?.tourName}}</h2>
<mat-dialog-content class="subtle-gradient-text" style="margin-top: 20px;">
  <strong>{{step?.message}}</strong>
</mat-dialog-content>
<mat-dialog-actions align="end">
    @if(!tour?.isComplete()){
        <button (click)="tour?.end()"  mat-button>Exit Tour</button>
        <button mat-button [disabled]="!tour?.canGoPrevious()" (click)="tour?.previous()" >Previous</button>
        <button [disabled]="!tour?.canGoNext()" (click)="tour?.next()" mat-button class="loading-button">
            @if(tour?.isPerformingAsync()){
                <mat-progress-spinner class="spinner" diameter="20" mode="indeterminate"></mat-progress-spinner>
            }  
            <span style="next">
                Next
            </span>
        </button>
    }
    @if(tour?.isComplete()){
        <button mat-button (click)="createGuidedTour()" >Restart Tour</button>
        <button mat-button (click)="tour?.end()" >Done</button>
    }
</mat-dialog-actions>