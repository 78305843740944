@if(authService.subscriptionUserSubject.value?.data?.subscription?.status != SubscriptionStatus.FREE){
    @if(!hasManaged){
        <button mat-fab extended (click)="openPortalUrl()" [disabled]="!portalUrl">
            <div class="button-content">
                @if(portalUrl){
                    <mat-icon>settings</mat-icon>
                }@else {
                    <mat-progress-spinner mode="indeterminate" diameter="25"></mat-progress-spinner>
                }
                <span>Manage Subscription</span>
            </div>
        </button>
    }@else{
    <button mat-fab extended (click)="reload()">
        <mat-icon>refresh</mat-icon>
        Refresh to Apply Changes
    </button>
    }
}