import * as i0 from '@angular/core';
import { InjectionToken, inject, ElementRef, input, computed, Directive } from '@angular/core';
import autoAnimate from '@formkit/auto-animate';
const isPlugin = config => typeof config === 'function';
const GLOBAL_AUTO_ANIMATE_OPTIONS = new InjectionToken('Global AutoAnimate Options or Plugin', {
  factory: () => ({})
});
class NgAutoAnimateDirective {
  constructor() {
    this.el = inject(ElementRef);
    this.globalOptions = inject(GLOBAL_AUTO_ANIMATE_OPTIONS);
    this.autoAnimateOptions = input('', {
      alias: 'auto-animate'
    });
    this.options = computed(() => {
      const localOptions = this.autoAnimateOptions();
      if (typeof localOptions === 'string') {
        // Default case, when no options or plugin is passed
        return this.globalOptions;
      }
      // When either some options or a plugin is passed
      if (isPlugin(this.globalOptions) || isPlugin(localOptions)) {
        // A plugin must replace any previously set options or plugin.
        // A plugin must be replaced by options or another plugin.
        return localOptions;
      }
      // When plugins are not involved
      return {
        ...this.globalOptions,
        ...localOptions
      };
    });
  }
  ngAfterViewInit() {
    autoAnimate(this.el.nativeElement, this.options());
  }
  static {
    this.ɵfac = function NgAutoAnimateDirective_Factory(t) {
      return new (t || NgAutoAnimateDirective)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: NgAutoAnimateDirective,
      selectors: [["", "auto-animate", ""]],
      inputs: {
        autoAnimateOptions: [1, "auto-animate", "autoAnimateOptions"]
      },
      standalone: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgAutoAnimateDirective, [{
    type: Directive,
    args: [{
      selector: '[auto-animate]',
      standalone: true
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { GLOBAL_AUTO_ANIMATE_OPTIONS, NgAutoAnimateDirective };
