import { AfterViewInit, Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { Asset } from '../../vo/GridSection';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule } from '@angular/forms';
import { MatDivider, MatDividerModule } from '@angular/material/divider';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';

@Component({
  selector: 'app-text-input-bottom-sheet',
  standalone: true,
  imports: [MatButtonModule, MatIconModule, MatFormFieldModule, MatDividerModule, MatInputModule, FormsModule],
  templateUrl: './text-input-bottom-sheet.component.html',
  styleUrl: './text-input-bottom-sheet.component.scss'
})
export class TextInputBottomSheetComponent implements AfterViewInit {

  @ViewChild('textInput') textInput ?: ElementRef

  constructor(@Inject(MAT_BOTTOM_SHEET_DATA) public data: Asset, private ref : MatBottomSheetRef){
    console.log()
  }

  ngAfterViewInit() {
    // Focus the input field when the bottom sheet is fully initialized
      setTimeout(() => {
        if (this.textInput) {
          this.textInput.nativeElement.focus()
        }
      }, 100)
  }

  public close(){
    this.ref.dismiss()
  }
}
