import { Inject, Injectable, OnInit, PLATFORM_ID } from '@angular/core';
import { DashboardComponent } from '../component/dashboard/dashboard.component';
import { decompressFrames, ParsedFrame, parseGIF } from 'gifuct-js';
import { ScreenAction } from './analytics.service';
//@ts-ignore 
//import GIF from '../util/gif'
//@ts-ignore 
import domtoimage from 'dom-to-image-more';
//import domtoimage from '../libs/domtoimage/domtoimage'
import { from, fromEvent, mergeMap, Observable, Subject, take, toArray } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';
import { FontService } from './font.service';
import { MediaType } from '../vo/GridSection';
import { CanvasSaveService } from './canvas-save-service';
import { DeviceDetectorService } from './device-detector.service';
import { AppService } from './app.service';
//@ts-ignore 
//import { changeDpiDataUrl } from 'changedpi';

//@ts-ignore 
type GifType = typeof import('../util/gif').GIF;

export interface ImageGenerationInfo {
  location?: string,
  completion: number,
}

interface CanvasWithFrame {
  canvas: HTMLCanvasElement
  frame: ParsedFrame
}

@Injectable({
  providedIn: 'root'
})
export class ImageGeneratorService {
  GIF !: any
  //GIF !: GifType
  //domtoimage !: any


  constructor(private appService : AppService, @Inject(PLATFORM_ID) protected platformId: Object, private fontService: FontService, private canvasSave: CanvasSaveService, private deviceDetector : DeviceDetectorService) {

  }


  private initTypes() {
    if (isPlatformBrowser(this.platformId)) {
      this.GIF = require('../util/gif')
      //this.GIF = this.gifLib.GIF
      console.log()
      //this.domtoimage = require('dom-to-image-more')
    }
  }

  ////DEPRECATED
  private generateImage(): Observable<ImageGenerationInfo> {
    const subject: Subject<ImageGenerationInfo> = this.getSubject()
    this.generateGif().subscribe({
      next: (value: ImageGenerationInfo) => {
        subject.next(value)
      },
      error: () => {
        subject.error(true)
      },
      complete: () => {
        subject.complete()
      }
    })
    return subject.asObservable()
  }

  generateStaticImage(): Observable<ImageGenerationInfo> {
    this.initTypes()
    const subject: Subject<ImageGenerationInfo> = this.getSubject()
    const rg = this.appService.rootGridHolder
    //const rg = this.rootGrid
    const ne = rg

    const cl = (this.appService.gridSection?.children.length || 0)
    const bw = (this.appService.gridSection?.borderWidth || 0)
    const h = (this.appService.gridSection?.rootGridSectionHeight || 0)
    const w = (this.appService.gridSection?.rootGridSectionWidth || 0)
    const height = h
    const width = w
    if(ne)this.snapTilValid(ne, 20).pipe(take(1)).subscribe((dataUrl: string) => {
      /*domtoimage
        .toPng(ne, { cacheBust: true, copyDefaultStyles: true, height: height, width: width })
        .then((dataUrl: any) => {*/
      if (this.appService.gridSection) {
        //dashboard.gridSection.downloadURL = dataUrl
        //dashboard.toggleMode()
        subject.next({ location: dataUrl, completion: 100 })
      }
    })
    return subject.asObservable()
  }

  private currentTime: number = 0
  private canvases: HTMLCanvasElement[] = []
  readonly fps: number = 8
  readonly frameDuration = 1000 / this.fps

  generateWebP(): Observable<ImageGenerationInfo> {
    this.canvasSave.deleteAll()
    this.currentTime = 0;
    this.canvases = []
    this.initTypes()
    const subject: Subject<ImageGenerationInfo> = this.getSubject()

    // Start pausing and calling function at every frame
    this.pauseAtEveryFrame().subscribe((value: ImageGenerationInfo) => subject.next(value))

    return subject.asObservable()
  }

  // Function to handle pausing and resuming at each frame
  private pauseAtEveryFrame(): Observable<ImageGenerationInfo> {
    const subject: Subject<ImageGenerationInfo> = this.getSubject()
    const videoElement: HTMLVideoElement | undefined = this.appService.video

    this.currentTime = 0;
    this.canvases = []

    //setting the video playhead is async
    if (videoElement) fromEvent(videoElement, 'seeked').pipe(take(1)).subscribe(() => {
      setTimeout(() => {
        this.pauseAndCallFunction().subscribe((value: ImageGenerationInfo) => subject.next(value))
      }, this.frameDuration);
      //}, 10);
    })

    // Ensure video starts from the beginning
    if (videoElement) videoElement.currentTime = 0;

    return subject.asObservable()
  }

  private restartVideo(videoElement?: HTMLVideoElement) {
    if (videoElement) {
      videoElement.currentTime = 0
      videoElement.loop = true
      videoElement.play()
    }
  }

  // Function to resume video playback
  private resumeVideo(subject: Subject<ImageGenerationInfo>) {
    let videoElement: HTMLVideoElement | undefined = this.appService.video
    if (videoElement) {
      videoElement.play();
      //if (videoElement) fromEvent(videoElement, 'seeked').pipe(take(1)).subscribe(() => setTimeout(pauseAndCallFunction, frameDuration))
      if (videoElement) fromEvent(videoElement, 'seeked').pipe(take(1)).subscribe(() => {
        setTimeout(() => {
          this.pauseAndCallFunction().subscribe((value: ImageGenerationInfo) => subject.next(value))
        }, 0)
        // }, 10);

      })
      videoElement.currentTime = this.currentTime;
      //setTimeout(pauseAndCallFunction, frameDuration); // Pause again after frame duration
    }
  }

  private c?: HTMLCanvasElement
  private context: CanvasRenderingContext2D | null = null
  // Function to pause video and call your custom function
  private pauseAndCallFunction(subject?: Subject<ImageGenerationInfo>): Observable<ImageGenerationInfo> {
    subject = subject ? subject : this.getSubject()
    let videoElement: HTMLVideoElement | undefined = this.appService.video

    if (videoElement) {
      videoElement.pause()
        setTimeout(() => {
          this.doSnapshot(this.c as HTMLCanvasElement, videoElement, subject)
        })
    }

    return subject.asObservable()
  }

  private doSnapshot(canvas: HTMLCanvasElement, videoElement: HTMLVideoElement, subject: Subject<ImageGenerationInfo>) {
    setTimeout(() => {

      const rg = this.appService.rootGridHolder
      const ne = rg

      if(ne)this.snapTilValid(ne).pipe(take(1)).subscribe((dataUrl: string) => {
          this.canvasSave.add(dataUrl).pipe(take(1)).subscribe(() => {
            //after we captured the current frame, restart it
            this.currentTime += this.frameDuration / 1000; // Increment current time by frame duration
            if (this.currentTime <= videoElement.duration) {
              //setTimeout(resumeVideo, 1); // Resume after frame duration
              const totalFrames = videoElement.duration * this.fps
              const currentFrame = Math.floor(videoElement.currentTime * this.fps)
              this.updateProgress(0, 90, currentFrame, totalFrames, subject)
              this.resumeVideo(subject)
            } else if (this.currentTime >= videoElement.duration) {
              console.log('Video has ended');
              const GIF = require('../util/gif')
              var g = new GIF({
                workers: 4,
                quality: 10
              })
              this.canvasSave.getAll().pipe(take(1)).subscribe((canvases : any[]) => {
                const test : string[] = []
                canvases.forEach((can: any) => {
                  test.push(can.dataURL)
                  //g.addFrame(img, { delay: this.frameDuration })
                })
                this.loadImages(test).pipe(take(1)).subscribe((elements : HTMLCanvasElement[]) =>{
                  elements.forEach((element : HTMLCanvasElement) => {
                    g.addFrame(element, { delay: this.frameDuration })
                  }) 
                  g.on('progress', (val: number) => {
                    this.updateProgress(90, 100, val, 1, subject)
                  })
                  g.on('finished', (blob: any) => {
                    //window.open(URL.createObjectURL(blob));
                    //if (dashboard.gridSection?.media) dashboard.gridSection.downloadURL = URL.createObjectURL(blob)
                    this.restartVideo(videoElement)
                    const downloadURL: string = URL.createObjectURL(blob)
                    subject.next({ location: downloadURL, completion: 100 })
                    subject.complete()
                    this.canvases.forEach((canvas: HTMLCanvasElement) => {
                      canvas.parentNode?.removeChild(canvas)
                    })
                  });
    
                  g.render()
    
                  this.restartVideo(videoElement)
                  return
                })
              })
            }
          })
        //}
        //console.log(dataUrl)
        //img.src = dataUrl
      })
    });
  }

  private loadImage(dataURL: string): Observable<HTMLCanvasElement> {
    return new Observable<HTMLCanvasElement>((observer) => {
      const img = new Image();
  
      img.onload = () => {
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext('2d');
        if (ctx) {
          ctx.drawImage(img, 0, 0);
          observer.next(canvas);
          observer.complete();
        } else {
          observer.error(new Error('Failed to get 2D context'));
        }
      };
  
      img.onerror = (error) => {
        observer.error(error);
      };
  
      img.src = dataURL;
    });
  }
  
  private loadImages(dataURLs: string[]): Observable<HTMLCanvasElement[]> {
    return from(dataURLs).pipe(
      mergeMap((dataURL) => this.loadImage(dataURL)), 
      toArray() 
    );
  }

  private snapTilValid(ne: HTMLElement, validSize: number = 75, sub?: Subject<any>, map?: Map<number, number>, cacheBust: boolean = false): Observable<string> {
    sub = sub ? sub : new Subject()
    map = map ? map : new Map()
    const size : number = this.appService.gridSection?.media?.mediaType == MediaType.LOCAL_IMAGE ? 800 : 400
    const multiplier = size / Math.min(this.appService?.gridSection?.rootGridSectionWidth || 0, this.appService?.gridSection?.rootGridSectionHeight || 0)
    const arg = {
      fontList: this.fontService.usedFonts,
      cacheBust: cacheBust,
      height: (this.appService?.gridSection?.rootGridSectionHeight || 0) * multiplier,
      width: (this.appService?.gridSection?.rootGridSectionWidth || 0) * multiplier,
      style: {
        transform: "scale(" + multiplier + ")",
        transformOrigin: "top left",
        width: (this.appService?.gridSection?.rootGridSectionWidth || 0) + "px",
        height: (this.appService?.gridSection?.rootGridSectionHeight || 0) + "px"
      }
    }
    domtoimage
      //.toPng(ne, {scale : multiplier, fontList : this.fontService.usedFonts, cacheBust: cacheBust, copyDefaultStyles: true, height: dashboard.gridSection?.rootGridSectionHeight || 0, width: dashboard.gridSection?.rootGridSectionWidth || 0 })
      .toPng(ne, arg)
      .then((dataUrl: any) => {
        // When a blank background appears, the image size will be much smaller than before. Find a value that can determine whether the generation was successful
        const l = dataUrl.length
        const mb = 1024
        const kb = l / mb
        validSize = this.appService.gridSection?.hasMedia ? 20 : 2
        if (kb > validSize && map) {
          //we're good here
          //ALWAYS retry the first time
          if ((this.deviceDetector.isTouchOnlyDevice() && map.size == 0) || ((this.deviceDetector.isTouchOnlyDevice() || this.deviceDetector.isUsingWebKit()) && (map.get(kb) || 0) < (this.appService.gridSection?.assets.length || 0) + 5)) { //the +5 seems to be the right buffer to make it work in webkit
            setTimeout(() => {
              this.snapTilValid(ne, validSize, sub, map, false)
            }, 1);
            /*
            if(this.deviceDetector.isTouchOnlyDevice() && !dashboard.video){
              this.snapTilValid(ne, dashboard, validSize, sub, map, false)
            }else{
              sub.next(dataUrl)
            }
              */
          } else {
            //const newDataUrl = changeDpiDataUrl(dataUrl, 1500)
            if(this.deviceDetector.isTouchOnlyDevice() || this.deviceDetector.isUsingWebKit()){
              map = new Map()
            }
            sub.next(dataUrl)
          }
        } else {
          if(map){
            //if we've hit the same number repeatedly, even if its lower than the threshold return it
            if ((map.get(kb) || 0) > 4 && (map.get(kb) || 0) < 10) {
              //we're not good, but we're in a loop
              this.snapTilValid(ne, validSize, sub, map, true)
            } else if ((map.get(kb) || 0) == 10) {
              ///const newDataUrl = changeDpiDataUrl(dataUrl, 1500)
              sub.next(dataUrl)
            } else {
              this.snapTilValid(ne, validSize, sub, map)
            }
          }
        }
        if(map)map.set(kb, (map.get(kb) || 0) + 1)
      })
    return sub.asObservable()
  }



  public generateGif(): Observable<ImageGenerationInfo> {
    this.initTypes()
    const subject: Subject<ImageGenerationInfo> = this.getSubject()

    const rg = this.appService.rootGridHolder
    //const rg = this.rootGrid
    //const ne = rg.nativeElement

    const cl = (this.appService.gridSection?.children.length || 0)
    const bw = (this.appService.gridSection?.borderWidth || 0)
    const h = (this.appService.gridSection?.rootGridSectionHeight || 0)
    const w = (this.appService.gridSection?.rootGridSectionWidth || 0)

    //const height : number =  cl > 0 ? (bw * cl) + h : h
    const height = h
    const width = w

    if (this.appService.gridSection) this.appService.gridSection.downloadURL = undefined


    const media: string = this.appService.gridSection?.media?.mediaLocation || ''

    const mediaURLs: string[] = []

    var promisedGif = fetch(media)
      .then(resp => resp.arrayBuffer())
      .then(buff => {
        var gif = parseGIF(buff)
        var frames: ParsedFrame[] = decompressFrames(gif, true);
        this.canvasesAndFrames = []
        subject.next({ completion: 33 })
        this.loopFrames(frames.length, frames, media).subscribe({
          next: (value: ImageGenerationInfo) => {
            subject.next(value)
          },
          error: () => {
            subject.error(true)
          },
          complete: () => {
            subject.complete()
          }
        })
      });

    return subject.asObservable()
  }

  private canvasesAndFrames: CanvasWithFrame[] = []
  private canvas?: HTMLCanvasElement
  private ctx?: CanvasRenderingContext2D
  private imageData?: ImageData

  private loopFrames(totalFrames: number, frames: ParsedFrame[], originalMedia: string, subject?: Subject<ImageGenerationInfo>): Observable<ImageGenerationInfo> {
    subject = subject ? subject : new Subject<ImageGenerationInfo>()
    if (this.appService.gridSection?.media) {
      const frame: ParsedFrame | undefined = frames.shift() || undefined

      if (frame && frame.disposalType != 2) {
        const imageData = new ImageData(frame.patch, frame.dims.width, frame.dims.height);

        if (!this.canvas) {
          this.canvas = document.createElement('canvas');
          this.canvas.width = frame.dims.width;
          this.canvas.height = frame.dims.height;

          // Get the 2D rendering context
          this.ctx = this.canvas.getContext('2d') || undefined
        }

        if (
          !this.imageData ||
          frame.dims.width != this.imageData.width ||
          frame.dims.height != this.imageData.height
        ) {
          this.canvas.width = frame.dims.width
          this.canvas.height = frame.dims.height
          if (this.ctx) this.imageData = this.ctx.createImageData(frame.dims.width, frame.dims.height)
        }

        if (this.imageData) this.imageData.data.set(frame.patch)

        // Draw the ImageData onto the canvas
        if (this.imageData) this.ctx?.putImageData(this.imageData, 0, 0)
        //this.ctx?.drawImage(this.canvas, frame.dims.width, frame.dims.height)

        // Convert the canvas content to a data URL
        const dataURL = this.canvas.toDataURL();

        console.log()

        // Remove the canvas element
        //canvas.remove();

        //weve grabbed the individual frame URL right here and set it to the background, we'll then loop through those and take screengrabs of them
        //console.log("Setting media", dataURL)
        this.appService.gridSection.media.mediaLocation = this.appService.gridSection.media.originalLocation = dataURL

        setTimeout(() => {
          if (this.appService.image) {
            if (this.appService.image.complete) {
              this.doGifSnapshot(totalFrames, frames, frame, originalMedia, subject)
            } else {
              fromEvent(this.appService.image, 'load').pipe(take(1)).subscribe(() => {
                this.doGifSnapshot(totalFrames, frames, frame, originalMedia, subject)
              })
            }
          }
        })



      }
    }
    return subject
  }

  private doGifSnapshot(totalFrames: number, frames: ParsedFrame[], frame: ParsedFrame, originalMedia: string, subject: Subject<ImageGenerationInfo>) {
    setTimeout(() => {

      const rg = this.appService.rootGridHolder
      const ne = rg
      if(ne)this.snapTilValid(ne, 1).pipe(take(1)).subscribe((dataUrl: string) => {
        /*domtoimage
          .toPng(ne, { cacheBust: false, copyDefaultStyles: true, height: dashboard.gridSection?.rootGridSectionHeight || 0, width: dashboard.gridSection?.rootGridSectionWidth || 0 })
          .then((dataUrl: any) => {*/
        const offscreenCanvas = document.createElement('canvas');
        const context = offscreenCanvas.getContext('2d');

        //create a canvas and set the background of it to the image, we can then loop through the canvas and add them as a gif, then we will use web workers to create the gif
        const img = new Image();
        img.onerror = () => {
          console.log()
        }
        img.onload = () => {
          offscreenCanvas.width = img.width;
          offscreenCanvas.height = img.height;
          if (context) context.drawImage(img, 0, 0);
          if (frame.disposalType == 2) {
            console.log()
          }
          this.canvasesAndFrames.push({ canvas: offscreenCanvas, frame: frame });
          //console.log("Frames ", frames.length)
          if (frames.length > 0) {
            this.updateProgress(33, 90, totalFrames - frames.length, totalFrames, subject)
            this.loopFrames(totalFrames, frames, originalMedia, subject)
          } else {
            if (this.appService.gridSection?.media) this.appService.gridSection.media.mediaLocation = this.appService.gridSection.media.originalLocation = originalMedia
            subject.next({ completion: 66 })
            this.makeGif(this.canvasesAndFrames).subscribe({
              next: (value: ImageGenerationInfo) => {
                subject.next(value)
              },
              error: () => {
                subject.error(true)
              },
              complete: () => {
                subject.complete()
              }
            })
          }
        }
        img.src = dataUrl
      })
    }, 1)
  }

  private makeGif(cAndFs: CanvasWithFrame[]): Observable<ImageGenerationInfo> {
    var subject: Subject<ImageGenerationInfo> = this.getSubject()
    const GIF = require('../util/gif')
    var g = new GIF({
      workers: 4,
      quality: 10
    })
    cAndFs.forEach((cAndF: CanvasWithFrame) => {
      g.addFrame(cAndF.canvas, { delay: cAndF.frame.delay });
    })
    g.on('progress', (val: number) => {
      this.updateProgress(90, 100, val, 1, subject)
    })
    g.on('finished', (blob: any) => {
      //window.open(URL.createObjectURL(blob));
      //if (dashboard.gridSection?.media) dashboard.gridSection.downloadURL = URL.createObjectURL(blob)
      const downloadURL: string = URL.createObjectURL(blob)
      subject.next({ location: downloadURL, completion: 100 })
      subject.complete()
      cAndFs.forEach((cAndF: CanvasWithFrame) => {
        cAndF.canvas.remove()
      })
    });

    g.render()
    return subject.asObservable()
  }

  private updateProgress(initialProgress: number, targetProgress: number, processedFrames: number, totalFrames: number, subject: Subject<ImageGenerationInfo>) {
    const progressRange = targetProgress - initialProgress

    // Calculate the current progress within the range
    const currentProgress = initialProgress + (processedFrames / totalFrames) * progressRange

    subject.next({ completion: currentProgress })
  }


  private getSubject(): Subject<ImageGenerationInfo> {
    return new Subject<ImageGenerationInfo>
  }
}
