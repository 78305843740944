import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { DankTankAuthService } from '../../service/dank-tank-auth.service';
import { PaymentService, SubscriptionStatus } from '../../service/payment.service';
import { take } from 'rxjs';
import { ServiceResult } from '../../service/result';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
  selector: 'app-profile-button',
  standalone: true,
  imports: [MatButtonModule, MatIconModule, MatProgressSpinnerModule],
  templateUrl: './profile-button.component.html',
  styleUrl: './profile-button.component.scss'
})
export class ProfileButtonComponent {

  public hasManaged : boolean = false
  public portalUrl ?: string
  public readonly SubscriptionStatus = SubscriptionStatus

  constructor(private paymentService : PaymentService, public authService : DankTankAuthService){


    paymentService.getPortalURL()?.pipe(take(1)).subscribe((response : ServiceResult<string>) => {
      if(response.data){
        this.portalUrl = response.data
      }else if(response.error){

      }
    })

  }

  public openPortalUrl(){
    if(!this.portalUrl)return
    this.hasManaged = true
    window.open(this.portalUrl, '_blank');
  }

  public reload(){
    window.location.reload()
  }

}
