import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AdBlockerService {
  private adBlockDetected = false;

  constructor() {
    this.checkForAdBlocker();
  }

  private checkForAdBlocker() {
    const ad = document.createElement('div');
    ad.className = 'adsbox';
    ad.style.height = '1px';

    const img = new Image();
    img.src = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAP///wAAACH5BAUUAAEALAAAAAABAAEAAAICTAEAOw=='; // Empty string for img.src

    document.body.appendChild(ad);
    setTimeout(() => {
      if (ad.offsetHeight === 0) {
        this.adBlockDetected = true;
      }
      document.body.removeChild(ad);
    }, 100);
  }

  public isAdBlockDetected(): boolean {
    return this.adBlockDetected;
  }
}
