import { AfterViewInit, ElementRef, QueryList, Renderer2 } from "@angular/core"
import { Observable, Subject, Subscription, take } from "rxjs"

export enum ShowFromDirection {
    TOP = 1,
    BOTTOM = 2,
    LEFT = 3,
    RIGHT = 4
}

export interface GuidedTourStep {
    title: string
    message: string
    originalDetails?: GuidedTourStepDetails
    details?: GuidedTourStepDetails
}

export interface GuidedTourStepDetails {
    scope?: any
    before?: (step: GuidedTourStep) => Observable<any> | void
    after?: (step: GuidedTourStep) => Observable<any> | void
    element?: ElementRef
    futureElement ?: QueryList<any>
    showFrom?: ShowFromDirection
    xOffset?: number
    yOffset?: number

}

export class GuidedTour {

    private steps: GuidedTourStep[] = []
    private currentStep?: GuidedTourStep
    private tourSubject?: Subject<GuidedTourStep>
    private performingAsync: boolean = false
    private currentIcon?: HTMLElement
    private complete: boolean = false
    private subscriptions: Subscription[] = []

    constructor(public tourName?: string, private renderer?: Renderer2) {
        this.tourName = tourName
    }

    public isPerformingAsync(): boolean {
        return this.performingAsync
    }

    public isComplete(): boolean {
        return this.complete
    }

    public addStep(title: string, message: string, details?: GuidedTourStepDetails): GuidedTour {
        if ((details && (details.before || details.after)) && !details.scope) throw new Error("If you pass in a before or after function, you must pass in a scope for it to be executed on")
        const step: GuidedTourStep = { title: title, message: message, details: details }
        const ox : number | undefined = details?.xOffset
        const oy : number | undefined = details?.yOffset
        
        //step.originalDetails = {scope : details?.scope, before : details?.before, after : details?.after, element : details?.element, showFrom : details?.showFrom, yOffset : 0, xOffset : 0}

        if(details?.futureElement){
            if(details.futureElement.first){
                details.element = details.futureElement.first
            }
            const obs : Observable<any> = details.futureElement.changes
            obs.subscribe(() => {
                details.element = details?.futureElement?.first
                if(this.currentStep == step)this.displayMarker(step)
            })
        }

        this.steps.push(step)
        return this
    }

    public removeStep(step: GuidedTourStep): GuidedTour {
        const index = this.steps.indexOf(step)
        if (index != undefined && index > -1 && index < this.steps.length) this.steps.splice(index, 1)
        return this
    }

    public removeAllSteps(): void {
        this.currentStep = undefined
        this.steps.length = 0
    }

    public start(): Subject<GuidedTourStep> {
        this.currentStep = this.currentIcon = undefined
        this.tourSubject = new Subject()
        if (this.steps.length == 0) throw new Error("Must add steps before starting the guided tour")
        this.currentStep = this.steps[0]
        //defer til after return, I wish this was GOLANG so this would just be built in
        setTimeout(() => {
            if (this.tourSubject && this.currentStep) this.callNext(this.currentStep)
        })
        return this.tourSubject
    }

    public end(): void {
        this.complete = true
        if (this.currentStep) {
            if (this.currentStep.details?.after) this.currentStep.details.after.call(this.currentStep.details.scope, this.currentStep)
            this.hideMarker(this.currentStep)
        }
        this.tourSubject?.complete()
    }

    public canGoNext(): boolean {
        if (this.currentStep) {
            const idx = this.steps.indexOf(this.currentStep)
            return idx != this.steps.length - 1
        }
        return false
    }

    public canGoPrevious(): boolean {
        if (this.currentStep) {
            const idx = this.steps.indexOf(this.currentStep)
            return idx != 0
        }
        return false
    }

    public next() {
        this.resetOffsets()
        this.performingAsync = false
        this.resetSubscriptions()
        if (!this.currentStep) throw new Error("Must start the guided tour before you can call next")
        const prev: GuidedTourStep = this.currentStep
        if (prev && prev.details && prev.details.after) {
            const afterRet: Observable<any> | void = prev.details.after.call(prev.details.scope, prev)
            if (afterRet instanceof Observable) {
                this.performingAsync = true
                this.subscriptions.push(afterRet.pipe(take(1)).subscribe(() => {
                    this.performingAsync = false
                    this.nextInternal(prev)
                }))
            } else {
                this.nextInternal(prev)
            }
        } else {
            this.nextInternal(prev)
        }
        //return this.nextInternal(prev)
    }

    private nextInternal(prev: GuidedTourStep): GuidedTourStep {
        let toReturn: GuidedTourStep
        let index = 0
        if (this.currentStep) index = this.steps.indexOf(this.currentStep)
        const nextIndex = index + 1
        if (!this.canGoNext()) {
            //toReturn = this.steps[0]
            return this.steps[this.steps.length - 1]
        } else {
            toReturn = this.currentStep = this.steps[nextIndex]
        }
        if(toReturn.details)toReturn.details.xOffset = toReturn.details.yOffset = 0
        if (toReturn && toReturn.details && toReturn.details.before) {
            const ret: Observable<any> | void = toReturn.details.before.call(toReturn.details.scope, toReturn)
            if (ret instanceof Observable) {
                this.performingAsync = true
                this.currentStep = toReturn
                this.hideMarker(prev)
                this.displayMarker(toReturn)
                this.callNext(toReturn)
                this.subscriptions.push(ret.pipe(take(1)).subscribe(() => {
                    //this.hideMarker(toReturn)
                    this.performingAsync = false
                    this.displayMarker(toReturn)
                    const idx: number = this.steps.indexOf(toReturn)
                    if (!this.canGoNext() && toReturn && toReturn.details && toReturn.details.after) {
                        const afterRet: Observable<any> | void = toReturn.details.after.call(toReturn.details.scope, toReturn)
                        if (afterRet instanceof Observable) {
                            this.performingAsync = true
                            this.subscriptions.push(afterRet.pipe(take(1)).subscribe(() => {
                                this.performingAsync = false
                                this.complete = true
                            }))
                        } else {
                            this.complete = true
                        }
                    }
                }))
            } else {
                //just call as normal
                this.currentStep = toReturn
                this.hideMarker(prev)
                this.displayMarker(toReturn)
                this.callNext(toReturn)
            }
        } else {
            this.currentStep = toReturn
            this.hideMarker(prev)
            this.displayMarker(toReturn)
            this.callNext(toReturn)
        }
        //this.hideMarker(prev)
        return toReturn
    }

    public previous() {
        this.resetOffsets()
        this.performingAsync = false
        this.resetSubscriptions()
        if (!this.currentStep) throw new Error("Must start the guided tour before you can call previous")
        const prev: GuidedTourStep = this.currentStep
        if (prev && prev.details && prev.details.after) {
            const afterRet: Observable<any> | void = prev.details.after.call(prev.details.scope, prev)
            if (afterRet instanceof Observable) {
                this.performingAsync = true
                this.subscriptions.push(afterRet.pipe(take(1)).subscribe(() => {
                    this.performingAsync = false
                    this.previousInternal(prev)
                }))
            } else {
                this.previousInternal(prev)
            }
        }else{
            this.previousInternal(prev)
        }

       // return this.previousInternal(prev)
    }

    private previousInternal(prev: GuidedTourStep): GuidedTourStep {
        let toReturn: GuidedTourStep
        let index = 0
        if (this.currentStep) index = this.steps.indexOf(this.currentStep)
        const prevIndex = index - 1
        if (prevIndex < 0) {
            toReturn = this.steps[this.steps.length - 1]
        } else {
            toReturn = this.currentStep = this.steps[prevIndex]
        }
        if (toReturn.details && toReturn.details.before) {
            const ret: Observable<any> | void = toReturn.details.before.call(toReturn.details.scope, toReturn)
            if (ret instanceof Observable) {
                this.performingAsync = true
                this.currentStep = toReturn
                this.hideMarker(prev)
                this.displayMarker(toReturn)
                this.callNext(toReturn)
                this.subscriptions.push(ret.pipe(take(1)).subscribe(() => {
                    this.displayMarker(toReturn)
                    this.performingAsync = false
                    if (!this.canGoPrevious() && toReturn && toReturn.details && toReturn.details.after) {
                        const afterRet: Observable<any> | void = toReturn.details.after.call(toReturn.details.scope, toReturn)
                        if (afterRet instanceof Observable) {
                            this.performingAsync = true
                            this.subscriptions.push(afterRet.pipe(take(1)).subscribe(() => {
                                this.performingAsync = false
                            }))
                        } else {

                        }
                    }
                }))
            } else {
                //just call as normal
                this.currentStep = toReturn
                this.hideMarker(prev)
                this.displayMarker(toReturn)
                this.callNext(toReturn)
            }
        } else {
            this.currentStep = toReturn
            this.hideMarker(prev)
            this.displayMarker(toReturn)
            this.callNext(toReturn)
        }
        //this.hideMarker(prev)  
        return toReturn
    }

    private displayMarker(step: GuidedTourStep) {
        this.hideMarker(step)
        // Check if step details and element are defined
        if (!step.details || !step.details.element) return;
        if (!this.renderer) throw new Error("You must instantiate the GuidedTour with a view container in order to display a marker");
    
        // Create mat-icon element
        this.currentIcon = this.renderer.createElement('mat-icon');
        this.renderer.appendChild(document.body, this.currentIcon);
    
        // Set common styles and classes
        this.renderer.setStyle(this.currentIcon, 'position', 'absolute');
        this.renderer.setStyle(this.currentIcon, 'z-index', '10000000000');
        this.renderer.addClass(this.currentIcon, 'mat-icon');
        this.renderer.addClass(this.currentIcon, 'material-icons');
        this.renderer.setStyle(this.currentIcon, 'color', 'yellow');
        this.renderer.setStyle(this.currentIcon, 'transform', 'scale(4)');
    
        const padding: number = 6;
        let top: number = 0, left: number = 0;
    
        const htmlElement: HTMLElement | undefined = this.currentStep?.details?.element?.nativeElement;
    
        if (!htmlElement) {
            throw new Error("Element is undefined");
        }
    
        // Extract the scale factor from the transform style
        const style = window.getComputedStyle(htmlElement);
        const transform = style.transform;
    
        let scaleFactor = 1; // Default scale factor
    
        if (transform && transform !== 'none') {
            const match = /matrix\((.+)\)/.exec(transform);
            if (match) {
                const values = match[1].split(', ');
                scaleFactor = parseFloat(values[0]); // Assuming uniform scaling
            }
        }
    
        const elementRect = htmlElement.getBoundingClientRect();
    
        if(this.currentIcon)switch (this.currentStep?.details?.showFrom) {
            case ShowFromDirection.TOP:
                this.renderer.appendChild(this.currentIcon, this.renderer.createText('arrow_drop_down'));
                top = elementRect.top - (this.currentIcon.offsetHeight * scaleFactor + padding) + (this.currentStep.details.yOffset || 0);
                left = elementRect.left + (elementRect.width / 2) - (this.currentIcon.offsetWidth * scaleFactor / 2) + (this.currentStep.details.xOffset || 0);
                this.animateVerticalBounce(this.currentIcon, 'top', top, padding);
                break;
            case ShowFromDirection.BOTTOM:
                this.renderer.appendChild(this.currentIcon, this.renderer.createText('arrow_drop_up'));
                top = elementRect.top + elementRect.height + padding + (this.currentStep.details.yOffset || 0);
                left = elementRect.left + (elementRect.width / 2) - (this.currentIcon.offsetWidth * scaleFactor / 2) + (this.currentStep.details.xOffset || 0);
                this.animateVerticalBounce(this.currentIcon, 'top', top, padding);
                break;
            case ShowFromDirection.LEFT:
                this.renderer.appendChild(this.currentIcon, this.renderer.createText('arrow_right'));
                top = elementRect.top + (elementRect.height / 2) - (this.currentIcon.offsetHeight * scaleFactor / 2) + (this.currentStep.details.yOffset || 0);
                left = elementRect.left - (this.currentIcon.offsetWidth * scaleFactor + padding) + (this.currentStep.details.xOffset || 0);
                this.animateHorizontalBounce(this.currentIcon, 'left', left, padding);
                break;
            case ShowFromDirection.RIGHT:
                this.renderer.appendChild(this.currentIcon, this.renderer.createText('arrow_left'));
                top = elementRect.top + (elementRect.height / 2) - (this.currentIcon.offsetHeight * scaleFactor / 2) + (this.currentStep.details.yOffset || 0);
                left = elementRect.left + elementRect.width + padding + (this.currentStep.details.xOffset || 0);
                this.animateHorizontalBounce(this.currentIcon, 'left', left, padding);
                break;
            default:
                // Handle default behavior or throw an error if necessary
                break;
        }
    
        // Apply calculated styles
        if (typeof top !== 'undefined' && typeof left !== 'undefined') {
            this.renderer.setStyle(this.currentIcon, 'top', `${top}px`);
            this.renderer.setStyle(this.currentIcon, 'left', `${left}px`);
        }
    }

    // Function to animate vertical bounce
    private animateVerticalBounce(element: HTMLElement, property: string, initialPos: number, padding: number) {
        padding += 10
        let direction = -1; // Start with moving up
        let bounceAmount = 10;
        let currentPos = initialPos + padding; // Initial position with padding

        let startTime: number;

        const animate = (timestamp: number) => {
            if (!startTime) startTime = timestamp;
            const progress = timestamp - startTime;

            currentPos = (initialPos + padding) + bounceAmount * direction * Math.sin(progress / 100); // Adjust bounce speed and amplitude as needed

            (element.style as any)[property] = `${currentPos}px`;

            if (progress < 2000) { // Adjust bounce duration as needed
                requestAnimationFrame(animate);
            }
        };

        requestAnimationFrame(animate);
    }

    private animateHorizontalBounce(element: HTMLElement, property: string, initialPos: number, padding: number) {
        let direction = -1; // Start with moving left
        let bounceAmount = 10;
        let currentPos = initialPos + padding; // Initial position with padding

        let startTime: number;

        const animate = (timestamp: number) => {
            if (!startTime) startTime = timestamp;
            const progress = timestamp - startTime;

            currentPos = (initialPos + padding) + bounceAmount * direction * Math.sin(progress / 100); // Adjust bounce speed and amplitude as needed

            (element.style as any)[property] = `${currentPos}px`;

            if (progress < 2000) { // Adjust bounce duration as needed
                requestAnimationFrame(animate);
            }
        };

        requestAnimationFrame(animate);
    }
    private hideMarker(step: GuidedTourStep) {
        if (!step || !step.details?.element) return
        if (!this.renderer) throw new Error("You must instantiate the GuidedTour with a view container in order to display a marker")
        if (this?.currentIcon) this?.renderer?.removeChild(document.body, this?.currentIcon)

        // Remove glow effect directly from the element
        //this.renderer.removeStyle(step.element, 'box-shadow');
    }

    public updateAsyncStep(step: GuidedTourStep, details?: GuidedTourStepDetails) {
        if (this.isComplete()) return
        step.details = details
        if(this.currentStep != step)return
        this.hideMarker(step)
        this.displayMarker(step)
    }

    public cleanup(){
        this.performingAsync = false
        this.resetSubscriptions()
        this.steps.forEach((step : GuidedTourStep) => {
            this.hideMarker(step)
        })
    }

    private resetSubscriptions() {
        this.subscriptions.forEach((subscription: Subscription) => {
            subscription.unsubscribe()
        })
        this.subscriptions.length = 0
    }
    private callNext(step : GuidedTourStep){
        setTimeout(() => {
            this.tourSubject?.next(step)
        });
    }

    private resetOffsets(){
        this.steps.forEach((step : GuidedTourStep) => {
            if(step.details)step.details.xOffset = step.details.yOffset = 0
        })
    }
}