import { EventEmitter, inject } from "@angular/core"
import { ScalingFactors } from "../component/dashboard/dashboard.component"
import { take, timeout } from "rxjs"
import { HistoryService } from "../service/history.service";
import { EditMode, ToolbarService } from "../service/toolbar.service";
import { Font, FontService } from "../service/font.service";
import { Sticker } from "../service/sticker.service";
import { ScrubberService } from "../service/scrubber.service";
import { SafeHtml } from "@angular/platform-browser";

interface TransformProperties {
    translateX: number;
    translateY: number;
    rotate ?: number;
    scale ?: number;
}

interface IGridSection{
    id : string

    icon ?: SafeHtml

    forehead : number

    name ?: string

    mode : Mode

    isRootGrid : boolean

    orientation ?: Orientation

    children : Array<GridSection>

    addChild(child : GridSection):GridSection

    removeChild(child : GridSection):void

    cascadeValue (key : string, value : any):void

}

export interface Downloadable{
    downloadURL ?: string
    determinate ?: boolean
    completion ?: number
    hasMedia() : boolean
}

export interface Loadable{
    toLoad : number
    loaded : number
}


export enum Mode {
    DEVELOPMENT = 1,
    VIEWING = 2
}

export interface Sizeable{
    width : number
    height : number
    widthInPX ?: number
    heightInPX ?: number
    rootGridSectionWidth : number
    rootGridSectionHeight : number
    viewPortWidth ?: number
    viewPortHeight ?: number
    horizontalAspectRatio ?: number
    verticalAspectRatio ?: number
}

export interface Styleable{
    borderWidth : number
    borderColor : string
    backgroundColor : string
    borderStyle : string
}

export interface SupportsMedia{
    media ?: Media
    collectMedia(arr ?: Array<Media>) : Array<Media>
    distributeMedia(media : Array<Media>):void
}

export interface Media extends MediaStyles{
    mediaType : MediaType
    loaded : boolean
    originalLocation : string
    mediaLocation : string
    name ?: string
    sticker ?: Sticker
}

export interface MediaStyles{
    sepia : number
    grey : number
    hue : number
    saturation : number
    brightness : number
    blur : number
}

export enum MediaType{
    LOCAL_IMAGE = 1,
    VIDEO = 2,
    LOCAL_ANIMATED_GIF = 3
}

export enum Orientation{
    VERTICAL = 0,
    HORIZONTAL = 1
}

export interface Assetable{
    activeAsset ?: Asset
    addAsset(assetType : AssetType, toolbarService ?: ToolbarService):Asset
    cloneAsset(asset : Asset, toolbarService : ToolbarService): Asset
    removeAsset(history : HistoryService, asset ?: Asset):void
    assets : Asset[]

    prepareSerialize() : void
    reapplyCyclicals() : void
    adjustAssets(sf : ScalingFactors) : void
}

export interface Asset{
    type : AssetType
    data : GenericAssetData,
    section ?: GridSection
    convertToMedia ?: Media
    loaded ?: EventEmitter<boolean>
    shownFrom ?: number
    shownUntil ?: number
    editMode ?: EditMode
}

export interface GenericAssetData{
    //////////GENERAL
    x : number
    y : number
    visible : boolean

    stackingOrder ?: number


    ////////TEXT
    text ?: string
    color ?: string
    fontFamily ?: string
    editing : boolean
    size ?: number
    fontWeight ?: number
    backgroundColor ?: string
    borderColor ?: string
    borderWidth ?: number
    padding ?: number
    lineHeight ?: number
    letterSpacing ?: number
    wordSpacing ?: number
    textDecorationStyle ?: string
    textDecorationLine ?: string
    textStrokeWidth ?: number
    textStrokeColor ?: string
    blendMode ?: string
    filter ?: string
    centerVertically ?: boolean
    centerHorizontally ?: boolean
    applyTextShadow ?: boolean

    horizontalAlign ?: string
    verticalAlign ?: string


    ////////IMAGE
    location ?: string
    opacity ?: number

    //text bubble
    tailY ?: number
    tailX ?: number
    bubbleStyle ?: 'speech' | 'shout' | 'caption' | 'pointedArcs' | 'ellipse'


    ///////SHARED
    transform ?: string
    width ?: number
    height ?:number
    display ?: string
}

export enum AssetType{
    TEXT = 1,
    STICKER = 2,
    MEME = 3,
    EMOJI = 4,
    TEXT_BUBBLE = 5,
    UPLOAD_IMAGE = 6,
    SHAPE = 7,
    GIF = 8,
}

export class GridSection implements IGridSection, Sizeable, Styleable, SupportsMedia, Assetable, Downloadable, Loadable{

    //public icon ?: SafeHtml

    private makeRandom(lengthOfCode: number) {
        const possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890,./;'[]\=-)(*&^%$#@!~`"
        let text = "";
        for (let i = 0; i < lengthOfCode; i++) {
          text += possible.charAt(Math.floor(Math.random() * possible.length));
        }
          return text;
    }

    forehead : number = 0

    id: string = this.makeRandom(35)

    name?: string | undefined

    public isRootGrid: boolean = true

    public widthInPX?: number | undefined;
    public heightInPX?: number | undefined;

    public toLoad: number = 0
    private _loaded: number = 0;
    public set loaded(l : number){
        this._loaded = l
        if(l != 0 && l == this.toLoad){
            window.dispatchEvent(new Event('alldataloaded'))
        }
    }
    public get  loaded() : number{
        return this._loaded
    }

    public downloadURL?: string | undefined
    public determinate?: boolean | undefined = false
    public completion?: number | undefined;

    private _rootGridSectionHeight : number = 0
    get rootGridSectionHeight() : number {
        return this._rootGridSectionHeight
    }
    set rootGridSectionHeight(h : number){
        this._rootGridSectionHeight = h
        this.cascadeValue('rootGridSectionHeight', h)
    }
    private _rootGridSectionWidth : number = 0
    get rootGridSectionWidth() : number {
        return this._rootGridSectionWidth
    }
    set rootGridSectionWidth(w : number){
        this._rootGridSectionWidth = w
        this.cascadeValue('rootGridSectionWidth', w)
    }

    public viewPortHeight?: number | undefined
    public viewPortWidth?: number | undefined
    public horizontalAspectRatio?: number | undefined
    public verticalAspectRatio?: number | undefined

    private _mode: Mode = Mode.DEVELOPMENT
    set mode(m : Mode){
        this._mode = m
        this.cascadeValue('mode', m)
    }
    get mode():Mode{
        return this._mode
    }

    public hasMedia(): boolean {
        return this.collectMedia().length > 0
    }

    public media ?: Media
    collectMedia(m ?: Array<Media>): Media[] {
        const arr : Array<Media> = m ? m : []
        if(this.media)arr.push(this.media)
        this._children.forEach((child : any) => {
            if(child.media)arr.push(child.media)
            child.collectMedia(arr)
        })
        return arr
    }

    distributeMedia(media: Media[]): void {
        this.cascadeValue('media', undefined);
        this.media = undefined
        if(!this.children || this.children && this.children.length == 0)this.media = media.splice(0, 1)[0]
        this._children.forEach((child : any) => {
            if(media.length > 0 && child.children.length == 0){
                child.media = media.splice(0, 1)[0]
            }
            if(media && media.length > 0)child.distributeMedia(media)
        })
    }

    public orientation ?: Orientation;

    private _children : Array<GridSection> = []

    constructor(orientation ?: Orientation){
        if(orientation != undefined)this.orientation = orientation
    }

    public get children() : Array<GridSection>{
        return this._children
    }

    addChild(child: GridSection) : GridSection {
        child.isRootGrid = false
        if(this.orientation == undefined)throw new Error("Must set orientation in constructor when adding children")
        this._children.push(child)
        //were setting explicit sizes here so they dont default to 100% and images make them grow
        if(this.children && this.orientation != undefined){
            if(this.orientation == Orientation.HORIZONTAL){
                this.children.forEach((child : Sizeable) => {
                    child.height = 100
                    child.width = 100 / this.children.length
                })
            }else if(this.orientation == Orientation.VERTICAL){
                this.children.forEach((child : Sizeable) => {
                    child.width = 100
                    child.height = 100 / this.children.length
                })
            }
            console.log()
        }
        return child
    }

    removeChild(child: GridSection) {
        const index : number = this._children.indexOf(child)
        if(index != undefined)this._children.splice(index, 1)
    }

    public cascadeValue(key: string, value: any) {
        this._children.forEach((child : any) => {
            child[key] = value;
            child.cascadeValue(key, value)
        })
    }

    public width: number = 100
    public height: number = 100

    private _borderColor: string = "#000"
    set borderColor(bc : string){
        this._borderColor = bc
        this.cascadeValue('borderColor', bc)
    }
    get borderColor():string{
        return this._borderColor
    }

    private _backgroundColor: string = "white"
    set backgroundColor(bc : string){
        this._backgroundColor = bc
    this.cascadeValue('backgroundColor', bc)
    }
    get backgroundColor():string{
        return this._backgroundColor
    }


    private _borderWidth: number = 9
    set borderWidth(bw : number){
        this._borderWidth = bw
        this.cascadeValue('borderWidth', bw)
    }
    get borderWidth():number{
        return this._borderWidth
    }
    private _borderStyle: string = 'solid'
    public get borderStyle() : string {
        return this._borderStyle
    }
    public set borderStyle(bs : string) {
        this._borderStyle = bs
        this.cascadeValue('borderStyle', bs)
    }

    private _activeAsset ?: Asset
    public set activeAsset(asset : Asset | undefined){
        //const scrubberService: ScrubberService = inject(ScrubberService)
        //scrubberService.setupScrubbers.next(asset)
        this._activeAsset = asset
    }
    public get activeAsset() : Asset | undefined {
        return this._activeAsset
    }

    cloneAsset(asset : Asset, toolbarService : ToolbarService): Asset {
        const cloned : Asset = this.addAsset(asset.type, toolbarService)
        for(let key in asset.data){
            if(key != 'x' && key != 'y' && key != 'transform')(cloned.data as any)[key] = (asset.data as any)[key]
        }
        const x = (this.rootGridSectionWidth / 2) - ((cloned.data.width || 0) / 2)
            const y = (this.rootGridSectionHeight / 2) - ((cloned.data.height || 0) / 2)

        //cloned.data.x = x
        //cloned.data.y = y
        cloned.data.transform = `translate(${x}px, ${y}px)`
        return cloned
    }
    //this needs to be public for serialization
    addAsset<T>(t : AssetType, toolbarService ?: ToolbarService, makeActive : boolean = true): Asset {
            const smaller : number = Math.min(this.rootGridSectionWidth, this.rootGridSectionHeight)
            const toSetSize : number = smaller * .1 < 75 ? 75 : smaller * .1
            let editMode !: EditMode
            let toAdd : Asset = {type : t || AssetType.STICKER, data : {x: 0, y : 0, visible : true, editing : false}, section : this};
            //toAdd.section = this
            //if you DONT want these presets update here, as these are sane and useful
            toAdd.data.width = toSetSize
            toAdd.data.height = toSetSize
            toAdd.data.opacity = 100;
            //toAdd.data.transform = "translateX(10px) translateY(10px)"
            //toAdd.data.x = toAdd.data.y = 0
            if(t == AssetType.TEXT){
                editMode = EditMode.TEXT
                //SANE DEFAULTS
                toAdd.data.width = toSetSize * 2.5
                toAdd.data.height = toSetSize
                toAdd.data.text = "EDIT ME"
                toAdd.data.fontFamily = 'Anton'
                toAdd.data.centerVertically = true
                toAdd.data.centerHorizontally = true
                toAdd.data.color = "white"
                toAdd.data.size = toSetSize * .5
                toAdd.data.padding = 5
                toAdd.data.borderWidth = 0
                toAdd.data.fontWeight = 500
                toAdd.data.borderColor = 'black'
                toAdd.data.backgroundColor = 'transparent'
                toAdd.data.lineHeight = 1
                toAdd.data.letterSpacing = 0
                toAdd.data.wordSpacing = 5
                toAdd.data.textDecorationLine = ''
                toAdd.data.textDecorationStyle = 'solid'
                toAdd.data.textStrokeWidth = toAdd.data.size * .05
                toAdd.data.textStrokeColor = 'black'
                toAdd.data.blendMode = 'normal'
                toAdd.data.horizontalAlign = 'center'
                toAdd.data.verticalAlign = 'center'
            // toAdd.data.editing = false
            }else if(t == AssetType.STICKER){
                editMode = EditMode.STICKER
                toAdd.loaded = new EventEmitter()
                this.toLoad ++
            }else if(t == AssetType.MEME){
                editMode = EditMode.STICKER
                toAdd.loaded = new EventEmitter()
                this.toLoad ++
            }else if(t == AssetType.EMOJI){
                editMode = EditMode.STICKER
                toAdd.loaded = new EventEmitter()
                this.toLoad ++
            }else if(t == AssetType.TEXT_BUBBLE){
                editMode = EditMode.TEXT_BUBBLE
                toAdd.data.width = toAdd.data.height = 100
                toAdd.data.tailY = 10
                toAdd.data.tailX = 10
                toAdd.data.bubbleStyle = 'speech'
                toAdd.data.borderWidth = 2.5
               // toAdd.data.opacity = 1
            }else if(t == AssetType.SHAPE){
                editMode = EditMode.STICKER
                toAdd.loaded = new EventEmitter()
                this.toLoad ++
                //toAdd.data.transform = 'invert(0%) sepia(0%) saturate(0%) hue-rotate(324deg) brightness(96%) contrast(104%)'
            }
            if(this.assets.length > 0)toAdd.data.blendMode = this.assets[0].data.blendMode
            const x = (this.rootGridSectionWidth / 2) - ((toAdd.data.width || 0) / 2)
            const y = (this.rootGridSectionHeight / 2) - ((toAdd.data.height || 0) / 2)

            toAdd.editMode = editMode

            //toAdd.data.x = x
            //toAdd.data.y = y
            toAdd.data.transform = `translate(${x}px, ${y}px)`

            //we've only added a loaded event emitter on the items that need it i.e. Stickers, memes, images etc
            if(toAdd.loaded){
                toAdd?.loaded
                ?.pipe(take(1))
                ?.pipe(timeout(2000))
                ?.subscribe({
                    next: () => {
                        this.loaded ++
                        delete toAdd['loaded']
                    },
                    error : () =>{
                        //this means the load has timed out, for now, what are we gonna do but continue?
                        this.loaded ++
                        delete toAdd['loaded']
                    }
                });
            }

            this.assets.push(toAdd)
            if(makeActive)this.activeAsset = toAdd
            if(toolbarService && makeActive)toolbarService.editModeSubject.next({ mode : editMode, asset : toAdd})
            toAdd.data.stackingOrder = this.assets.length + 1
            return toAdd
    }
    removeAsset<T>(history : HistoryService, asset ?: Asset): void {
        history.reverse()
        if(asset == undefined)return
        if(asset == this.activeAsset)this.activeAsset = undefined
        if(asset.data.fontFamily){
            const font : Font = {name : asset.data.fontFamily}
            //const fontService: FontService = inject(FontService)
            //fontService.removeUsedFont(font)
        }
        const idx = this.assets.indexOf(asset)
        if(idx != undefined)this.assets.splice(idx, 1)
    }
    
    public assets: Asset[] = []


    extractTransformProps(transformString: string): TransformProperties {
        // Regular expression to extract translateX and translateY
        const regex = /translate\(\s*(-?\d*\.?\d+)px,\s*(-?\d*\.?\d+)px\s*\)/;
        
        // Match the translate function in the transform string
        const match = transformString.match(regex);
        
        if (match) {
            // Extract and parse translateX and translateY values
            const translateX = parseInt(match[1], 10);
            const translateY = parseInt(match[2], 10);
            return { translateX, translateY };
        } else {
            // Return empty object if translate function is not found
            return {translateX : 0, translateY : 0};
        }
    }
      
      // Function to update translateX and translateY in a transform string
    updateTransformString(transformString: string, newProperties: TransformProperties): string {
        // Regular expression to find and replace translate function
        const regex = /translate\(\s*(-?\d*\.?\d+)px,\s*(-?\d*\.?\d+)px\s*\)/;
      
        // Extract current translate function from the transform string
        const match = transformString.match(regex);
        let currentTranslate = match ? match[0] : 'translate(0px, 0px)'; // Default if not found
      
        // Update translateX and translateY if new values are provided
        if (newProperties.translateX !== undefined && newProperties.translateY !== undefined) {
          currentTranslate = `translate(${newProperties.translateX}px, ${newProperties.translateY}px)`;
        }
      
        // Replace old translate function with updated one
        const finalTransformString = transformString.replace(regex, currentTranslate);
      
        return finalTransformString;
      }
      

      
      adjustAssets(sf: ScalingFactors): void {
        if (!sf || typeof sf.overall !== 'number' || typeof sf.positionX !== 'number' || typeof sf.positionY !== 'number') {
            throw new Error('Invalid scaling factors');
        }
    
        if (sf.overall === 1 && sf.positionX === 1 && sf.positionY === 1) return;
    
        this.assets.forEach((asset: Asset) => {
            if (!asset.data) return;
    
            const beforeH = asset.data.height ?? 1;
    
            asset.data.width = this.scaleValue(asset.data.width, sf.positionX);
            asset.data.height = this.scaleValue(asset.data.height, sf.positionY);
    
            const diffH = asset.data.height - beforeH;

            const xAndY : {x: number, y : number} = this.getMatrixForAsset(asset)
            let x = xAndY.x
            let y = xAndY.y
    
            //asset.data.x *= sf.positionX;
            x *= sf.positionX;
            //asset.data.y = (asset.data.y * sf.positionY) + (diffH / 2);
            y =  (y * sf.positionY) + (diffH / 2);
            this.updateMatrixOnAsset(asset, x, y)
            asset.data.size = this.scaleValue(asset.data.size, sf.overall);
            if(asset.type == AssetType.TEXT)asset.data.height = this.scaleValue(asset.data.height, sf.overall)
            asset.data.textStrokeWidth = this.scaleValue(asset.data.textStrokeWidth, sf.overall);
        });
    }

    public getMatrixForAsset(asset : Asset): {x : number, y :number, angle : number}{
        // Get the current transform string
        const transform = asset.data.transform || '';
    
        // Parse the transform matrix
        const matrix = new DOMMatrix(transform);

        const angleInRadians = Math.atan2(matrix.b, matrix.a);
        const angleInDegrees = angleInRadians * (180 / Math.PI);
    
        // Extract current translate values
        let translateX = matrix.m41;
        let translateY = matrix.m42;
        return {x : translateX, y : translateY, angle : angleInDegrees}
    }

    public updateMatrixOnAsset(asset : Asset, tx ?: number, ty ?: number){
        const matrix = this.getMatrixForAsset(asset)
        
        // Create a new matrix with updated translation values
        const newMatrix = new DOMMatrix()
        .translateSelf(tx || matrix.x, ty || matrix.y);
        newMatrix.rotateSelf(matrix.angle)

        // Convert matrix to transform string
        const newTransform = newMatrix.toString();

        // Update the transform property
       asset.data.transform = newTransform;
    }
    
    private scaleValue(value: number | undefined, factor: number, defaultValue: number = 1): number {
        return (value ?? defaultValue) * factor;
    }

    prepareSerialize(): void {
        if(this.activeAsset)delete this.activeAsset.section
        this.assets.forEach((asset : Asset) => {
            delete asset.section
            delete asset.loaded
        })
    }

    reapplyCyclicals(): void {
        if(this.activeAsset)this.activeAsset.section = this
        this.assets.forEach((asset : Asset) => {
            asset.section = this
            asset.loaded = new EventEmitter()
        })
    }
}