import { Injectable } from '@angular/core';
import { ServiceResult, transformError } from './result';
import { catchError, map, Observable, of, take } from 'rxjs';
import { APIName, HTTPMethods, ServiceLookupService } from './service-lookup.service';
import { HttpClient } from '@angular/common/http';

export interface HowTo {
  name:        string;
  description: string;
  thumb:       string;
  video:       string;
  tags:        string;
}

@Injectable({
  providedIn: 'root'
})
export class GeneralJsonLoaderService {

  constructor(private serviceLookup : ServiceLookupService, private http : HttpClient) { }

  public loadHowTos() : Observable<ServiceResult<HowTo[]>> | undefined {
    const uri = this.serviceLookup.lookup(APIName.HOW_TOS, HTTPMethods.GET)
    return this.http.get<ServiceResult<HowTo[]>>(uri)
    .pipe(
      take(1),
      map((response : any) => ({data : response.howtos})),
      catchError(error => of(transformError<HowTo[]>(error)))
    );

  }
}
