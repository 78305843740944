import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'safeStyle',
  standalone: true
})
export class SafeStylePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}
  transform(value: string, ...args: unknown[]): unknown {
    return this.sanitizer.bypassSecurityTrustStyle(value);
  }

}

@Pipe({
  name: 'fontFilter',
  standalone: true
})
export class FontFilterPipe implements PipeTransform {
  transform(fonts: any[], searchTerm: string): any[] {
    if (!fonts || !searchTerm) {
      return fonts;
    }
    return fonts.filter(font =>
      font.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }
}


@Pipe({
  name: 'round',
  standalone: true
})
export class RoundPipe implements PipeTransform {
  transform(value: number): number {
    return Math.round(value)
  }
}
