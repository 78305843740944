export const resolveFetch = customFetch => {
  let _fetch;
  if (customFetch) {
    _fetch = customFetch;
  } else if (typeof fetch === 'undefined') {
    _fetch = (...args) => import('@supabase/node-fetch').then(({
      default: fetch
    }) => fetch(...args));
  } else {
    _fetch = fetch;
  }
  return (...args) => _fetch(...args);
};
