import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { DankTankAuthService } from '../../service/dank-tank-auth.service';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [MatDialogModule, MatButtonModule, MatIconModule],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss'
})
export class LoginComponent {

  constructor (private ref : MatDialogRef<LoginComponent>, private authService : DankTankAuthService){

  }

  public signInWithGoogle(){
    this.authService.loginWithProvider('google')
    this.ref.close('closed_by_code')
  }

  public signInWithFacebook(){
    this.authService.loginWithProvider('facebook')
    this.ref.close('closed_by_code')
  }
  
  public signInWithApple(){
    
  }

}
