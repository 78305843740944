<div  #bubbleAsset
    class="add-pointer-events bubble-holder"
    [style.transform]="data?.data?.transform"
    [style.width.px]="data?.data?.width"
    [style.height.px]="data?.data?.height"
    [style.opacity]="(data?.data?.opacity || 0) * .01"
>
    <div #bubbleInner 
    [style.width.px]="(data?.data?.width || 0) / 2"
    [style.height.px]="(data?.data?.height || 0) / 2"
    style="text-align: center;"
    [innerText]="''">
    </div>

</div>