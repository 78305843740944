import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, catchError, map, of, take, tap, throwError } from 'rxjs';
import { ServiceLookupService } from './service-lookup.service';
import { GridSection } from '../vo/GridSection';
import { parseGridSection } from '../util/ParseUtil';
import { Sticker } from './sticker.service';
import { initializeAppCheck, getToken, AppCheck, ReCaptchaV3Provider, AppCheckTokenResult } from 'firebase/app-check';
import { FirebaseApp } from '@angular/fire/app';


export interface ImageResponse {
  data?: string | null;
  error?: any; 
}

 interface ImagePayload {
  data : any
  status : string
}


@Injectable({
  providedIn: 'root'
})
export class SocialService {
  public imageSubject = new BehaviorSubject<ImageResponse>({ data: null, error: null });
  public viewsSubject = new Subject<ImageResponse>();

  private appCheck : AppCheck



  constructor(private app : FirebaseApp, private http: HttpClient, private serviceLookup : ServiceLookupService) { 
    this.appCheck = initializeAppCheck(
      app, 
      {
        provider: new ReCaptchaV3Provider('6LeFYwsqAAAAAG3oAiyefKeTS86OJG_fvlcq16mF'),
        isTokenAutoRefreshEnabled: true 
      }
    )
    console.log()
  }

  async imageViewed(sticker : Sticker): Promise<Observable<ImageResponse>> {
    const uri = this.serviceLookup.lookup(this.serviceLookup.APIName.IMAGE_VIEWED, this.serviceLookup.HTTPMethods.GET, sticker.storageReference);

    const token = await this.getToken()

    const config = {                                                                                                                                                                                 
      headers: new HttpHeaders({'X-Firebase-AppCheck': token}), 
    }

    this.http.get<ImagePayload>(uri, config)
      .pipe(take(1))
      .pipe(
        map(data => ({ data : data.data })), 
        catchError(error => of(this.transformError(error))) 
      )
      .subscribe(response => {
        this.imageSubject.next(response)
      })

      return this.imageSubject.asObservable();
  }

  async imageViews(): Promise<Observable<ImageResponse>> {
    const uri = this.serviceLookup.lookup(this.serviceLookup.APIName.GET_IMAGE_VIEWS, this.serviceLookup.HTTPMethods.GET)

    const token = await this.getToken()

    const config = {                                                                                                                                                                                 
      headers: new HttpHeaders({'X-Firebase-AppCheck': token}), 
    }

    this.http.get<ImagePayload>(uri, config)
      .pipe(take(1))
      .pipe(
        map(data => ({ data : data.data })), 
        catchError(error => of(this.transformError(error))) 
      )
      .subscribe(response => {
        this.viewsSubject.next(response)
      })

      return this.viewsSubject.asObservable();
  }

  private async getToken():Promise<string>{
    let appCheckTokenResponse;
    try {
        return appCheckTokenResponse = (await getToken(this.appCheck, false)).token
    } catch (err) {
        return '';
    }
  }

  private transformError(error: any): any {
    return { error };
  }}
