import { isPlatformBrowser } from '@angular/common';
import { ApplicationRef, Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { first, take } from 'rxjs';

export interface Toast {
  message: string;
  action: string;
  duration?: number;
}

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  private displayedMessages: Set<string> = new Set<string>();
  private snackBarRef: MatSnackBarRef<any> | null = null;
  private queuedToasts: Toast[][] = []
  private toasts: Toast[] = []
  private stable : boolean = false
  constructor(private snackBar: MatSnackBar, @Inject(PLATFORM_ID) private platformId: Object, private appRef: ApplicationRef) { 
    this.appRef
    .isStable.pipe(first((isStable: boolean) => isStable))
    .subscribe(() => {
      this.stable = true
      this.show([])
    })
  }

  show(toasts: Toast[]) {
        this.toasts = toasts
        if (!isPlatformBrowser(this.platformId)) return
        if (this.isSnackbarOpen()) {
          this.queuedToasts.push(this.toasts)
          return;
        }
        if(!this.stable)return;
        const toShow: Toast | undefined = this.toasts.shift();
        if (toShow) {
          if (!this.displayedMessages.has(toShow.message)) {
            this.snackBarRef = this.snackBar.open(toShow.message, toShow.action, { duration: toShow.duration || 5000, panelClass: 'custom-snackbar', verticalPosition: 'top', horizontalPosition: 'end' });
            this.snackBarRef.afterDismissed().pipe(take(1)).subscribe(() => {
              this.displayedMessages.add(toShow.message);
              this.snackBarRef = null; // Clear the reference when the snackbar is closed
              if (this.toasts.length > 0) {
                this.show(this.toasts);
              } else if (this.toasts.length == 0) {
                if (this.queuedToasts.length > 0) {
                  const queued: Toast[] | undefined = this.queuedToasts.shift()
                  if (queued) this.show(queued)
                }
              }
            });
          } else if (this.toasts.length > 0) {
            this.show(this.toasts);
          }
        }
  }

  private isSnackbarOpen(): boolean {
    return this.snackBarRef !== null;
  }

  hideAll() {
    this.queuedToasts = []
    this.toasts = []
    setTimeout(() => {
      this.snackBar.dismiss()
    }, 0);
  }
}