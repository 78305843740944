export const environment = {
    production: true,
    supabaseUrl: 'https://dinbxwvmllmigojxxcce.supabase.co',
    supabaseKey: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImRpbmJ4d3ZtbGxtaWdvanh4Y2NlIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MjE0OTc3MzQsImV4cCI6MjAzNzA3MzczNH0.PFv1CGC_5F4kX_Kxc3R87ozAFijAWbNwYo4Uj6ebHUk',
    stripe_publishable_key : 'pk_test_51Pf6rvJvH9T3hOu4v0fwQUuC2YTGLWZ7DEIxnzw4xGdyeaq1RGmvK5nFQDoG8NFB7Hfw1D1vzLUVLo6XtIV4baWs00RGsl2Cfx',
    firebase:  {
        apiKey: "AIzaSyAV-kkKjzSQASjjr0ZzFdhY_S08yhyjCc0",

        authDomain: "meme-creator-app.firebaseapp.com",

        databaseURL: "https://meme-creator-app-default-rtdb.firebaseio.com",

        projectId: "meme-creator-app",

        storageBucket: "meme-creator-app.appspot.com",

        messagingSenderId: "377988640277",

        appId: "1:377988640277:web:94d36a8a9d786837b8281c",

        measurementId: "G-B51MJ7HQ4T"

      },
      endpoint: "https://us-central1-meme-creator-app.cloudfunctions.net/",
      storage: "https://hhopznfvcfwimeaxsktv.supabase.co/storage/v1/object/public/",
      smallAdKey : "12eeaed671979bacb70f237ef0b7c7ca",
      mediumAdKey : "470d5cc49b940b384d32d3c736f7dd21",
      largeAdKey : "a8d880603373d164b410908b3f6ca5fd",
      adURI : "//www.topcreativeformat.com/470d5cc49b940b384d32d3c736f7dd21/invoke.js",
      analyticsId : "G-B51MJ7HQ4T"
  };
  