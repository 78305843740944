import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { isPlatformBrowser, Location } from '@angular/common';
import { from, fromEvent, Observable, Subject, SubscriptionLike } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { Alert, AlertComponent } from '../component/alert/alert.component';
import { take } from 'rxjs/operators';
import { AppService } from './app.service';

@Injectable({
  providedIn: 'root'
})
export class NavigatorService {


  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private router: Router,
    private location: Location,
    private dialog: MatDialog,
    private appService : AppService
  ) {}

  public shouldPreventNavigation(): Observable<boolean> {
    const subject = new Subject<boolean>();
    if(((this.appService.gridSection?.collectMedia().length || 0) > 0 || (this.appService.gridSection?.assets.length || 0) > 0)){
      const data: Alert = { 
        title: 'Attention', 
        body: 'If you navigate away you will lose progress', 
        buttons: [
          { name: 'Cancel', closeValue: 'cancel' }, 
          { name: 'Ok', closeValue: 'ok' }
        ] 
      };
      
      this.dialog.open(AlertComponent, { data: data }).afterClosed().pipe(take(1)).subscribe((closeValue: string) => {
        switch (closeValue) {
          case 'ok':
            subject.next(false);
            subject.complete()
            break;
          case 'cancel':
            subject.next(true);
            subject.complete()
            break;
        }
      });
    }else{
      setTimeout(() => {
        subject.next(false)
        subject.complete()
      });
    }
    
    return subject.asObservable();
  }

  private handleBackOrForward(url: string) {
    console.log('Navigation detected to URL:', url);
  }
}