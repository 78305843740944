import { Component, ViewChild, OnInit, signal, Inject } from '@angular/core';
import { ReactiveFormsModule, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { injectStripe, StripeElementsDirective, StripePaymentElementComponent, StripeService } from 'ngx-stripe';
import { StripeElementsOptions, StripePaymentElementOptions, StripeElementsOptionsMode } from '@stripe/stripe-js';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { IntentResponse, PaymentService, Subscription, SubscriptionStatus, SubscriptionType } from '../../service/payment.service';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { DialogRef } from '@angular/cdk/dialog';
import { DankTankAuthService } from '../../service/dank-tank-auth.service';
import { CurrencyPipe } from '@angular/common';
import { ServiceResult } from '../../service/result';
import { take } from 'rxjs';
import { MatIconModule } from '@angular/material/icon';
import { ProfileButtonComponent } from '../profile-button/profile-button.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
  selector: 'app-payment',
  standalone: true,
  imports: [
    CurrencyPipe,
    ReactiveFormsModule,
    MatInputModule,
    StripeElementsDirective,
    StripePaymentElementComponent,
    MatDialogModule,
    MatButtonModule,
    MatProgressBarModule,
    MatIconModule,
    ProfileButtonComponent,
    MatProgressSpinnerModule
  ],
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit {
  @ViewChild(StripePaymentElementComponent) paymentElement!: StripePaymentElementComponent;

  public readonly SubscriptionStatus = SubscriptionStatus
  public readonly SubscriptionType = SubscriptionType


  public title: string = ''

  constructor(public authService: DankTankAuthService, private dialogRef: DialogRef, private fb: UntypedFormBuilder, public stripe: StripeService, private paymentService: PaymentService, @Inject(MAT_DIALOG_DATA) public data: Subscription) {
    const subscribeOrUpgrade : string = this.needsToUpgrade() ? 'Upgrade' : 'Subscribe'
    this.title = `${subscribeOrUpgrade} - ${data.type.toString()} $${data.monthlyPrice}/mo`
  }

  public needsToUpgrade(): boolean {
    return (
    (this.authService.subscriptionUserSubject.value?.data?.subscription?.status === SubscriptionStatus.ACTIVE || this.authService.subscriptionUserSubject?.value?.data?.subscription?.status === SubscriptionStatus.FREE) &&
      (this.authService.subscriptionUserSubject.value.data.subscription.type === SubscriptionType.BASIC_MONTHLY ||
        this.authService.subscriptionUserSubject.value.data.subscription.type === SubscriptionType.BASIC_YEARLY)
    )
  }

  paymentElementForm = this.fb.group({
    name: ['', [Validators.required]],
    email: ['', [Validators.required, Validators.email]],
    address: ['', [Validators.required]],
    zipcode: ['', [Validators.required]],
    city: ['', [Validators.required]],
    amount: [2500, [Validators.required, Validators.pattern(/\d+/)]]
  });

  elementsOptions: StripeElementsOptions = {
    locale: 'en',
    appearance: {
      theme: 'stripe',
      labels: 'floating',
      variables: {
        colorPrimary: '#673ab7',
        colorBackground: '#1D1A1D',
        colorText: 'white',
        colorTextPlaceholder: 'white'
      },
    },
  };

  paymentElementOptions: StripePaymentElementOptions = {
    layout: {
      type: 'tabs',
      defaultCollapsed: false,
      radios: false,
      spacedAccordionItems: false
    }
  };


  public paying = false
  public paidSuccessfully = false
  public paymentFailed = false
  public paymentFailedMessage?: string

  private hasRetried: boolean = false
  ngOnInit() {
    if (this.hasRetried) return
    if(!this.needsToUpgrade()){
      if (this.paidSuccessfully) return
      this.paymentService.initiate(this.data)?.pipe(take(1)).subscribe((value: ServiceResult<IntentResponse>) => {
        if(value.data){
          if (value.data.clientSecret) {
            this.elementsOptions.clientSecret = value.data.clientSecret
          }
        }else{
          this.ngOnInit()
            this.hasRetried = true
        }
      })
    }
  }

  public upgrading : boolean = false
  public upgradeFailed : boolean = false
  public portalUrl ?: string

  public upgrade(){
    this.upgrading = true
    this.paymentService.upgrade(this.data)?.pipe(take(1)).subscribe((result : ServiceResult<boolean>) => {
      if(result.data){
        this.upgrading = false
        this.paidSuccessfully = true
      }else if(result.error){
        this.paymentService.getPortalURL()?.pipe(take(1)).subscribe((value : ServiceResult<string>) =>{
          if(value.data){
            this.upgradeFailed = true
            this.portalUrl = value.data
          }else if(value.error){

          }
        })
        this.upgradeFailed = true
      }
    })
  }

  pay() {
    if (this.paying || this.paymentElementForm.invalid) return;

    this.paying = true

    const { name, email, address, zipcode, city } = this.paymentElementForm.getRawValue();



    this.stripe.confirmPayment({
      elements: this.paymentElement.elements,
      confirmParams: {
        payment_method_data: {
          billing_details: {
            name: name as string,
            email: email as string,
            address: {
              line1: address as string,
              postal_code: zipcode as string,
              city: city as string
            }
          }
        }
      },
      redirect: 'if_required'
    }).subscribe(result => {
      //console.log('Result', result);
      if (result.error) {
        //alert({ success: false, error: result.error.message });
        this.paymentFailed = true
        this.paying = false
        this.paymentFailedMessage = result.error.message
      } else {
        if (result.paymentIntent.status === 'succeeded') {
          //alert({ success: true });
          setTimeout(() => {
            this.paying = false
            this.paidSuccessfully = true
          }, 2000);
        }
      }
    });
  }

  private getNewSubscription() : Subscription {
    return {
      type: this.data.type,
      monthlyPrice: this.data.monthlyPrice,
      status: SubscriptionStatus.ACTIVE
    }
  }

  public continue() {
    //when they pay we'll set a temporary fake membership to allow for webook delays
    this.authService.fakeSubscription = this.getNewSubscription()
    this.dialogRef.close()
    //this.authService.reauth()
  }

  public retry() {
    this.paymentFailed = this.paying = this.paidSuccessfully = this.upgrading = this.upgradeFailed = false 
    this.paymentFailedMessage = undefined
  }

  openPortalUrl(): void {
    window.open(this.portalUrl, '_blank');
  }

  public applyingDiscount : boolean = false
  public discountMessage ?: string
  public applyDiscount(code : string){
    this.applyingDiscount = true
    this.paymentService.applyDiscountCode(code)?.pipe(take(1)).subscribe((result : ServiceResult<string>) =>{
      this.applyingDiscount = false
      if(result.data){
        this.discountMessage = "Congrats, you've been successfully upgraded, forwarding you in one second!"
        setTimeout(() => {
          this.continue()
        }, 1500);
      }else if(result.error){
        this.discountMessage = (result.error as unknown) as string
      }
    })
  }
}