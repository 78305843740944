import { Injectable } from '@angular/core';
import { BehaviorSubject, fromEvent, Subscription } from 'rxjs';
import { Asset, GridSection, Mode } from '../vo/GridSection';

export interface ScrubberInfo {
  video?: HTMLVideoElement;
  currentTime: number;
  currentFrame: number;
  totalFrames: number;
  FPS: number;
  isPlaying: boolean;
  activeGrid ?: GridSection
  friendlyTime ?: string
  friendlyDuration ?: string
}

@Injectable({
  providedIn: 'root'
})
export class ScrubberService {

  private FPS: number = 30;
  private currentVideo?: HTMLVideoElement;
  private currentGridSection ?: GridSection

  public info: BehaviorSubject<ScrubberInfo | undefined> = new BehaviorSubject<ScrubberInfo | undefined>(undefined);
  public setupScrubbers: BehaviorSubject<Asset | undefined> = new BehaviorSubject<Asset | undefined>(undefined);

  // subscriptions
  private timeUpdateSubscription?: Subscription;
  private endedSubscription?: Subscription

  constructor() {}

  public setActiveVideo(video: HTMLVideoElement, grid : GridSection): BehaviorSubject<ScrubberInfo | undefined> {
    this.currentVideo = video;
    this.currentGridSection = grid

    // Clear previous subscription if any
    this.clearSubscriptions();

    this.setupEvent()
    //this.timeUpdateSubscription = fromEvent(this.currentVideo, 'timeupdate').subscribe(() => this.handleTimeUpdate());
    this.endedSubscription = fromEvent(this.currentVideo, 'ended').subscribe(() => this.handleEnd());

    setTimeout(() => {
      this.handleTimeUpdate();
    });
    return this.info;
  }
  
  private setupEvent(){
    if(this.currentVideo)this.timeUpdateSubscription = fromEvent(this.currentVideo, 'timeupdate').subscribe(() => this.handleTimeUpdate());
  }

  private stopEvent(){
    if(this.timeUpdateSubscription)this.timeUpdateSubscription.unsubscribe()
  }

  public setAssetStartPercent(start : number){
    //start = start / 100
    if(this.currentGridSection?.activeAsset){
      const time : number = start * (this.currentVideo?.duration || 0)
      //console.log("START", time)
      this.currentGridSection.activeAsset.shownFrom = start
    }
    //console.log(start)
  }

  public setAssetEndPercent(end : number){
    //end = end / 100
    if(this.currentGridSection?.activeAsset){
      const time : number = end * (this.currentVideo?.duration || 0)
      //console.log("END", time)
      this.currentGridSection.activeAsset.shownUntil = end
    }
    //console.log(start)
  }


  private userPaused : boolean = false
  public pause() {
    this.userPaused = true
    if (!this.currentVideo) throw new Error("There is no video set");
    this.currentVideo.pause();
    this.handleTimeUpdate();
  }

  public play() {
    this.userPaused = false
    if (!this.currentVideo) throw new Error("There is no video set");
    this.currentVideo.play();
    this.handleTimeUpdate();
  }

  private timeoutId ?: any
  public scrubToPercent(percent: number, pause : boolean = false) {
    if (!this.currentVideo) throw new Error("There is no video set");

    // Ensure percent is within 0-100 range
    percent = Math.max(0, Math.min(100, percent));

    // Calculate new time based on percent
    const newTime = (percent / 100) * this.currentVideo.duration;

    // Set the new time to the video
    this.currentVideo.currentTime = newTime;

    if(pause){
      this.pause()
      if(this.timeoutId)clearTimeout(this.timeoutId)
      this.timeoutId = setTimeout(() => {
        this.play()
      }, 1000);
    }

    // Update the ScrubberInfo
    this.handleTimeUpdate();
  }

  private clearSubscriptions() {
    if (this.timeUpdateSubscription) this.timeUpdateSubscription.unsubscribe();
    if(this.endedSubscription)this.endedSubscription.unsubscribe()
  }

  private handleEnd():void{
      this.stopEvent()
      if(this.currentVideo){
        //this.currentVideo.pause()
        this.currentVideo.currentTime = this.currentVideo?.duration
        this.currentVideo.play()
      }
      setTimeout(() => {
        this.handleTimeUpdate()
        if(this.currentVideo){
          this.currentVideo.currentTime = 0
          this.currentVideo.play()
          this.setupEvent()
        }
      }, 0);
  }

  private handleTimeUpdate() {
    if (!this.currentVideo) return;

    const currentTime = this.currentVideo.currentTime;
    const duration = this.currentVideo.duration;

    console.log(`Time Update: ${currentTime.toFixed(2)} / ${duration.toFixed(2)}`);


    // Proceed with normal time update logic
    const currentFrame = this.getCurrentFrameFromCurrentTime(currentTime);
    const totalFrames = Math.floor(duration * this.FPS);

    const data: ScrubberInfo = {
        currentFrame: currentFrame,
        currentTime: currentTime,
        totalFrames: totalFrames,
        video: this.currentVideo,
        FPS: this.FPS,
        isPlaying: !this.userPaused,//this.showPlayingNoMatterWhat ? true : !this.currentVideo.paused,
        activeGrid: this.currentGridSection,
        friendlyTime: this.formatTime(currentTime),
        friendlyDuration: this.formatTime(duration)
    };

    this.updateAssetDisplay(currentTime, duration, data);

    this.info.next(data);
}

private updateAssetDisplay(currentTime: number, duration: number, data: ScrubberInfo) {
    if (this.currentGridSection) {
        this.currentGridSection.assets.forEach((asset: Asset) => {
            const shownFrom: number = ((asset.shownFrom || 0) / 100) * duration;
            const shownUntil: number = ((asset.shownUntil || 0) / 100) * duration;
            if (currentTime >= shownFrom && currentTime <= shownUntil) {
                asset.data.display = '';
            } else {
                if (this.currentVideo?.paused && this?.currentGridSection?.mode === Mode.DEVELOPMENT) {
                    asset.data.display = '';
                } else {
                  asset.data.display = 'none';
                }
            }
        });
    }
}

  public getCurrentFrameFromCurrentTime(currentTime: number): number {
    return Math.floor(currentTime * this.FPS);
  }

  public formatTime(seconds: number): string {
    if (seconds < 60) {
      return `${seconds.toFixed(1)}s`;
    }
  
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = Math.floor(seconds % 60);
  
    const hourPart = hours > 0 ? `${hours}hr ` : '';
    const minutePart = minutes > 0 ? `${minutes}min ` : '';
    const secondPart = remainingSeconds > 0 ? `${remainingSeconds}s` : '';
  
    return `${hourPart}${minutePart}${secondPart}`.trim();
  }
  
}