import { AfterViewInit, Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { GridSectionComponent } from '../grid-section/grid-section.component';
import { MemeDetailsComponent } from '../meme-details/meme-details.component';
import { AppService } from '../../service/app.service';
import { ActivatedRoute } from '@angular/router';
import { MediaType } from '../../vo/GridSection';
import { DragDropModule } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-main-content',
  standalone: true,
  imports: [GridSectionComponent, MemeDetailsComponent, DragDropModule],
  templateUrl: './main-content.component.html',
  styleUrl: './main-content.component.scss'
})
export class MainContentComponent implements AfterViewInit {

  public readonly MediaType = MediaType

  @ViewChild('rootGridHolder', { read: ElementRef }) rootGridHolder!: ElementRef
  @ViewChild('rootGrid') rootGrid !: GridSectionComponent

  constructor(public appService : AppService, route : ActivatedRoute){
    //inside the service it doesnt have the right context to a route, so let's pass it in here
    appService.route = route
  }

  ngAfterViewInit(): void {
    this.appService.rootGridHolder = this.rootGridHolder.nativeElement
  }

  @HostListener('window:resize')
  resize() {
    if (this.rootGrid) {
      this.rootGrid.resizing = true
      setTimeout(() => {
        setTimeout(() => {
          this.rootGrid.resizing = false
        }, 100);
      }, 0);
    }
  }

}
