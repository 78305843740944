<div >
    <h2 mat-dialog-title>Edit Image!</h2>
    @if(loaded){
        <button mat-icon-button color="primary" class="title-info-button" matTooltip="Filters" matTooltipPosition="below" (click)="filtersOpened = !filtersOpened">
        <mat-icon>tune</mat-icon>
        </button>
        <br>
    }
</div>
<mat-dialog-content class="centered-dialog-content-vertical-top no-scroll content">
    @if(data){
        <mat-drawer-container class="drawer">
            <mat-drawer #drawer (openedChange)="rejiggerImage()" mode="side" [opened]="filtersOpened" style="width: 175px !important; height: 100% !important; padding: 15px !important;" class="no-scroll">
                <mat-label style="color: white;">Blur</mat-label>
                <br>
                <span>
                    <mat-slider min="0" max="10" step="0.1" showTickMarks discrete >
                        <input (change)="visiblePropsUpdated()"  matSliderThumb [(ngModel)]="data.blur"/> 
                    </mat-slider> 
                    <mat-divider></mat-divider>
                </span>

                <mat-label style="color: white;">Sepia</mat-label>
                <br>
                <span>
                    <mat-slider min="0" max="1" step="0.1" showTickMarks discrete>
                        <input (change)="visiblePropsUpdated()"  matSliderThumb [(ngModel)]="data.sepia"/> 
                    </mat-slider> 
                    <mat-divider></mat-divider>
                </span>

                <mat-label style="color: white;">Greyscale</mat-label>
                <br>
                <span>
                    <mat-slider min="0" max="1" step="0.1" showTickMarks discrete>
                        <input (change)="visiblePropsUpdated()"  matSliderThumb [(ngModel)]="data.grey"/> 
                    </mat-slider> 
                    <mat-divider></mat-divider>
                </span>

                <mat-label style="color: white;">Color Change</mat-label>
                <br>
                <span>
                    <mat-slider min="0" max="360" step="1" showTickMarks discrete>
                        <input (change)="visiblePropsUpdated()" matSliderThumb [(ngModel)]="data.hue"/> 
                    </mat-slider> 
                    <mat-divider></mat-divider>
                </span>

                <mat-label style="color: white;">Saturation</mat-label>
                <br>
                <span>
                    <mat-slider min="0" max="10" step="0.1" showTickMarks discrete>
                        <input (change)="visiblePropsUpdated()"  matSliderThumb [(ngModel)]="data.saturation"/> 
                    </mat-slider> 
                    <mat-divider></mat-divider>
                </span>

                <mat-label style="color: white;">Brightness</mat-label>
                <br>
                <span>
                    <mat-slider min="0" max="3" step="0.1" showTickMarks discrete>
                        <input (change)="visiblePropsUpdated()" matSliderThumb [(ngModel)]="data.brightness"/> 
                    </mat-slider> 
                </span>


                  <!--const m : Media = {blur : 0, sepia : 0, grey : 0, hue : 0, saturation : 0, brightness: 0, mediaType : MediaType.LOCAL_IMAGE, mediaLocation : e.dataURL || '', originalLocation : e.dataURL || ''}-->
            </mat-drawer>
          
            <div class="no-scroll image-holder" >
                @if(!loaded){<mat-spinner></mat-spinner>}
                <image-cropper 
                    [hidden]="!loaded"
                    style="background-color: transparent !important; max-width: 94%; max-height: 94%;"
                    [hideResizeSquares]="filtersOpened"
                    [disabled]="filtersOpened"
                    [style.filter]="'grayscale('+data.grey+') sepia('+data.sepia+') hue-rotate('+data.hue+'deg) saturate('+data.saturation+') brightness('+data.brightness+') blur('+data.blur+'px)'"
                    class="image-cropper"
                    [imageBase64]="data.mediaLocation.includes('http') ? undefined :data.mediaLocation" 
                    [imageURL]="data.mediaLocation.includes('http') ? data.mediaLocation : undefined"
                    [output]="'base64'" 
                    [maintainAspectRatio]="false"
                    [resizeToHeight]="1500"
                    [resizeToWidth]="1500"
                    [containWithinAspectRatio]="false"
                    [backgroundColor]="'#1e1a1d'"
                    (imageCropped)="imageCropped($event)" 
                    (imageLoaded)="imageLoaded($event)" 
                    (cropperReady)="cropperReady()"
                    (loadImageFailed)="loadImageFailed($event)"
                    >
                 </image-cropper>
            </div>
          
          </mat-drawer-container>
        }
</mat-dialog-content>
<mat-dialog-actions align="end">
    @if(imageChanged){<button mat-button mat-dialog-close (click)="cancel()">Cancel</button>}
   @if(data.mediaLocation != data.originalLocation && imageChanged){<button mat-button (click)="revert()">Revert to Original</button>}
    <button mat-button mat-dialog-close (click)="apply()">Ok!</button>
</mat-dialog-actions>