import { Component } from '@angular/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDividerModule } from '@angular/material/divider';
import { MatRadioModule } from '@angular/material/radio';
import { Pickable } from '../../vo/Pickers';
import { AppService } from '../../service/app.service';
import { FormsModule } from '@angular/forms';
import { NgAutoAnimateDirective } from 'ng-auto-animate';

@Component({
  selector: 'app-text-decoration',
  standalone: true,
  imports: [NgAutoAnimateDirective, MatCheckboxModule, MatRadioModule, MatCheckboxModule, MatDividerModule, FormsModule],
  templateUrl: './text-decoration.component.html',
  styleUrl: './text-decoration.component.scss'
})
export class TextDecorationComponent {

  public constructor(public appService : AppService){
    
  }

  public updateValue(value : string){
    if(this.appService.gridSection?.activeAsset?.data){
      this.appService.gridSection.activeAsset.data.textDecorationStyle = value
    }
  }


  public readonly textStyles: Array<Pickable> = [
    new Pickable('Line-through', 'format_strikethrough', 'textDecorationLine'),
    new Pickable('Underline', 'format_underline', 'textDecorationLine'),
    new Pickable('Overline', 'format_overline', 'textDecorationLine'),
  ]
  addOrRemoveTextStyle(add: boolean, ts: Pickable) {
    if (add) {
      if (!this.hasStyle(ts)) {
        if (this.appService.gridSection?.activeAsset?.data) this.appService.gridSection.activeAsset.data.textDecorationLine = `${this.appService.gridSection?.activeAsset.data.textDecorationLine} ${ts.name?.toLocaleLowerCase()}`
      }
    } else {
      if (this.hasStyle(ts)) {
        if (this.appService.gridSection?.activeAsset?.data) this.appService.gridSection.activeAsset.data.textDecorationLine = this.appService.gridSection?.activeAsset?.data?.textDecorationLine?.split(ts.name?.toLowerCase() || '').join('').trim()
      }
    }
    //this.a('Setting prop on asset', `${this.editData?.asset?.type} : textDecorationLine = ${this?.editData?.asset?.data.textDecorationLine}`)
  }

  hasStyle(ts: Pickable): boolean {
    return this.appService.gridSection?.activeAsset?.data?.textDecorationLine?.includes(ts.name?.toLowerCase() || '') || false
  }

  hasAtLeastOneStyle(): boolean {
    return (this.appService.gridSection?.activeAsset?.data?.textDecorationLine?.trim()?.length || 0) > 0
  }

  hasAllStyles(): boolean {
    const a: number = this.appService.gridSection?.activeAsset?.data?.textDecorationLine?.trim()?.split(' ').length || 0
    const b: number = this.textStyles.length
    return a == b
  }

  addAllStyles() {
    if (this.appService.gridSection?.activeAsset?.data && this.appService.gridSection?.activeAsset.data.textDecorationLine && this.hasAllStyles()) {

      this.appService.gridSection.activeAsset.data.textDecorationLine = ''
    } else {
      if (this.appService.gridSection?.activeAsset?.data && this.appService.gridSection?.activeAsset.data) {
        this.appService.gridSection.activeAsset.data.textDecorationLine = this.textStyles.map((ts: Pickable) => ts.name?.toLocaleLowerCase()).join(' ')
      }
    }
    //this.a('Setting prop on asset', `${this.editData?.asset?.type} : textDecorationLine = ${this?.editData?.asset?.data.textDecorationLine}`)
  }

  public readonly textStylesAugmentors: Array<Pickable> = [
    new Pickable('Solid', 'temp', 'textDecorationStyle'),
    new Pickable('Double', 'temp', 'textDecorationStyle'),
    new Pickable('Dotted', 'temp', 'textDecorationStyle'),
    new Pickable('Dashed', 'temp', 'textDecorationStyle'),
    new Pickable('Wavy', 'temp', 'textDecorationStyle'),
  ]
}
