import { Component, Inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import {MatTabsModule} from '@angular/material/tabs';
import { DankTankAuthService, SubscriptionUser } from '../../service/dank-tank-auth.service';
import { User } from '@supabase/supabase-js';
import { MatInputModule } from '@angular/material/input';
import { TitleCasePipe, CurrencyPipe } from '@angular/common';
import { IntentResponse, PaymentService, SubscriptionStatus, SubscriptionType } from '../../service/payment.service';
import { StripeService, StripeCardComponent, StripeElementsDirective, StripePaymentElementComponent } from 'ngx-stripe';
import {
  StripeCardElementOptions,
  StripeElementsOptions,
  StripePaymentElementOptions
} from '@stripe/stripe-js';
import { take } from 'rxjs';
import { MatProgressBar } from '@angular/material/progress-bar';
import { MatGridListModule } from '@angular/material/grid-list';
import { ImageUploadService, UploadImage } from '../../service/image-upload.service';
import { LazyLoadDirective } from '../../directive/lazy-load.directive';
import { DownloadImageComponent } from '../download-image/download-image.component';
import { ServiceResult } from '../../service/result';
import { ProfileButtonComponent } from '../profile-button/profile-button.component';

export interface MultiUserData{
  user : User,
  subscriptionUser ?: SubscriptionUser | null
}

enum ImageLoadStatus {
  LOADED = "LOADED",
  ERROR = "ERROR",
}

type ImageStatusMap = Record<string, ImageLoadStatus>

@Component({
  selector: 'app-user-profile',
  standalone: true,
  imports: [LazyLoadDirective, MatGridListModule, MatProgressBar, StripeElementsDirective, StripePaymentElementComponent, StripeCardComponent, TitleCasePipe, CurrencyPipe, MatDialogModule, MatButtonModule, MatIconModule, MatTabsModule, FormsModule, MatFormFieldModule, MatInputModule, ProfileButtonComponent],
  templateUrl: './user-profile.component.html',
  styleUrl: './user-profile.component.scss'
})
export class UserProfileComponent {

  SubscriptionType = SubscriptionType
  SubscriptionStatus = SubscriptionStatus

  public readonly images: ImageStatusMap = {

  }

  public readonly ImageLoadStatus = ImageLoadStatus


  constructor(
    public stripe : StripeService,
    public dialogRef: MatDialogRef<UserProfileComponent>,
    @Inject(MAT_DIALOG_DATA) public data: MultiUserData,
    public paymentService : PaymentService,
    private imageService : ImageUploadService,
    private dialog : MatDialog,
    public authService : DankTankAuthService,
  ) {}

  public portaUrl ?: string
  public userImages ?: UploadImage[]


  public tabChanged(index : number){
    if(index == 1){
      //payment service stuff here
      /*
      if(this.data.subscriptionUser?.subscription?.type != SubscriptionType.NONE){
        if(!this.portaUrl)this.paymentService.getPortalURL()?.pipe(take(1)).subscribe((value : ServiceResult<string>) => {
          if(value.data){
            this.portaUrl = value.data
          }else if(value.error){
            
          }
        })
      }
        */
    }else if(index == 2){
      if(!this.userImages)this.imageService.getAllImagesForUser().pipe(take(1)).subscribe((value : ServiceResult<UploadImage[]>) => {
        this.userImages = value.data?.reverse()
      })
    }
  }

  getUriFromImage(img : UploadImage, isFull ?: boolean) : string{
    const base : string = 'https://dinbxwvmllmigojxxcce.supabase.co/storage/v1/object/public/user_content'
    if(isFull){
      return `${base}/${this.data.subscriptionUser?.id}/${img.fileName}${img.fileExtension}`
    }else{
      return `${base}/${this.data.subscriptionUser?.id}/${img.fileName}_thumb${img.fileExtension}`
    }
  }

  openUserImage(userImage : UploadImage){
    const popup : MatDialogRef<DownloadImageComponent> = this.dialog.open(DownloadImageComponent, { data: null, hasBackdrop : true, backdropClass : 'pulsing-rainbow-tiled-background' })
    popup.componentInstance.uploadImage = userImage
  }

  openPortalUrl(): void {
    window.open(this.portaUrl, '_blank');
  }

  onClose(): void {
    this.dialogRef.close();
  }

}
