import { AfterViewInit, Component, ElementRef, Inject, ViewChild, ViewChildren } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { ImageCropperComponent, ImageCroppedEvent, LoadedImage } from 'ngx-image-cropper';
import { Media } from '../../vo/GridSection';
import { MatButtonModule } from '@angular/material/button';
import { DialogRef } from '@angular/cdk/dialog';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
//@ts-ignore 
import fx from 'glfx';
import { MatSliderModule } from '@angular/material/slider';
import { MatDividerModule } from '@angular/material/divider';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

interface Filter {
  name: string
}

@Component({
  selector: 'app-image-editor',
  standalone: true,
  imports: [MatProgressSpinnerModule, MatTooltipModule, MatIconModule, FormsModule, MatDividerModule, MatSliderModule, MatSelectModule, MatFormFieldModule, MatSidenavModule, MatButtonModule, MatDialogModule, ImageCropperComponent],
  templateUrl: './image-editor.component.html',
  styleUrl: './image-editor.component.scss'
})
export class ImageEditorComponent implements AfterViewInit {


  @ViewChild('cropperHolder', { read: ElementRef }) cropperHolder?: ElementRef

  public filtersOpened: boolean = false
  public loaded: boolean = false

  public selectedFilter?: Filter
  public readonly filters: Filter[] = [
    { name: 'Brightness' },
    { name: 'Contrast' },
    { name: 'Saturation' },
    { name: 'Hue' },
  ]

  constructor(public elRef: ElementRef, @Inject(MAT_DIALOG_DATA) public data: Media, private dialogRef: DialogRef) {

  }

  ngAfterViewInit(): void {

  }

  public cancel() {
    this.revert()
  }

  public apply() {
    // this.data.mediaLocation = this.tempImageContent || ''
    this.data.originalLocation = this.data.mediaLocation
    this.dialogRef.close()
  }

  revert() {
    for (let key in this.data) {
      if (typeof (this.data as any)[key] === 'number') {
        if (key === "mediaType") {
          continue
        }
        (this.data as any)[key] = 0;
      }
    }

    this.data.saturation = 1
    this.data.brightness = 1

    this.updateLastUpdateTime()
    this.data.mediaLocation = this.data.originalLocation
    this.imageChanged = false
  }

  rejiggerImage() {
    return;
    const tmp = this.data.mediaLocation
    this.data.mediaLocation = this.data.originalLocation
    this.data.mediaLocation = tmp
  }

  /////////////////////PHOTO EDITING STUFF
  imageChangedEvent: Event | null = null;

  public imageChanged: boolean = false

  visiblePropsUpdated() {
    this.imageChanged = true
  }

  lastUpdateTime: number = 0;
  imageCropped(event: ImageCroppedEvent) {
    //const img = this.sanitizer.bypassSecurityTrustUrl(event.objectUrl || '');
    //this.toolbarService.imageEditedSubject.next({imageContent : event.base64?.toString() || ''})
    // event.blob can be used to upload the cropped image
    const currentTime: number = Date.now();

    if (currentTime - this.lastUpdateTime > 500 && this.loaded) {
      // Update the mediaLocation
      this.data.mediaLocation = event.base64?.toString() || ''

      this.updateLastUpdateTime()

      this.imageChanged = true
    }
  }
  imageLoaded(image: LoadedImage) {
    // show cropper
    this.loaded = true
    this.updateLastUpdateTime()
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed(evt: any) {
    // show message
    console.log()
  }

  private updateLastUpdateTime() {
    const currentTime: number = Date.now();
    this.lastUpdateTime = currentTime
  }
}
