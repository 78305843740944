import { Component, DestroyRef, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { Asset, GridSection, MediaType } from '../../vo/GridSection';
import { Sticker, StickerPack, StickerService, StickerServiceResponse } from '../../service/sticker.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatCardModule } from '@angular/material/card';
import { MatListModule } from '@angular/material/list';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { EditMode, ToolbarService } from '../../service/toolbar.service';
import { ClipboardModule } from '@angular/cdk/clipboard';
//import { ShareButtons } from 'ngx-sharebuttons/buttons'; //ShareButtons,
import { HttpClient, HttpResponse } from '@angular/common/http';
import { ImageUploadService, UploadImage } from '../../service/image-upload.service';
import { take } from 'rxjs';
import { MatMenu, MatMenuModule } from '@angular/material/menu';
import { DankTankAuthService } from '../../service/dank-tank-auth.service';
import { PaymentService, SubscriptionStatus, SubscriptionType } from '../../service/payment.service';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { ShareButtons } from 'ngx-sharebuttons/buttons';
// @ts-ignore
import saveAs from 'file-saver';
import { DeviceDetectorService } from '../../service/device-detector.service';
import e from 'express';
import { ServiceResult } from '../../service/result';
import { DashboardComponent } from '../dashboard/dashboard.component';

interface DownloadResponse {
  body: Blob;
  headers: {
    get(headerName: string): string | null;
  };
}


@Component({
  selector: 'app-download-image',
  standalone: true,
  imports: [ShareButtons, MatProgressBarModule, MatDividerModule, MatMenuModule, MatDividerModule, ClipboardModule, MatTooltipModule, MatIconModule, MatGridListModule, MatDividerModule, MatDialogModule, MatButtonModule, MatExpansionModule, MatProgressSpinnerModule, MatCardModule, MatListModule],
  templateUrl: './download-image.component.html',
  styleUrl: './download-image.component.scss'
})
export class DownloadImageComponent {

  public loaded: boolean = false
  public canDownload: boolean = true
  readonly SubscriptionType = SubscriptionType
  readonly SubscriptionStatus = SubscriptionStatus
  public dashboard ?: DashboardComponent

  constructor(private deviceDetector : DeviceDetectorService, public paymentService: PaymentService, public authService: DankTankAuthService, private uploadService: ImageUploadService, private http: HttpClient, private toolbarService: ToolbarService, private destroyRef: DestroyRef, private dialogRef: MatDialogRef<DownloadImageComponent>, @Inject(MAT_DIALOG_DATA) public data?: GridSection) {
    this.canDownload = data?.media?.mediaType == MediaType.LOCAL_IMAGE
  }

  cancel() {

  }

  public uploading: boolean = false
  public uploadImage?: UploadImage
  saveToAccount() {
    this.uploading = true
    this.uploadImage = undefined
    if (this.data && this.data.downloadURL) this.uploadService.uploadImage(this.data.downloadURL)?.pipe(take(1)).subscribe((value: ServiceResult<UploadImage>) => {
      if (value.data) {
        this.uploading = false
        this.uploadImage = value.data
      }
    })
  }

  regenerate(){
    if(this.dashboard){
        if(this.data)this.data.downloadURL = undefined
        this.dashboard.captureScreenshot(true)
    }
  }

  downloadAndSave() {
    this.download()
    this.saveToAccount()
  }

  getUriFromImage(img?: UploadImage, isFull?: boolean): string {
    const base: string = 'https://dinbxwvmllmigojxxcce.supabase.co/storage/v1/object/public/user_content'
    if (isFull) {
      return img ? `${base}/${this.authService.subscriptionUserSubject.value?.data?.id}/${img.fileName}${img.fileExtension}` : ''
    } else {
      return img ? `${base}/${this.authService.subscriptionUserSubject.value?.data?.id}/${img.fileName}_thumb${img.fileExtension}` : ''
    }
  }

  public download() {
    const downloadURL = this.data?.downloadURL;
  
    if (!downloadURL) {
      console.error('No download URL provided.');
      return;
    }
  
    if (downloadURL.startsWith('data:')) {
      // Handle data URL (base64)
      const parts = downloadURL.split(';base64,');
      const imageType = parts[0].split(':')[1];
      const byteCharacters = atob(parts[1]);
      const byteNumbers = new Uint8Array(byteCharacters.length);
  
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
  
      const blob = new Blob([byteNumbers], { type: imageType });
      this.saveBlob(blob, imageType);
    } else if (downloadURL.startsWith('blob:')) {
      // Handle blob URL
      fetch(downloadURL)
        .then(response => response.blob())
        .then(blob => {
          const imageType = blob.type;
          this.saveBlob(blob, imageType);
        })
        .catch(error => console.error('Failed to fetch blob:', error));
    } else {
      console.error('Unsupported download URL format.');
    }
  }
  
  private saveBlob(blob: Blob, imageType: string) {
    const filename = `download_${Date.now()}.${imageType.split('/')[1]}`;
    const url = URL.createObjectURL(blob);
  
    if (this.deviceDetector.isTouchOnlyDevice()) {
      const newWindow = window.open('', '_blank'); // Open a new window
  
      if (newWindow) {
        newWindow.document.write(`<img src="${url}" alt="Download Image">`);
        newWindow.document.title = filename; // Set the window title to the filename
        newWindow.document.close(); // Ensure the new window is fully rendered
      } else {
        // Fallback if the window failed to open (e.g., due to popup blockers)
        const a = document.createElement('a');
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }
    } else {
      saveAs(blob, filename);
    }
  }  

}
