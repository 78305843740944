import { Component, Inject, NgZone } from '@angular/core';
import { FileUploaderComponent, ImageData, UploadType } from '../file-uploader/file-uploader.component';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { Asset, GridSection, Media, MediaType } from '../../vo/GridSection';
import { MatDialog } from '@angular/material/dialog';
import { ImageEditorComponent } from '../image-editor/image-editor.component';
import { HistoryRecordType, HistoryService } from '../../service/history.service';
import { createAssetsFromJSON } from '../../util/AssetCreator';
import { ToolbarService } from '../../service/toolbar.service';
import { FontService } from '../../service/font.service';

@Component({
  selector: 'app-media-attacher',
  standalone: true,
  imports: [FileUploaderComponent],
  templateUrl: './media-attacher.component.html',
  styleUrl: './media-attacher.component.scss'
})
export class MediaAttacherComponent {

  constructor(private history : HistoryService, private ref : MatBottomSheetRef<MediaAttacherComponent>, private dialog : MatDialog, private toolbarService : ToolbarService, private fontService : FontService, private zone : NgZone){
  }

  imageChosen( e : ImageData){
    //if(this.isGridSection(this.data)){
    if(e.section){
        switch(e.uploadType){
          case UploadType.GIF:
          case UploadType.IMAGE:
            const mediaType : MediaType = e.uploadType == UploadType.IMAGE ? MediaType.LOCAL_IMAGE : MediaType.LOCAL_ANIMATED_GIF
            const imageMedia : Media = {loaded : false, blur : 0, sepia : 0, grey : 0, hue : 0, saturation : 1, brightness: 1, mediaType : mediaType, mediaLocation : e.dataURL || '', originalLocation : e.dataURL || ''}
            e.section.media = imageMedia
            if(e.uploadType == UploadType.IMAGE){
              const imageHeight = e.section.rootGridSectionWidth > e.section.rootGridSectionHeight ? e.section.rootGridSectionHeight + 'px' : '75%'
              this.dialog.open(ImageEditorComponent, {data : e.section?.media, minWidth : e.section?.rootGridSectionWidth, height : imageHeight})
            }
            break;
          case UploadType.VIDEO:
            const videoMedia : Media = {loaded: false, blur : 0, sepia : 0, grey : 0, hue : 0, saturation : 1, brightness: 1, mediaType : MediaType.VIDEO, mediaLocation : e.dataURL || '', originalLocation : e.dataURL || ''}
            e.section.media = videoMedia
            //const videoHeight = e.section.rootGridSectionWidth > e.section.rootGridSectionHeight ? e.section.rootGridSectionHeight + 'px' : '75%'
            //this.dialog.open(ImageEditorComponent, {data : e.section?.media, minWidth : e.section?.rootGridSectionWidth, height : videoHeight})
            break
        }
        if(e.section.isRootGrid)createAssetsFromJSON(e.section, this.toolbarService, this.fontService, undefined, this.zone, true)
      }else if(e.asset){
        switch(e.uploadType){
          case UploadType.IMAGE:
            e.asset.data.location = e.dataURL
            break
        }
      }
      this.ref.dismiss()
  }

  private isGridSection(d: any): d is GridSection {
    return (d as GridSection).rootGridSectionWidth !== undefined;
  }
}
