import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { GridSection } from '../../vo/GridSection';
import { MatDividerModule } from '@angular/material/divider';
import { RoundPipe } from '../../util/util.pipe';

@Component({
  selector: 'app-meme-details',
  standalone: true,
  imports: [MatCardModule, MatIconModule, MatDividerModule, RoundPipe],
  templateUrl: './meme-details.component.html',
  styleUrl: './meme-details.component.scss'
})

export class MemeDetailsComponent implements OnChanges{

  @Input() public expanded : boolean = false

  @Input() gridSection ?: GridSection

  public isReady : boolean = false

  public constructor(){
    console.log()
  }

 
  ngOnChanges(changes: SimpleChanges) {
    this.isReady = true
  }

  public get title() {
    return this.gridSection?.name ?? 'undefined'
  }

  public getTextWidthFromElement(text : string , element : HTMLElement) {
    // Create a temporary canvas element
    const canvas = document.createElement('canvas')
    const context = canvas.getContext('2d')
    
    // Get the computed font style of the element
    if(context){
      const fontStyle = window.getComputedStyle(element).font
      context.font = fontStyle
      
      // Measure the text width
      const metrics = context.measureText(text)
      
      return metrics.width + 75
    }else{
      return 75
    }
}

}
