import { AfterViewInit, Component, DestroyRef, ElementRef, Inject, Renderer2 } from '@angular/core';
import { GuidedTour, GuidedTourStep, GuidedTourStepDetails, ShowFromDirection } from '../../guidedTour/GuidedTour';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { DashboardComponent } from '../dashboard/dashboard.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { delay, Observable, Subject, Subscription, take } from 'rxjs';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { MatMenu, MatMenuTrigger } from '@angular/material/menu';
import { EventEmitter } from 'node:stream';
import { AssetType, Mode } from '../../vo/GridSection';
import { DeviceDetectorService } from '../../service/device-detector.service';
import { EditMode } from '../../service/toolbar.service';


@Component({
  selector: 'app-guided-tour',
  standalone: true,
  imports: [MatDialogModule, MatButtonModule, MatProgressSpinnerModule, MatIconModule],
  templateUrl: './guided-tour.component.html',
  styleUrl: './guided-tour.component.scss'
})
export class GuidedTourComponent {

  public tour ?: GuidedTour
  public step ?: GuidedTourStep
  private timeoutId ?: any
  private shouldContinue : boolean = true

  constructor(private deviceDetector : DeviceDetectorService, private renderer: Renderer2, private elRef : ElementRef, @Inject(MAT_DIALOG_DATA) private dashboard : DashboardComponent, private destroyRef : DestroyRef, private dialogRef : MatDialogRef<GuidedTourComponent>){
    this.createGuidedTour()
    dialogRef.afterClosed().pipe(take(1)).subscribe(() => this.tour?.end())
  }


  private guidedTour ?: GuidedTour
  public createGuidedTour(){
    this.dashboard.tourMode = true
    this.shouldContinue = true
    this.tour = new GuidedTour("DankTank", this.renderer)

    const gt : GuidedTour = this.tour

    gt.addStep("Welcome! Getting Started", "Welcome to DankTank.  Your one stop shop for meme creation.  Continue with this guided tour for a quick lesson on how to use it.", {before : this.startTour, scope : this})
      .addStep("Layout", "Change layout by adding and subtracting panels", {futureElement : this.dashboard.layoutButton, showFrom : ShowFromDirection.BOTTOM})
      .addStep("Aspect Ratio", "Change aspect ratio to suit your memes width and height", {futureElement : this.dashboard.aspectRatioButton, showFrom : ShowFromDirection.BOTTOM})
      .addStep("Colors", "Update background and border colors here", {futureElement : this.dashboard.sectionColorButton, showFrom : ShowFromDirection.BOTTOM})
      .addStep("Border", "Update border size and style here", {futureElement : this.dashboard.sectionBorderButton, showFrom : ShowFromDirection.BOTTOM})
      .addStep("Assets", "Add assets here", {before : this.openAssetMenu, after : this.closeAssetMenu, scope: this, futureElement : this.dashboard.addAssetButton, showFrom : ShowFromDirection.RIGHT})
      .addStep("Finish Editing", "Click this button when you're ready to download or save your creation!", {before : this.openSaveAndShareMenu, after : this.closeSaveAndShareMenu, scope : this, futureElement : this.dashboard.saveAndShareButtonList, showFrom : ShowFromDirection.LEFT})
      //.addStep("Finish Editing", "Click this button when you're ready to download or save your creation!", {before : this.switchToDevelopment, scope : this, futureElement : this.dashboard.finishEditingButton, showFrom : ShowFromDirection.LEFT})
      //.addStep("Save", "Save your creation to your device to work on later!", {before : this.switchToViewing, scope : this, futureElement : this.dashboard.saveButton, showFrom : ShowFromDirection.BOTTOM})
      //.addStep("Download", "Download whatever you've made!", {before : this.switchToViewing, scope : this, futureElement : this.dashboard.downloadButton, showFrom : ShowFromDirection.BOTTOM})
      .addStep("Info", "Find your saved creations, as well as most popular memes and other information about the app!", {before : this.showInfoMenu, after:this.closeInfoMenu, scope : this, futureElement : this.dashboard.infoButton, showFrom : ShowFromDirection.LEFT})
      .addStep("Text Editing", "Now lets look at the screen for editing text!", {before : this.changeToTextEditing, scope : this})
      .addStep("Text Editing", "When in text editing mode, the toolbar at top changes.  To the far left is back button", {before : this.changeToTextEditing, scope : this, futureElement : this.dashboard.textBackButton, showFrom : ShowFromDirection.BOTTOM})
      .addStep("Text Editing - Color", "All things color can be edited from this menu", {before : this.changeToTextEditing, scope : this, futureElement : this.dashboard.textColorsButton, showFrom : ShowFromDirection.BOTTOM})
      .addStep("Text Editing - Text Color", "Change the color of the text with this button", {before : this.changeToColorTextEditing, scope : this, futureElement : this.dashboard.textColorButton, showFrom : ShowFromDirection.BOTTOM})
      .addStep("Text Editing - Background Color", "This fills the area behind the text with color", {before : this.changeToColorTextEditing, scope : this,  futureElement : this.dashboard.textBackgroundColorButton, showFrom : ShowFromDirection.BOTTOM})
      .addStep("Text Editing - Border Color", "This changes the color of the border of the area behind the text", {before : this.changeToColorTextEditing, scope : this,  futureElement : this.dashboard.textBorderColorButton, showFrom : ShowFromDirection.BOTTOM})
      .addStep("Text Editing - Change fonts", "This changes the color of the stroke around the letters", {before : this.changeToColorTextEditing, scope : this,  futureElement : this.dashboard.textStrokeColorButton, showFrom : ShowFromDirection.BOTTOM})
      .addStep("Text Editing - Change fonts", "Choose from 100s of beautiful fonts to enhance your creations", {before : this.changeToTextEditing, scope : this,  futureElement : this.dashboard.fontButton, showFrom : ShowFromDirection.BOTTOM})
      .addStep("Text Editing - more style settings", "This menu is full of amazing tools to allow you to change everything from text size to border width, give this a look!", {before : this.changeToTextEditing, scope : this,  futureElement : this.dashboard.textSizesButton, showFrom : ShowFromDirection.BOTTOM})
      .addStep("Text Editing - Text Decorations", "This allows you to add fun decorations to your text, including underline, strike through and much more!", {before : this.changeToTextEditing, scope : this,  futureElement : this.dashboard.textDecorationButton, showFrom : ShowFromDirection.BOTTOM})
      .addStep("Text Editing - Delete Text", "Change your mind?  Conveniently use this button to trash the text!", {before : this.changeToTextEditing, scope : this,  futureElement : this.dashboard.trashTextButton, showFrom : ShowFromDirection.BOTTOM})



    gt.start().pipe(takeUntilDestroyed(this.destroyRef)).subscribe({
      next : (step : GuidedTourStep) => {
        setTimeout(() => {
          if(this.timeoutId){
            //clearTimeout(this.timeoutId)
            this.timeoutId = undefined
          }
        }, 1100);
        this.step = step
      },
      complete : () => {
        this.tour?.cleanup()
        this.dashboard.tourMode = false
        this.dialogRef.close()
      }
    })
  }

  private startTour(){
    this.dashboard.tourMode = true
    this.shouldContinue = true
    this.tour = new GuidedTour("DankTank", this.renderer)
  }

  private changeToColorTextEditing() : Observable<any>{
    const sub : Subject<any> = new Subject()
    setTimeout(() => {
      sub.next({})
    }, 400)
    return sub
  }

  private changeToTextEditing(){
    this.dashboard.toolbarService.editModeSubject.next({ mode: EditMode.TEXT })
  }

  private showInfoMenu(){
    this.dashboard.toolbarService.editModeSubject.next({ mode: EditMode.BASIC })
    if(this.deviceDetector.isPortrait()){
      this.dialogRef.updatePosition({bottom : '450px'})
    }
    this.switchToDevelopment()
    this.dashboard.infoTrigger.openMenu()
  }

  private closeInfoMenu(){
    this.dialogRef.updatePosition({top : ''})
    this.switchToDevelopment()
    this.dashboard.infoTrigger.closeMenu()
  }

  private switchToViewing(){
    if(this.dashboard.gridSection)this.dashboard.gridSection.mode = Mode.VIEWING
  }

  private switchToDevelopment(){
    if(this.dashboard.gridSection)this.dashboard.gridSection.mode = Mode.DEVELOPMENT
  }

  private menuSubscription ?: Subscription
  private openAssetMenu(step : GuidedTourStep):Observable<any>{
    this.switchToDevelopment()
    if(step.details)step.details.showFrom = ShowFromDirection.RIGHT
    this.shouldContinue = true
    const sub : Subject<any> = new Subject()
    const menuTrigger : MatMenuTrigger = this.dashboard.assetTrigger
    //if they click outside and close the menu we'll consider it a skip
    this.menuSubscription = menuTrigger.menuClosed.subscribe(() => {
      this.tour?.cleanup()
      if(this.timeoutId){
        this.shouldContinue = false
        clearTimeout(this.timeoutId)
        this.timeoutId = undefined
      }
    })

    menuTrigger.openMenu()
    this.timeoutId = setTimeout(() => {
      this.showAddTextAsset(step, sub)
    }, 500);
    return sub.asObservable()
  }


  private closeAssetMenu(step : GuidedTourStep):Observable<any>{
    const sub : Subject<any> = new Subject()
    const menuTrigger : MatMenuTrigger = this.dashboard.assetTrigger
    //since they didn't skip lets unsubscribe
    this.menuSubscription?.unsubscribe()
    this.tour?.cleanup()
    menuTrigger.closeMenu()
    if(this.timeoutId){
      this.shouldContinue = false
      clearTimeout(this.timeoutId)
      this.timeoutId = undefined
    }
    setTimeout(() => {
      sub.next({})
    }, 100);
    return sub.asObservable()
  }

  private openSaveAndShareMenu(step : GuidedTourStep):Observable<any>{
    this.switchToDevelopment()
    if(step.details)step.details.showFrom = ShowFromDirection.LEFT
    this.shouldContinue = true
    const sub : Subject<any> = new Subject()
    const menuTrigger : MatMenuTrigger | undefined = this.dashboard.saveAndShareButton
    //if they click outside and close the menu we'll consider it a skip
    this.menuSubscription = menuTrigger?.menuClosed.subscribe(() => {
      this.tour?.cleanup()
      if(this.timeoutId){
        this.shouldContinue = false
        clearTimeout(this.timeoutId)
        this.timeoutId = undefined
      }
    })

    menuTrigger?.openMenu()
    this.timeoutId = setTimeout(() => {
      this.showAddTextAsset(step, sub)
    }, 500);
    return sub.asObservable()
  }

  private closeSaveAndShareMenu(){
    const sub : Subject<any> = new Subject()
    const menuTrigger : MatMenuTrigger | undefined = this.dashboard.saveAndShareButton
    //since they didn't skip lets unsubscribe
    this.menuSubscription?.unsubscribe()
    this.tour?.cleanup()
    menuTrigger?.closeMenu()
    if(this.timeoutId){
      this.shouldContinue = false
      clearTimeout(this.timeoutId)
      this.timeoutId = undefined
    }
    setTimeout(() => {
      sub.next({})
    }, 100);
    return sub.asObservable()
  }

  showAddTextAsset(step : GuidedTourStep, sub : Subject<any>){
    if(!this.shouldContinue)return
    step.message = "Add text, memes, stickers, emojis, shapes, speech bubbles and images from your device to make something amazing!"
    const details : GuidedTourStepDetails | undefined = step.details
    if(details){
      //details.showFrom = ShowFromDirection.LEFT
      //details.xOffset = -35
      //details.yOffset = 125
    }
    this.tour?.updateAsyncStep(step, details)
    this.timeoutId = setTimeout(() => {
      //this.showAddMemeAsset(step, sub)
      sub.next({})
    }, 500);
  }

  showAddMemeAsset(step : GuidedTourStep, sub : Subject<any>){
    if(!this.shouldContinue)return
    step.message = "Add popular memes here"
    const details : GuidedTourStepDetails | undefined = step.details
    if(details){
      details.yOffset = 125
    }
    this.tour?.updateAsyncStep(step, details)
    this.timeoutId = setTimeout(() => {
      this.showAddStickerAsset(step, sub)
    }, 1000);
  }

  showAddStickerAsset(step : GuidedTourStep, sub : Subject<any>){
    if(!this.shouldContinue)return
    step.message = "Add playful stickers here"
    const details : GuidedTourStepDetails | undefined = step.details
    if(details){
      details.yOffset = 185
    }
    this.tour?.updateAsyncStep(step, details)
    this.timeoutId = setTimeout(() => {
      this.showAddEmojiAsset(step, sub)
    }, 2000);
  }

  showAddEmojiAsset(step : GuidedTourStep, sub : Subject<any>){
    if(!this.shouldContinue)return
    step.message = "Add any emoji you can dream of here"
    const details : GuidedTourStepDetails | undefined = step.details
    if(details){
      details.yOffset = 245
    }
    this.tour?.updateAsyncStep(step, details)
    this.timeoutId = setTimeout(() => {
      this.showAddShapeAsset(step, sub)
    }, 1000);
  }

  showAddShapeAsset(step : GuidedTourStep, sub : Subject<any>){
    if(!this.shouldContinue)return
    step.message = "Liven your designs up with shapes"
    const details : GuidedTourStepDetails | undefined = step.details
    if(details){
      details.yOffset = 305
    }
    this.tour?.updateAsyncStep(step, details)
    this.timeoutId = setTimeout(() => {
      this.showAddTextBubbleAsset(step, sub)
    }, 1000);
  }

  showAddTextBubbleAsset(step : GuidedTourStep, sub : Subject<any>){
    if(!this.shouldContinue)return
    step.message = "Bring your characters to life with text bubbles"
    const details : GuidedTourStepDetails | undefined = step.details
    if(details){
      details.yOffset = 365
    }
    this.tour?.updateAsyncStep(step, details)
    this.timeoutId = setTimeout(() => {
      this.showAddUploadImageAsset(step, sub)
    }, 1000);
  }

  showAddUploadImageAsset(step : GuidedTourStep, sub : Subject<any>){
    if(!this.shouldContinue)return
    step.message = "Add any image from your device!"
    const details : GuidedTourStepDetails | undefined = step.details
    if(details){
      details.yOffset = 425
    }
    this.tour?.updateAsyncStep(step, details)
    this.timeoutId = setTimeout(() => {
      sub.next({})
      //this.tour?.next()
    }, 1000);
  }
}
