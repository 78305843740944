import { Component, ElementRef, Inject, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { AssetComponent } from '../asset.component';
//import { Bubble, Comical, BubbleSpec } from '../../../libs/comicaljs'
import { style } from '@angular/animations';
import { EditMode } from '../../../service/toolbar.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { isPlatformBrowser } from '@angular/common';


type BubbleType = typeof import('../../../libs/comicaljs').Bubble;
type ComicalType = typeof import('../../../libs/comicaljs').Comical;


@Component({
  selector: 'app-text-bubble-asset',
  standalone: true,
  imports: [],
  templateUrl: './text-bubble-asset.component.html',
  styleUrl: './text-bubble-asset.component.scss'
})
export class TextBubbleAssetComponent extends AssetComponent implements OnInit{
    comicalJS !: any
    Bubble!: BubbleType;
    Comical!: ComicalType;

    @ViewChild('bubbleAsset') public bubbleAsset ?: any
    @ViewChild('bubbleInner') public bubbleInner ?: any


    private bubble?: any;

    async ngOnInit(): Promise<any> {
      if (isPlatformBrowser(this.platformId)) {
        this.comicalJS = require('../../../libs/comicaljs')
        this.Bubble = this.comicalJS.Bubble;
        this.Comical = this.comicalJS.Comical;
        
      }
    }

    override ngAfterViewInit(): void {  
      super.ngAfterViewInit()

      this.createBubble();

      this.keepRatio = false
      this.onMoveableAssetSet.emit(this.bubbleAsset.nativeElement)

      this.toolbarService.assetPropertyUpdatedSubject.asObservable().pipe(takeUntilDestroyed(this.destroyRef)).subscribe((d) => {
        this.updateBubble()
      })
    }

  
    override updateActiveAsset(): void {
      super.updateActiveAsset()
      if(this.data){
        this.toolbarService.editModeSubject.next({mode : EditMode.TEXT_BUBBLE, asset : this.data})
      }
      if(this.Comical)this.Comical.hideHandles()
    }

    override onResize(e: any): void {
      super.onResize(e)
      this.createBubble()
      this.updateBubble()
    }
    
    public createBubble(){
        //speech, shout, caption, pointedArcs, ellipse

        if(this.bubble){
          this.Comical.stopEditing()
          //Comical.deleteBubbleFromFamily(this.bubbleInner.nativeElement, this.bubbleAsset.nativeElement)
        }

        this.bubble = new this.Bubble(this.bubbleInner.nativeElement);
        this.Comical.startEditing([this.bubbleAsset.nativeElement]);

        this.updateBubble()
        this.Comical.hideHandles()

        //Comical.stopEditing()
    }

    private updateBubble(){
      if(this.bubble){
        this.bubble.setBorderWidth(this?.data?.data.borderWidth || 3)
      }

      const w = (this.data?.data.width || 0)
      const h = (this.data?.data.height || 0)
      const tx : number = this.data?.data.tailX || 0
      const ty : number = this.data?.data.tailY || 0
   

      //console.log((this.data?.data.width || 0) + 10, (this.data?.data.height || 0) + 10)
      this.bubble?.setBubbleSpec({
        version: "1.0",
        style: this.data?.data.bubbleStyle || 'speech',
        tails: [{ tipX: w + tx , tipY: h + ty , midpointX: w - 5, midpointY: h - 5}],
        level: 1,
        //outerBorderColor : this.data?.data.borderColor || 'black',
        backgroundColors : [this.data?.data.backgroundColor || 'white'],
    });
    this.Comical.update(this.bubbleAsset.nativeElement)
    this.Comical.hideHandles()
    }
}
