import { DIALOG_DATA } from '@angular/cdk/dialog';
import { AfterViewInit, Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

export enum InfoDialogType{
	TOC = "Privacy Policy & Terms of Service",
	ABOUT = "About DankTank"
}

@Component({
  selector: 'app-legal',
  standalone: true,
  imports: [MatProgressBarModule, MatDialogModule, MatFormFieldModule, FormsModule, MatInputModule, MatButtonModule],
  templateUrl: './legal.component.html',
  styleUrl: './legal.component.scss'
})
export class LegalComponent implements AfterViewInit {

  constructor(private sanitizer : DomSanitizer, @Inject(DIALOG_DATA) public type : InfoDialogType, private ref : MatDialogRef<LegalComponent>){

  }

  public readonly InfoDialogType = InfoDialogType

  public get sanitizedContent() : SafeHtml{
	return this.sanitizer.bypassSecurityTrustHtml(this.type == InfoDialogType.ABOUT ? this.aboutText : this.tocText)
  }

  private readonly aboutText : string = `
 		 <section class="about subtle-gradient-text">
            <p>Welcome to Dank Tank, your ultimate online meme maker.</p>
            <p>Our mission is to revolutionize meme creation by providing a user-friendly, flexible tool. Frustrated by the limitations of existing meme-making tools and the complexity of high-cost software like Photoshop, we set out to build a better solution.</p>
            <p>Dank Tank stands out by offering a streamlined meme-making experience that eliminates the hassle and expense. Our platform is designed to be quick to use, making it easy for anyone to create memes without the steep learning curve or high costs. Supported by minimal ads to cover server expenses, Dank Tank is accessible to everyone and perfect for anyone who loves to share a good laugh or make a statement.</p>
            <p>Explore Dank Tank today and see how simple and enjoyable meme creation can be!</p>
        </section>
  `

  private readonly tocText : string = `<div class="container">
        <section class="intro subtle-gradient-text">
            <p>Welcome to Dank Tank! This document outlines our Terms of Service and Privacy Policy. By accessing or using our website and services, you agree to comply with and be bound by the following terms and conditions and our privacy practices. Please read them carefully.</p>
        </section>
        <section class="terms subtle-gradient-text">
            <h2>1. Terms of Service</h2>
            <h3>1.1. Acceptance of Terms</h3>
            <p>By using Dank Tank, you agree to these Terms of Service, our Privacy Policy, and any additional terms that may apply to specific features. We may update these terms from time to time, and your continued use of our site constitutes acceptance of any changes.</p>
            <h3>1.2. Use of the Service</h3>
            <p><strong>Eligibility:</strong> You must be at least 13 years old to use our website.</p>
            <p><strong>Account Responsibilities:</strong> You are responsible for maintaining the confidentiality of your account credentials and for all activities under your account.</p>
            <p><strong>Prohibited Activities:</strong> You agree not to engage in illegal, defamatory, or fraudulent activities and not to interfere with or disrupt the site.</p>
            <h3>1.3. Content</h3>
            <p><strong>User-Generated Content:</strong> You retain ownership of any content you upload but grant us a non-exclusive, royalty-free, worldwide license to use, display, and distribute it.</p>
            <p><strong>Content Guidelines:</strong> Do not upload content that violates laws, intellectual property rights, or is offensive or inappropriate.</p>
            <h3>1.4. Use of Logos and Third-Party Content</h3>
            <p><strong>Brand Logos and Trademarks:</strong> Any logos, trademarks, and registered trademarks that appear in Dank Tank are the property of their respective owners. Their use in this app is intended for creative and humorous purposes only, and no endorsement or affiliation is implied. Users are responsible for ensuring their use of such content complies with applicable intellectual property laws.</p>
            <p><strong>User Responsibility:</strong> By using Dank Tank, you acknowledge that you are solely responsible for any content you create, including compliance with copyright and trademark laws. Dank Tank does not assume liability for the misuse of copyrighted or trademarked materials.</p>
            <h3>1.5. Basic and Pro Subscriptions</h3>
            <p><strong>Subscription Details:</strong> Basic and Pro subscriptions are managed through Stripe. We do not store payment details, which are handled by Stripe.</p>
            <p><strong>Billing and Cancellation:</strong> Subscription fees are billed in advance. You can cancel anytime, and refunds are provided at our discretion.</p>
            <h3>1.6. Intellectual Property</h3>
            <p>All intellectual property rights in Dank Tank, including the website and content, are owned by us or our licensors. Unauthorized use of our intellectual property is prohibited.</p>
            <h3>1.7. Disclaimers and Limitation of Liability</h3>
            <p>Dank Tank is provided "as-is." We disclaim all warranties and are not liable for any damages arising from your use of the site. Our total liability is limited to the amount you paid for the service.</p>
            <h3>1.8. Indemnification</h3>
            <p>You agree to indemnify and hold harmless Dank Tank and its affiliates from any claims or damages arising from your use of the site or violation of these terms.</p>
            <h3>1.9. Termination</h3>
            <p>We reserve the right to terminate or suspend your access for any reason, including violations of these Terms of Service.</p>
            <h3>1.10. Governing Law</h3>
            <p>These terms are governed by the laws of [Insert Jurisdiction], without regard to its conflict of laws principles.</p>
            <h3>1.11. DMCA Compliance and Handling</h3>
            <p><strong>DMCA Notice:</strong> Dank Tank respects the intellectual property rights of others. If you believe that your work has been copied in a way that constitutes copyright infringement, please provide us with a written notice in accordance with the Digital Millennium Copyright Act (DMCA).</p>
            <p><strong>Filing a Complaint:</strong> To file a DMCA complaint, you must send a written notification that includes:</p>
            <ul>
                <li>Your contact information, including a valid email address.</li>
                <li>A description of the copyrighted work that you claim has been infringed.</li>
                <li>A description of where the material that you claim is infringing is located on our site.</li>
                <li>A statement that you have a good faith belief that the use of the material in the manner complained of is not authorized by the copyright owner, its agent, or the law.</li>
                <li>A statement, made under penalty of perjury, that the information in the notification is accurate and that you are the copyright owner or are authorized to act on behalf of the owner.</li>
                <li>Your physical or electronic signature.</li>
            </ul>
            <p><strong>Counter-Notice:</strong> If your content has been removed due to a DMCA notice and you believe it was wrongly taken down, you may file a counter-notice. The counter-notice must include:</p>
            <ul>
                <li>Your contact information, including a valid email address.</li>
                <li>A statement that you consent to the jurisdiction of the federal district court in your area.</li>
                <li>A statement that you will accept service of process from the person who filed the original DMCA notice.</li>
                <li>A description of the material that has been removed and the location where it appeared before it was removed.</li>
                <li>A statement, made under penalty of perjury, that you have a good faith belief that the material was removed as a result of a mistake or misidentification.</li>
                <li>Your physical or electronic signature.</li>
            </ul>
        </section>
        <section class="privacy subtle-gradient-text">
            <h2>2. Privacy Policy</h2>
            <h3>2.1. Information We Collect</h3>
            <p><strong>Personal Information:</strong> We collect personal information if you subscribe to our Basic or Pro service or use third-party authentication (e.g., Google Auth). Payments are processed by Stripe, and we do not store payment details.</p>
            <p><strong>Uploaded Content:</strong> Images and memes you upload are stored on our servers and may be shared with other users.</p>
            <h3>2.2. Use of Information</h3>
            <p><strong>Service Provision:</strong> We use your information to manage your account, process payments, and provide customer support.</p>
            <p><strong>Content Usage:</strong> Uploaded content is used within the Dank Tank platform. You grant us a license to use your content for this purpose.</p>
            <p><strong>Communication:</strong> We may use your contact information to send updates or relevant information.</p>
            <h3>2.3. Data Protection and Security</h3>
            <p><strong>Security Measures:</strong> We implement reasonable security measures to protect your data but cannot guarantee absolute security.</p>
            <p><strong>Data Retention:</strong> We retain information as long as necessary to provide our services and fulfill purposes outlined in this policy.</p>
            <h3>2.4. Your Rights</h3>
            <p><strong>Access and Control:</strong> You have the right to access, correct, or delete your personal information. Contact us to exercise these rights.</p>
            <p><strong>GDPR and CCPA Compliance:</strong> If you are in the EU or California, you have additional rights under GDPR and CCPA. Contact us for details.</p>
            <h3>2.5. Third-Party Services</h3>
            <p>We use third-party services for authentication (e.g., Google Auth) and payment processing (e.g., Stripe). Review their privacy policies as we do not control their practices.</p>
            <h3>2.6. Changes to This Policy</h3>
            <p>We may update this Privacy Policy periodically. We will notify you of significant changes by updating this policy on our website.</p>
        </section>
        <section class="contact subtle-gradient-text">
            <h2>3. Contact Us</h2>
            <p>For any questions or concerns regarding these Terms of Service and Privacy Policy, please contact us at contact@danktank.app.</p>
        </section>
        <footer class="subtle-gradient-text">
            <p>Thank you for using Dank Tank!</p>
        </footer>
    </div>`

ngAfterViewInit(): void {
}

public close(){
	this.ref.close()
}

}
