<h2 mat-dialog-title class="dialog-title">Templates Popular with our Users</h2>
<mat-dialog-content class="center-me" ngSkipHydration>
  @if(!loaded){<mat-spinner
    style="margin: 0 auto !important; margin-top: 25px !important"
  ></mat-spinner
  >} @if(loaded){
  <mat-list style="width: 100% !important">
    @for (meme of popularMemes; track meme; let i = $index;) {
    <!-- <mat-menu #continueMenu class="centered-dropdown" style="margin-left: 20px !important; margin-right: 20px !important;">
                    <mat-label style="color: white;">If you continue you will lose changes</mat-label>
                    <br>
                    <button mat-button>
                        <mat-icon>cancel</mat-icon>
                        Cancel
                    </button>
                    <button mat-button (click)="$event.stopPropagation(); addGrid(meme)">
                        <mat-icon>check_circle</mat-icon>
                        Confirm
                    </button>
                </mat-menu>
            -->
    <mat-list-item
      class="pointer list-item"
      (click)="addGrid(meme)"
    >
      <div matListItemIcon class="icon-holder">
        @if(!images[meme.storageReference]){<mat-icon>pending</mat-icon>}
        @if(images[meme.storageReference] ==
        ImageLoadStatus.ERROR){<mat-icon>error</mat-icon>}
        @if(images[meme.storageReference] != ImageLoadStatus.ERROR){
        <img
          #img
          [style.position]="
            images[meme.storageReference] == ImageLoadStatus.LOADED
              ? 'static'
              : 'absolute'
          "
          (load)="
            images[meme.storageReference] = ImageLoadStatus.LOADED;
            img.style.opacity = '1'
          "
          (error)="images[meme.storageReference] = ImageLoadStatus.ERROR"
          class="list-icon fade-img"
          [src]="uriFromSticker(meme)"
          [appLazyLoad]="uriFromSticker(meme)"
        />
        }
      </div>
      <div class="info-holder">
        <mat-chip-set class="meme-ranking">
          <mat-chip color="primary" class="chip">
            #{{ i + 1 }}
            <mat-icon class="ranking-icon" svgIcon="medal"></mat-icon>
          </mat-chip>
        </mat-chip-set>
        <div class="meme-name subtle-gradient-text">
          {{ gettickerFromMeme(meme)?.name }}
        </div>
        <div class="meme-button">
          <!--
                                <button mat-button #continueTrigger="matMenuTrigger"
                                [style.display]="(data.gridSection?.collectMedia()?.length || 0) > 0 || (data.gridSection?.assets?.length || 0) > 0 ? null : 'none' "
                                [matMenuTriggerFor]="continueMenu"
                                >
                                Start with this meme
                                </button>
                            -->
          <button mat-button (click)="addGrid(meme)">
            Start with this meme
          </button>
        </div>
      </div>
    </mat-list-item>
    @if(i != popularMemes.length){<mat-divider></mat-divider>} }
  </mat-list>
  }
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cancel</button>
</mat-dialog-actions>
