import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, catchError, map, of, take, tap, throwError } from 'rxjs';
import { APIName, HTTPMethods, ServiceLookupService } from './service-lookup.service';


export interface StickerServiceResponse {
  data?: StickerPayload | null;
  error?: any; // Holds error object in case of failure
}

export interface StickerPayload {
  collection : StickerPack[]
}

export interface StickerPack {
  name: string;
  description: string;
  stickers: Sticker[];
  highContrast ?: boolean
}

export interface Sticker {
  name:             string;
  storageReference: string;
  tags?:             string;
  template:         Template;
  //this is only used in popular memes, theres got to be a better way
  type : 'gif' | 'meme';
}

export interface Template {
  width : number;
  height : number;
  forehead : number
  aspectRatio?: string;
  viewport?: Viewport;
  assets?: TemplateAsset[];
}

export interface Viewport{
  width : number
  height : number
} 

export interface TemplateAsset {
  value: string;
  props: Prop[];
}

export interface Prop {
  prop:  string;
  value: number | string;
}


@Injectable({
  providedIn: 'root'
})
export class StickerService {
  public stickerPackDataSubject = new Subject<StickerServiceResponse>();

  public memePackDataSubject = new Subject<StickerServiceResponse>();
  
  public emojiPackDataSubject = new Subject<StickerServiceResponse>();

  public gifPackDataSubject = new Subject<StickerServiceResponse>();

  constructor(private http: HttpClient, private serviceLookup : ServiceLookupService) { }


  getStickerPackData(): Observable<StickerServiceResponse> {
    const filePath = this.serviceLookup.lookup(APIName.GET_STICKERS, HTTPMethods.GET)

    this.http.get<StickerPayload>(filePath)
      .pipe(
        take(1),
        map(data => ({ data })), // Map successful data to response object
        catchError(error => of(this.transformError(error))) // Transform error before emitting
      )
      .subscribe(response => {
        this.stickerPackDataSubject.next(response)
      }); // Emit response object

      return this.stickerPackDataSubject.asObservable();
  }

  getMemePackData(): Observable<StickerServiceResponse> {
    const filePath = this.serviceLookup.lookup(APIName.GET_MEMES, HTTPMethods.GET)

    this.http.get<StickerPayload>(filePath)
      .pipe(
        take(1),
        map(data => {
          //console.log('Data received from HTTP request:', data);
          return { data };
        }), // Map successful data to response object
        catchError(error => {
          //console.error('Error occurred:', error);
          return of(this.transformError(error)); // Transform error before emitting
        })
      )
      .subscribe(response => {
        //console.log('Emitting response to subject:', response);
        this.memePackDataSubject.next(response);
      }); // Emit response object

    return this.memePackDataSubject.asObservable();
  }

  getGifPackData(): Observable<StickerServiceResponse> {
    const filePath = this.serviceLookup.lookup(APIName.GET_GIFS, HTTPMethods.GET)

    this.http.get<StickerPayload>(filePath)
      .pipe(
        take(1),
        map(data => {
          //console.log('Data received from HTTP request:', data);
          return { data };
        }), // Map successful data to response object
        catchError(error => {
          //console.error('Error occurred:', error);
          return of(this.transformError(error)); // Transform error before emitting
        })
      )
      .subscribe(response => {
        //console.log('Emitting response to subject:', response);
        this.gifPackDataSubject.next(response);
      }); // Emit response object

    return this.gifPackDataSubject.asObservable();
  }

  getEmojiPackData(): Observable<StickerServiceResponse> {
    const filePath = this.serviceLookup.lookup(APIName.GET_EMOJIS, HTTPMethods.GET)

    this.http.get<StickerPayload>(filePath)
      .pipe(
        take(1),
        map(data => ({ data })), // Map successful data to response object
        catchError(error => of(this.transformError(error))) // Transform error before emitting
      )
      .subscribe(response => {
        this.emojiPackDataSubject.next(response)
      }); // Emit response object

      return this.emojiPackDataSubject.asObservable();
  }

  getShapePackData(): Observable<StickerServiceResponse> {
    const filePath = this.serviceLookup.lookup(APIName.GET_SHAPES, HTTPMethods.GET)

    this.http.get<StickerPayload>(filePath)
      .pipe(
        take(1),
        map(data => ({ data })), // Map successful data to response object
        catchError(error => of(this.transformError(error))) // Transform error before emitting
      )
      .subscribe(response => {
        this.emojiPackDataSubject.next(response)
      }); // Emit response object

      return this.emojiPackDataSubject.asObservable();
  }

  private transformError(error: any): StickerServiceResponse {
    return { error };
  }}
