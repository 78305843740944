import { Injectable } from '@angular/core';
import { Asset, GridSection } from '../vo/GridSection';
import { BehaviorSubject, Subject } from 'rxjs';

export enum EditMode{
  BASIC = 1,
  IMAGE = 2,
  TEXT =3,
  STICKER = 4,
  TEXT_BUBBLE = 5,


  //Secondary
  COLORS = 100,
}

export interface PropChange{
  prop : string
  value : any
}

export interface EditModeData{
  editing ?: GridSection
  asset ?: Asset
  mode : EditMode
}

export interface ImageEditedData{
  imageContent : string
}

@Injectable({
  providedIn: 'root'
})
export class ToolbarService {

  public editModeSubject = new BehaviorSubject<EditModeData>({mode : EditMode.BASIC})
  public imageEditedSubject = new Subject<ImageEditedData>()
  public assetPropertyUpdatedSubject = new Subject<PropChange>()

  constructor() { }

  
}
