<h2 mat-dialog-title class="dialog-title">{{loaded ? 'Download your Image' : 'Preparing your image'}}</h2>
<mat-dialog-content style="text-align: center">

    <img #downloadImg (load)="downloadImg.style.opacity = '1'" class="img fade-img" style="margin: 0 auto !important; margin-top: 15px !important;" (load)="loaded = true" [src]="data?.downloadURL || getUriFromImage(uploadImage, true)">

    @if(!loaded && data && data.determinate){<mat-spinner mode="determinate" [value]="data.completion"
        style="margin: 0 auto !important;"></mat-spinner>}
    @if((!loaded && data && !data.determinate) || (!loaded && !data)){<mat-spinner mode="indeterminate"
        style="margin: 0 auto !important;"></mat-spinner>}

    @if(loaded){
        @if(authService.subscriptionUserSubject.value == null || (authService.subscriptionUserSubject.value.data?.subscription?.type == SubscriptionType.NONE && (authService.subscriptionUserSubject.value.data?.subscription?.status != SubscriptionStatus.ACTIVE || authService.subscriptionUserSubject.value.data?.subscription?.status != SubscriptionStatus.FREE))){
            @if(data){
                <button mat-fab extended style="width: 100% !important;" (click)="this.authService.authThen(regenerate, this, this.paymentService.getAllPaid())">
                    <mat-icon>branding_watermark</mat-icon>
                    Remove Watermark
                </button>
            }
        }
        @if(uploading){
            <mat-progress-bar style="margin-top: 25px;" mode="indeterminate"></mat-progress-bar>
        }
        @if(uploadImage){
            <mat-divider style="margin-top: 25px;"></mat-divider>
            <br>
            <share-buttons [theme]="'default'" style="margin: 0 auto !important;"
                [include]="['facebook','x','linkedin','reddit','email','copy']"
                [show]="11" [image]="getUriFromImage(uploadImage, true)" [url]="getUriFromImage(uploadImage, true)">

            </share-buttons>
        }
    }


</mat-dialog-content>
@if(loaded){
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close (click)="cancel()">Keep Editing</button>
    <button mat-button (click)="$event.stopImmediatePropagation()" [matMenuTriggerFor]="downloadMenu">Download</button>
</mat-dialog-actions>
}

<mat-menu #downloadMenu xPosition="after">
    <button mat-menu-item (click)="download()">
        <mat-icon>download</mat-icon>
        <span class="subtle-gradient-text">Download to Device</span>
    </button>
    @if(canDownload){
        <mat-divider></mat-divider>
            <button mat-menu-item (click)="authService.authThen(saveToAccount, this, this.paymentService.getAllPaid())" [disabled]="uploadImage">
                <mat-icon>share</mat-icon>
                <span class="subtle-gradient-text">Share to Socials</span>
            </button>
    }
    <!--
    @if(canDownload){
    <mat-divider></mat-divider>
        <button mat-menu-item (click)="authService.authThen(downloadAndSave, this, this.paymentService.getAllPaid())" [disabled]="uploadImage">
            <mat-icon>share</mat-icon>
            <span class="subtle-gradient-text">Download and Share to Socials</span>
        </button>
    }
    -->
</mat-menu>