import { TextAssetComponent } from "../component/asset/text-asset/text-asset.component";
import { FontService } from "../service/font.service";
import { ToolbarService } from "../service/toolbar.service";
import { Asset, GridSection } from "../vo/GridSection";


export function parseGridSection(data : any, toolbarService : ToolbarService, fontService : FontService):GridSection{
    const gridSection = new GridSection(data['orientation']);
    if(data['_children']){
        (data['_children'] as Array<any>).forEach(element => {
            const childGrid : GridSection = parseGridSection(element, toolbarService, fontService)
            gridSection.addChild(childGrid)
        });
    }
    for (const key in data) {
        if (Reflect.has(data, key)) {
            switch(key){
                case "_allAssets":
                    gridSection.toLoad = gridSection.loaded = 0
                    const assetCollectionJson : any = data[key]
                    assetCollectionJson.forEach((assetJson : any) => {
                        let asset : Asset = gridSection.addAsset(assetJson.type, toolbarService)
                        asset = parseGeneric<Asset>(assetJson, asset)
                        console.log()
                    });
                    break
                case 'toLoad':
                case 'loaded':
                case '_loaded':
                    break;
                case '_children':
                    break
                default:
                    const value = (data as any)[key];
                    (gridSection as any)[key] = value; 
                    break
            }
        }
    }
    gridSection.reapplyCyclicals()
    gridSection.assets.forEach((asset : Asset) => {
        if(asset.data.fontFamily && asset.data.fontFamily.length > 0){
          fontService.loadFont({name : asset.data.fontFamily})
        }
    })
    return gridSection
}

function parseGeneric<T>(data : any, toReflect : T, ignoreProps ?: string[]) : T{
    for (const key in data) {
        if (Reflect.has(data, key)) {
            if(!ignoreProps || ignoreProps && !ignoreProps.includes(key as string)){
                const value : any = (data as any)[key];
                (toReflect as any)[key] = value 
            }
        }
    }
    return toReflect
}
