import { ApplicationRef, inject, Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject, catchError, delay, delayWhen, first, from, fromEvent, map, mergeMap, Observable, of, retry, retryWhen, Subject, take, tap, throwError, timeout, timer } from 'rxjs';
//import { AuthChangeEvent, AuthResponse, AuthSession, SupabaseClient, User } from '@supabase/supabase-js';
import { environment } from '../../environments/environment';
import { isPlatformBrowser } from '@angular/common';
import { createBrowserClient } from '@supabase/ssr'
import { AuthResponse, AuthSession, AuthTokenResponse, OAuthResponse, Session, SupabaseClient, User, UserResponse } from '@supabase/supabase-js';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DeviceDetectorService } from './device-detector.service';
import { LoginComponent } from '../component/login/login.component';
import { APIName, HTTPMethods, ServiceLookupService } from './service-lookup.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UpgradeComponent } from '../component/upgrade/upgrade.component';
import { AuthenticatingComponent } from '../component/authenticating/authenticating.component';
import { PaymentService, Subscription, SubscriptionStatus, SubscriptionType } from './payment.service';
import { ServiceResult } from './result';

export interface SubscriptionUserResponse {
  data?: SubscriptionUser | null;
  error?: any | null;
}

export interface SubscriptionUser {
  id: string,
  email: string,
  subscription: Subscription
}

export interface SubscriptionUserPayload {
}

@Injectable({
  providedIn: 'root',
})
export class DankTankAuthService {
  private supabase?: SupabaseClient;
  //private _session?: AuthSession | null = null;
  private mustAuthSubject: Subject<boolean> = new Subject();

  //use behavior subjects so subscribers always get the last result, that way we can use these in interceptors to see if someones logged in, kthxbye
  //thers convenient methods for these to get them as observers, but allow direct access so you can get the current value
  public sessionSubject: BehaviorSubject<Session | null> = new BehaviorSubject<Session | null>(null)
  public userSubject: BehaviorSubject<User | null> = new BehaviorSubject<User | null>(null)
  public subscriptionUserSubject: BehaviorSubject<ServiceResult<SubscriptionUser> | null> = new BehaviorSubject<ServiceResult<SubscriptionUser> | null>(null)

  private _fakeSubscription ?: Subscription
  public set fakeSubscription(subscription : Subscription){
    this._fakeSubscription = subscription
    const subscriptionUser : SubscriptionUser | undefined = this.subscriptionUserSubject.value?.data
    if(subscriptionUser){
      subscriptionUser.subscription = subscription
      this.subscriptionUserSubject.next({data : subscriptionUser})
    }
  }

  public stableSubject : BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
  constructor(appRef: ApplicationRef, @Inject(PLATFORM_ID) private platformId: Object, private dialog: MatDialog, private deviceDetector: DeviceDetectorService, private serviceLookup: ServiceLookupService, private http: HttpClient) {
    if (isPlatformBrowser(platformId)) {
      appRef
        .isStable.pipe(first((isStable: boolean) => isStable))
        .subscribe(() => {
          this.stableSubject.next(true)
          this.subscriptionUserSubject.next(null)
          if (typeof window === 'undefined' || createBrowserClient === undefined) return
          this.supabase = createBrowserClient(environment.supabaseUrl, environment.supabaseKey)
          if (this.guardedAction && this.scope && this.subscription) this.authThen(this.guardedAction, this.scope, this.subscription, this.authDismissed)

          //emit on our session subject
          from(this.supabase.auth.getSession()).pipe(map(response => response.data.session)).subscribe((value: AuthSession | null) => {
            this.sessionSubject?.next(value)
          })

          //emit on our user subject
          from(this.supabase.auth.getUser()).pipe(map(response => response.data.user)).subscribe((value: User | null) => {
            this.userSubject?.next(value)
          })

          this.subscriptionUserSubject.asObservable().subscribe((value: SubscriptionUserResponse | null) => {
            if (value && !value.error) {
              //delay the calling until the next has cmopleted
              setTimeout(() => {
                if (this.guardedAction && this.scope && this.subscription) this.authThen(this.guardedAction, this.scope, this.subscription, this.authDismissed)
              }, 100);
            }
          })

          this.getSession()?.subscribe((value: Session | null) => {
            if (!value) return
            this.getSubscriptionUser()
            //if(this.guardedAction && this.scope)this.authThen(this.guardedAction, this.scope)
          })
        })
    }
  }

  private openCenteredPopup(url: string, name: string, width: number, height: number): WindowProxy | null {
    const screenWidth = window.innerWidth
    const screenHeight = window.innerHeight

    const left = (screenWidth / 2) - (width / 2)
    const top = (screenHeight / 2) - (height / 2)

    return window.open(
      url,
      name,
      `width=${width},height=${height},top=${top},left=${left}`
    )
  }

  public loginWithProvider(provider: 'google' | 'apple' | 'facebook') {
    this.hideAuthenticating()
    this.showAuthenticating()
    const w: WindowProxy | null = this.openCenteredPopup('', 'Login', 400, 500)
    this._loginWithProvider(provider)?.pipe(take(1)).subscribe((value: OAuthResponse) => {
      if (value && value.data && value.data.url) {
        if (w) w.location = value.data.url

        const channel = new BroadcastChannel("callback-channel")

  
        //this repeatedly checks if the window is closed
        const t = setInterval(() => {
          const closed = w?.closed
          if (closed) {
            //this.hideAuthenticating()
            //we want to close it and this should count as a user initiated action, so we don't use the regular close function
            if (this.currentDialog) this.currentDialog.close()
            clearInterval(t)
          }
        }, 1000);

        fromEvent<MessageEvent>(channel, 'message').pipe(take(1), timeout(60000)).subscribe((e: MessageEvent) => {
          if (e.origin !== window.location.origin) return

          clearTimeout(t)

          // get authResultCode from popup
          const code = e.data?.authResultCode
          if (!code) return

          const p: Promise<AuthTokenResponse> | undefined = this.supabase?.auth.exchangeCodeForSession(code)
          if (p) from(p).pipe(take(1)).subscribe((value: AuthTokenResponse) => {
            this.sessionSubject?.next(value.data.session)
            this.userSubject?.next(value.data.user)
          })
        })
      } else {
        // probably an error
        console.log(value?.error?.toString())
      }
    })
  }

  private _loginWithProvider(provider: 'google' | 'apple' | 'facebook'): Observable<OAuthResponse> | undefined {
    const origin = location.origin;
    const p: Promise<OAuthResponse> | undefined = this.supabase?.auth.signInWithOAuth({
      provider, options: {
        skipBrowserRedirect: true, redirectTo: `${origin}/callback.html`,
        queryParams: { prompt: "select_account" },
      }
    })
    return p ? from(p) : undefined
  }

  signup(email: string, password: string): Observable<AuthResponse> | undefined {
    const p: Promise<AuthResponse> | undefined = this.supabase?.auth.signUp({ email, password });
    return p ? from(p) : undefined
  }


  login(): void {
    // Uncomment and implement login logic
    // this.supabase?.auth.signInWithOAuth({});
  }

  logout(): void {
    const p: Promise<any> | undefined = this.supabase?.auth.signOut()
    if (p) from(p).pipe(take(1)).subscribe(() => {
      this.userSubject.next(null)
      this.sessionSubject.next(null)
      this.subscriptionUserSubject.next(null)
    })
  }

  getSession(): Observable<Session | null> | undefined {
    return this.sessionSubject?.asObservable()
  }

  getUser(): Observable<User | null> | undefined {
    return this.userSubject?.asObservable()
  }

  sendMustAuth() {
    this.mustAuthSubject.next(true);
  }

  subscribeToMustAuth(): Observable<boolean> {
    return this.mustAuthSubject.asObservable();
  }

  private guardedAction?: () => void
  private scope?: any
  private subscription?: SubscriptionType[]
  private authDismissed?: () => void
  authThen(guardedAction: () => void, scope: any, subscription: SubscriptionType[], authDismissed?: () => void) {
    this.guardedAction = guardedAction
    this.scope = scope
    this.subscription = subscription
    this.authDismissed = authDismissed
    if(!this.supabase?.auth)return
    const user: User | null = this.userSubject.value
    if (user && user.user_metadata) {
      const subscriptionUser: SubscriptionUser | null | undefined = this.subscriptionUserSubject.value?.data
      if (subscriptionUser) {
        this.hideAuthenticating()
        if ((subscriptionUser.subscription && subscription.includes(subscriptionUser.subscription.type) && (subscriptionUser.subscription.status == SubscriptionStatus.ACTIVE || subscriptionUser.subscription.status == SubscriptionStatus.FREE)) || subscription.length == 0) {
          guardedAction.call(scope)
        } else {
          this.currentDialog = this.dialog.open(UpgradeComponent, { width: 'fit-content', maxHeight: '90vh', autoFocus: false, data: subscriptionUser })
          this.currentDialog.afterClosed().pipe(take(1)).subscribe((val : any) => {
            if(this.authDismissed && val != 'closed_by_code'){
              this.authDismissed.call(this.scope)
            }
          })
          this.currentDialog.componentInstance.allowedTiers = subscription
        }
      } else {
        this.showAuthenticating()
        this.getSubscriptionUser()
      }
    } else {
      this.currentDialog = this.dialog.open(LoginComponent, { width: this.deviceDetector.isPortrait() ? 'fit-content' : 'fit-content' })
      this.currentDialog.afterClosed().pipe(take(1)).subscribe((val : any) => {
        if(this.authDismissed && val != 'closed_by_code'){
          this.authDismissed.call(this.scope)
        }
      })
    }
  }

  getSubscriptionUser(): Observable<SubscriptionUserResponse | null> | undefined {
    if (!isPlatformBrowser(this.platformId)) return;
    const uri = this.serviceLookup.lookup(APIName.GET_SUBSCRIPTION_USER, HTTPMethods.GET);
    const token = this.sessionSubject.value?.access_token;

    if (!token) return undefined;

    const headers = new HttpHeaders({
      Authorization: `Bearer ${token}`
    });

    const retryCount = 5;
    const initialDelay = 1000; // 1 second in milliseconds
    const backoffFactor = 2; // increase delay by 100% each retry

    this.http.get<ServiceResult<SubscriptionUser>>(uri, { headers })
      .pipe(
        retry({
          count: retryCount,
          delay: (error, attempt) => {
            const delayTime = initialDelay * Math.pow(backoffFactor, attempt);
            console.log(`Retrying request in ${delayTime}ms (attempt ${attempt + 1} of ${retryCount})`);
            return timer(delayTime);
          }
        }),
        map((data: any) => {
          return {
            data: {
              id: data.id,
              email: data.email,
              name: data.name,
              subscription: this._fakeSubscription || PaymentService.getSubscription(data.priceFriendly, data.subscriptionStatus)
            }
          }
        }),
        tap(value => this.subscriptionUserSubject.next(value)),
        catchError(error => of(this.transformError<SubscriptionUser>(error)))
      )
      .pipe(take(1))
      .subscribe();

    return this.subscriptionUserSubject.asObservable();
}

  reauth() {
    //it may just be for a second but we need to reauth
    this.showAuthenticating()
    //null it out so its forced to try and get it again
    this.subscriptionUserSubject.next(null)
    //this will force it to get the user again
    this.getSubscriptionUser()
  }

  private transformError<T>(error: any): ServiceResult<T> {
    return { error };
  }


  private currentDialog?: MatDialogRef<any>
  private showAuthenticating() {
    this.hideAuthenticating()
    this.currentDialog = this.dialog.open(AuthenticatingComponent, { disableClose: true, width: '75px', height: '150px' })
    this.currentDialog.afterClosed().pipe(take(1)).subscribe((val : any) => {
      if(this.authDismissed && val != 'closed_by_code'){
        this.authDismissed.call(this.scope)
      }
    })
  }

  private hideAuthenticating() {
    if (this.currentDialog) this.currentDialog.close('closed_by_code')
  }
}