<section (click)="$event.stopPropagation()" class="checkbox-section">
    <span>
        <mat-checkbox [checked]="hasAllStyles()"
            [indeterminate]="hasAtLeastOneStyle() && !hasAllStyles()" (change)="addAllStyles()">
            <span class="subtle-gradient-text">Use all Text Styles</span>
        </mat-checkbox>
    </span>
    <span>
        <ul>
            @for (ts of textStyles; track ts; let i = $index) {
            <li>
                <mat-checkbox [checked]="hasStyle(ts)"
                    (change)="addOrRemoveTextStyle($event.checked, ts)">
                    <span class="subtle-gradient-text">{{ts.name}}</span>
                </mat-checkbox>
            </li>
            }
        </ul>
    </span>
</section>
<section (click)="$event.stopPropagation()" class="radio-section" auto-animate>
    @if(hasAtLeastOneStyle()){
    <mat-divider></mat-divider>
    <mat-radio-group class="radio-group"
        [ngModel]="appService.gridSection?.activeAsset?.data?.textDecorationStyle?.toLowerCase()"
        (change)="updateValue($event.value)">
        @for (aug of textStylesAugmentors; track aug) {
        <mat-radio-button class="radio"
            [value]="aug.name?.toLowerCase()">
            <span class="subtle-gradient-text">{{aug.name}}</span>
        </mat-radio-button>
        }
    </mat-radio-group>
    }
</section>