<mat-toolbar color="primary" class="toolbar" >
    <mat-toolbar-row class="top-row" [style.height.px]="collapsed ? 0 : null">
            <app-app-menu #appMenu></app-app-menu>
        <div class="flex-expand"></div>
        <img alt="DankTank Logo" (click)="goHome()" #logoImg class="fade-img pointer main-logo" (load)="logoImg.style.opacity = '1'" width="65px" height="65px" src="https://hhopznfvcfwimeaxsktv.supabase.co/storage/v1/object/public/media.danktank.cloud/logos/logo-200_x_200.png">
        <div class="flex-expand"></div>
        <app-logged-in-user></app-logged-in-user>
    </mat-toolbar-row>
    <mat-divider></mat-divider>
        <mat-toolbar-row class="bottom-row" [style.height.px]="collapsed ? 0 : null" [style.display]="isLandingToolbar ? 'none' : null">
            @if(historyService.history.length > 0){
                <mat-icon (click)="historyService.undo()" class="pointer fade-in"
                matTooltip="Undo ({{historyService.history.length}})" matTooltipPosition="below">
                undo
            </mat-icon>   
            }
            <div class="flex-expand"></div>
            @if(appService.gridSection){
                <div class="info-holder fade-in">
                    <h1>{{appService.gridSection.name || 'undefined'}}</h1>
                    @if((appService.gridSection.rootGridSectionWidth || 0) == 0 || (appService.gridSection.rootGridSectionHeight || 0) > 0){
                        <div style="font-size: 12px;">{{Math.round(appService.gridSection.rootGridSectionWidth || 0)}} x {{Math.round(appService.gridSection.rootGridSectionHeight || 0)}}</div>
                    }
                </div>
            }
            <div class="flex-expand"></div>
            <app-save-and-generate (isSaving)="appMenu.saving = $event" (savedMemesAmount)="appMenu.savedMemes = $event"></app-save-and-generate>
        </mat-toolbar-row>
        <mat-toolbar-row class="expand-row pointer" (click)="collapsed = !collapsed" [style.display]="isLandingToolbar ? 'none' : null">
            <div class="flex-expand"></div>
            <mat-icon 
                class="pointer rotate-icon" 
                [class.rotated]="!collapsed" 
                >
                expand_more
            </mat-icon>
            <div class="flex-expand"></div>
        </mat-toolbar-row>
</mat-toolbar>