// src/app/services/device-detector.service.ts
import { Injectable, Inject, PLATFORM_ID, NgZone } from '@angular/core';
import { BehaviorSubject, fromEvent, map, Observable, startWith } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';

export enum AdSize {
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
  LARGE = 'LARGE'
}

@Injectable({
  providedIn: 'root'
})
export class DeviceDetectorService {
  private screenWidth$: BehaviorSubject<number>;

  constructor(@Inject(PLATFORM_ID) private platformId: any, private zone : NgZone) { 
    // Initialize BehaviorSubject based on the platform
    this.screenWidth$ = new BehaviorSubject<number>(isPlatformBrowser(this.platformId) ? window.innerWidth : 0);

    if (isPlatformBrowser(this.platformId)) {
      fromEvent(window, 'resize')
        .pipe(map((event: Event) => (event.target as Window).innerWidth))
        .subscribe(this.screenWidth$);
    }
  }

  public isTouchOnlyDevice(): boolean {
    if (isPlatformBrowser(this.platformId)) {
      // Check if the device has touch points
      const hasTouchPoints = navigator.maxTouchPoints > 0 || (navigator as any).msMaxTouchPoints > 0;
      
      // Check if the primary input is touch
      const primaryInputIsTouch = window.matchMedia('(pointer: coarse)').matches;
  
      // Device is considered touch-only if it has touch points and primary input is touch
      return hasTouchPoints && primaryInputIsTouch;
    }
    return false; // default value for non-browser environments
  }

  public isUsingWebKit(): boolean {
    return /AppleWebKit/.test(navigator.userAgent)
  }

  public isPortrait():boolean{
    if(isPlatformBrowser(this.platformId)){
      return window.matchMedia('(orientation: portrait)').matches
    }
    return false
  }

  getScreenWidth(): Observable<number> {
    return this.screenWidth$.asObservable();
  }

  getAdSize(): Observable<AdSize> {
    return this.getScreenWidth().pipe(
      startWith(isPlatformBrowser(this.platformId) ? window.innerWidth : 0),
      map(width => {
        if (width < 470) {
          return AdSize.SMALL;
        } else if (width >= 470 && width < 730) {
          return AdSize.MEDIUM;
        } else {
          return AdSize.LARGE;
        }
      })
    );
  }

  lessThanHeight(height: number): boolean {
    if(isPlatformBrowser(this.platformId)){
        const currentHeight = window.innerHeight;
        return currentHeight < height;
    }else{
      true
    }
    return true
  }
}