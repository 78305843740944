<h2 mat-dialog-title class="dialog-title">Drafts</h2>
<mat-dialog-content class="" ngSkipHydration>
    @if(!loaded){<mat-spinner></mat-spinner>}
    @if(loaded){
        <mat-list style="width: 100% !important;" auto-animate>
            @if(savedMemes?.length == 0){
                <strong><p class="subtle-gradient-text">You haven't saved any drafts yet</p></strong>
            }
            @for (savedMeme of savedMemes; track savedMeme; let idx = $index;) {
                <mat-list-item class="pointer list-item" auto-animate>
                    <!--<mat-icon matListItemIcon class="icon">edit_square</mat-icon>-->
                    <div class="info-holder">
                        <div class="meme-name subtle-gradient-text">{{savedMeme.name}}</div>
                        <div class="meme-button">
                            <button mat-mini-fab class="toolbar-button" matTooltip="Delete" matTooltipPosition="right" (click)="deleteMeme(savedMeme)">
                                <mat-icon>delete</mat-icon>
                            </button>
                            @if((data.gridSection?.collectMedia()?.length || 0) == 0 && (data.gridSection?.assets?.length || 0) == 0 ){
                                <button mat-mini-fab style="margin-left: 5px;" class="toolbar-button" matTooltip="Open" matTooltipPosition="right" (click)="openMeme(savedMeme)">
                                    <mat-icon>open_in_new</mat-icon>
                                </button>
                            }
                            @if((data.gridSection?.collectMedia()?.length || 0) > 0 || (data.gridSection?.assets?.length || 0) > 0 ){
                                <button mat-mini-fab style="margin-left: 5px;"
                                [matMenuTriggerFor]="continueMenu"
                                >
                                <mat-icon>open_in_new</mat-icon>
                                    <mat-menu #continueMenu class="centered-dropdown">
                                        <mat-label style="color: white;">If you continue you will lose changes</mat-label>
                                        <br>
                                        <button mat-button>
                                            <mat-icon>cancel</mat-icon>
                                            Cancel
                                        </button>
                                        <button mat-button (click)="$event.stopPropagation(); openMeme(savedMeme)">
                                            <mat-icon>check_circle</mat-icon>
                                            Confirm
                                        </button>
                                    </mat-menu>
                                </button>
                            }
                        </div>
                    </div> 
                </mat-list-item>
                @if(idx != (savedMemes?.length || 0) - 1){
                    <mat-divider style="margin-top: 10px; margin-bottom: 10px;"></mat-divider>
                }
            }
        </mat-list>
    }
</mat-dialog-content> 