import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, catchError, map, of, take, tap, throwError } from 'rxjs';
import { APIName, HTTPMethods, ServiceLookupService } from './service-lookup.service';

export interface SEODataResponse {
  data?: SEOPayload | null;
  error?: any; // Holds error object in case of failure
}

export interface SEOPayload {
  default : SeoData
}

export interface SEOServiceResponse {
  data?: SeoData | null;
  error?: any; // Holds error object in case of failure
}

export interface SeoData {
  title: string;
  description: string;
  keywords: string;
  image: string;
  url: string;
  logo: string
}

@Injectable({
  providedIn: 'root'
})
export class SEOService {

  public seoDataSubject = new Subject<SEODataResponse>();

  constructor(private http: HttpClient, private serviceLookup : ServiceLookupService) { 
    //this.getSEOData()
  }

  getSEOData(): Observable<SEODataResponse> {
    const filePath = this.serviceLookup.lookup(APIName.GET_SEO, HTTPMethods.GET)

      this.http.get<SEOPayload>(filePath)
      .pipe(
        take(1),
        map(data => ({ data })), 
        catchError(error => of(this.transformError(error))) 
      )
      .subscribe(response => {
        console.log("LOADED SEO")
        this.seoDataSubject.next(response)
      }); // Emit response object

      return this.seoDataSubject.asObservable();
  }

  private transformError(error: any): SEODataResponse {
    return { error };
  }
}
