import { CanDeactivateFn } from '@angular/router';
import { Subject, take } from 'rxjs';
import { NavigatorService } from '../service/navigator.service';
import { inject } from '@angular/core';

export const backAndForwardGuard: CanDeactivateFn<unknown> = (component, currentRoute, currentState, nextState) => {
  const deactivateSubject = new Subject<boolean>();
  const navigator: NavigatorService = inject(NavigatorService);
  navigator.shouldPreventNavigation().pipe(take(1)).subscribe((value : boolean) => {
    deactivateSubject.next(!value)
    deactivateSubject.complete()
  })
  return deactivateSubject
};
