import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HapticService {

  constructor(@Inject(PLATFORM_ID) private platformId: Object,){
    
  }

  public vibrate(){
    if(isPlatformBrowser(this.platformId)){
      if ("vibrate" in navigator) {
        navigator.vibrate([200]);
      } else {
        //console.log("Vibration API not supported.");
      }
    }
  }
}
