import { ChangeDetectorRef, Component, DestroyRef, EventEmitter, Output, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule, MatMenuTrigger } from '@angular/material/menu';
import { AppService } from '../../service/app.service';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DankTankAuthService } from '../../service/dank-tank-auth.service';
import { PaymentService } from '../../service/payment.service';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ToastService } from '../../service/toast.service';
import { GridSection, MediaType, Mode } from '../../vo/GridSection';
import { ImageGenerationInfo, ImageGeneratorService } from '../../service/image-generator.service';
import { take } from 'rxjs';
import { DownloadImageComponent } from '../download-image/download-image.component';
import { MatDialog } from '@angular/material/dialog';
import { LocalSaveService } from '../../service/local-save.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';


@Component({
  selector: 'app-save-and-generate',
  standalone: true,
  imports: [MatTooltipModule, MatIconModule, MatDividerModule, MatButtonModule, MatMenuModule, FormsModule, MatFormFieldModule, MatInputModule, MatProgressSpinnerModule],
  templateUrl: './save-and-generate.component.html',
  styleUrl: './save-and-generate.component.scss'
})
export class SaveAndGenerateComponent {

  public showSaveInput : Boolean = false
  public saving : boolean = false

  @Output() public isSaving: EventEmitter<boolean> = new EventEmitter();
  @Output() public savedMemesAmount: EventEmitter<number> = new EventEmitter();



  constructor(private destroyRef : DestroyRef, private cdr : ChangeDetectorRef, private localSaveService : LocalSaveService, private dialog : MatDialog, private imageGenerator : ImageGeneratorService, private toast : ToastService,  public appService : AppService, public authService : DankTankAuthService, public paymentService : PaymentService){
    this.getSaveMemesCount()
  }

  @ViewChild('saveAndShareButton') saveAndShareButton!: MatMenuTrigger
  @ViewChild('menuTrigger') menuTrigger?: MatMenuTrigger
  public savedMemes: number = 0

  public saveLater2() {
    this.localSaveService.getAll().pipe(take(1)).subscribe((val: GridSection[]) => {
      let isSavedGrid: boolean = false
      val.forEach((gs: GridSection) => {
        if (gs.id == this.appService.gridSection?.id) isSavedGrid = true
      })

      if (!this.saving && this.appService.gridSection?.name && (this.appService.gridSection?.name != this.appService.gridSection?.media?.name || isSavedGrid)) {
        this.save()
      } else if (!this.saving && (!this.appService.gridSection?.name || this.appService.gridSection?.name == this.appService.gridSection?.media?.name)) {
        this.showSaveInput = true
      }
    })
  }

  save(name?: string) {

    this.saving = true;
    this.isSaving.emit(true)
    if (this.appService.gridSection) {
      if (name) {
        this.appService.gridSection.name = name
        this.localSaveService.add(this.appService.gridSection).pipe(take(1)).subscribe({
          next: (value: GridSection) => {
            this.doVisualSaveRoutine()
          }
        })
      } else {
        this.localSaveService.update(this.appService.gridSection).pipe(take(1)).subscribe({
          next: (value: GridSection) => {
            this.doVisualSaveRoutine()
          }
        })
      }
    }

    /// this.localSaveService.getAll()

  }

  private getSaveMemesCount() {
    this.localSaveService.getAll().pipe(takeUntilDestroyed(this.destroyRef)).subscribe((val: GridSection[]) => {
       this.savedMemes = val.length 
       this.savedMemesAmount.emit(val.length)
      })
  }

  private doVisualSaveRoutine() {
    setTimeout(() => {
      this.menuTrigger?.openMenu()
      this.localSaveService.getAll().pipe(take(1)).subscribe((val: GridSection[]) => {
        this.savedMemes = val.length
        setTimeout(() => {
          this.saving = false
          this.isSaving.emit(false)
          this.showSaveInput = false
          this.cdr.detectChanges()
          setTimeout(() => {
            this.menuTrigger?.closeMenu()
            this.saveAndShareButton?.closeMenu()
            if (this.appService.gridSection) this.appService.gridSection.mode = Mode.DEVELOPMENT
          }, 1500);
        }, 750);
      })
    }, 500);
  }

  toggleMode() {
    //if (this._timeout) this._timeout.unsubscribe();
    switch (this.appService.gridSection?.mode) {
      case Mode.DEVELOPMENT:
        if (this.appService.gridSection) {
          this.appService.gridSection.mode = Mode.VIEWING
          this.appService.gridSection.activeAsset = undefined
        }
        break;
      case Mode.VIEWING:
        if (this.appService.gridSection) this.appService.gridSection.mode = Mode.DEVELOPMENT
        break
    }
    //this.a("Switching mode", this.appService.gridSection?.mode.toString() || 'Unkown Mode', ScreenAction.CLICK)
  }

  public downloading: boolean = false
  captureScreenshot(skipPopup?: boolean) {
    this.toggleMode();
    setTimeout(() => {
      if (this.appService.gridSection) this.appService.gridSection.downloadURL = undefined
      if (this.appService.gridSection?.children && this.appService.gridSection.children.length > 0) {
        this.imageGenerator.generateStaticImage().pipe(take(1)).subscribe({
          next: (value: ImageGenerationInfo) => {
            if (this.appService.gridSection) this.appService.gridSection.downloadURL = value.location
            this.downloading = false
            //this.toggleMode()
          }
        })
      } else {
        const mediaType: MediaType = this.appService.gridSection?.media?.mediaType || MediaType.LOCAL_IMAGE
        switch (mediaType) {
          case MediaType.LOCAL_ANIMATED_GIF:
            if (this.appService.gridSection) this.appService.gridSection.determinate = true
            this.imageGenerator.generateGif().subscribe({
              next: (value: ImageGenerationInfo) => {
                if (value.completion == 100) {
                  if (this.appService.gridSection) this.appService.gridSection.downloadURL = value.location
                  this.downloading = false
                  //this.toggleMode()
                } else {
                  //console.log(`${value.completion}% completed`)
                  if (this.appService.gridSection) this.appService.gridSection.completion = value.completion
                }
              },
              error: () => {
                //error
              },
              complete: () => {
                //done
              }
            })
            break
          case MediaType.LOCAL_IMAGE:
            this.imageGenerator.generateStaticImage().pipe(take(1)).subscribe({
              next: (value: ImageGenerationInfo) => {
                if (this.appService.gridSection) this.appService.gridSection.downloadURL = value.location
                this.downloading = false
                //this.toggleMode()
              }
            })
            break
          case MediaType.VIDEO:
            if (this.appService.gridSection) this.appService.gridSection.determinate = true
            this.imageGenerator.generateWebP().subscribe({
              next: (value: ImageGenerationInfo) => {
                if (value.completion == 100) {
                  if (this.appService.gridSection) this.appService.gridSection.downloadURL = value.location
                  this.downloading = false
                  //this.toggleMode()
                } else {
                  //console.log(`${value.completion}% completed`)
                  if (this.appService.gridSection) this.appService.gridSection.completion = value.completion
                }
              }
            })
            break
        }
      }

      if (!skipPopup) this.showDownloadPopover();
      this.downloading = true
      //this.a('Capturing Screenshot', '', ScreenAction.CAPTURE)
    }, 0);

  }


  public showDownloadPopover() {
    //this.dialog.open(DownloadImageComponent, { data: this.gridSection, hasBackdrop : true }).afterClosed().pipe(take(1)).subscribe(() => { if (this.gridSection) this.gridSection.mode = Mode.DEVELOPMENT })
    const d = this.dialog.open(DownloadImageComponent, { data: this.appService.gridSection, hasBackdrop: true, backdropClass: 'pulsing-rainbow-tiled-background' })
    d.afterClosed().pipe(take(1)).subscribe(() => { if (this.appService.gridSection) this.appService.gridSection.mode = Mode.DEVELOPMENT })
    //this is kind of a hack
    //d.componentInstance.dashboard = this
  }


}
