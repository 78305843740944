import index from '../cjs/index.js';
const {
  PostgrestClient,
  PostgrestQueryBuilder,
  PostgrestFilterBuilder,
  PostgrestTransformBuilder,
  PostgrestBuilder
} = index;
export { PostgrestBuilder, PostgrestClient, PostgrestFilterBuilder, PostgrestQueryBuilder, PostgrestTransformBuilder };

// compatibility with CJS output
export default {
  PostgrestClient,
  PostgrestQueryBuilder,
  PostgrestFilterBuilder,
  PostgrestTransformBuilder,
  PostgrestBuilder
};