import { AfterViewInit, Component, ComponentRef, DestroyRef, ElementRef, ViewChild } from '@angular/core';
import { fromEvent, merge, Subscription } from 'rxjs';
import { map, startWith, switchMap, takeUntil, tap } from 'rxjs/operators';
import { ScrubberInfo, ScrubberService } from '../../service/scrubber.service';
import { CommonModule } from '@angular/common';
import { DeviceDetectorService } from '../../service/device-detector.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Asset, AssetType } from '../../vo/GridSection';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { EditMode, ToolbarService } from '../../service/toolbar.service';
import {CdkDrag, CdkDragHandle} from '@angular/cdk/drag-drop';
import { MatChipsModule } from '@angular/material/chips';

@Component({
  selector: 'app-scrubber',
  standalone: true,
  imports: [MatChipsModule, CdkDrag, CdkDragHandle, CommonModule, MatIconModule, MatDividerModule],
  templateUrl: './scrubber.component.html',
  styleUrls: ['./scrubber.component.scss']
})
export class ScrubberComponent implements AfterViewInit {

  readonly AssetType = AssetType

  @ViewChild('scrubberArea') scrubberArea!: ElementRef;
  @ViewChild('leftScrubLocation') leftScrubLocation!: ElementRef;
  @ViewChild('rightScrubLocation') rightScrubLocation!: ElementRef;



  private moveSubscription?: Subscription;
  private endSubscription?: Subscription;
  
  //this is for the asset portion
  public collapsed : boolean = false
  //this is for the controller portion will also do assets
  private _minimized : boolean = false
  public set minimized(val : boolean){
    if(!val)this.collapsed = false
    this._minimized = val
  }
  public get minimized():boolean{
    return this._minimized
  }

  public isTouching : boolean = false

  //public showScrubbers : boolean = false

  constructor(private elRef : ElementRef, private toolbarService : ToolbarService, public scrubberService: ScrubberService, private deviceDetector: DeviceDetectorService, private destroyRef: DestroyRef) {}


  private resizeObserver ?: ResizeObserver
  ngOnDestroy(): void {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
  }
  
  ngAfterViewInit() {

    /*
   this.resizeObserver = new ResizeObserver((entries : ResizeObserverEntry[]) => {
      const rect = this.elRef.nativeElement.getBoundingClientRect()
      const isOffScreen = rect.top < 0;
      if(isOffScreen){
        this.elRef.nativeElement.style.top = '0px'
      }
    });
    */

    //this.resizeObserver.observe(this.elRef.nativeElement);

    const scrubberElement = this.scrubberArea.nativeElement;

    // Subscribe to scrubber info updates to set width
    this.scrubberService.info.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((info: ScrubberInfo | undefined) => {
      if (info) {
        this.setScrubberWidth();
        //this.showScrubbers = info.activeAsset != undefined && info.activeAsset != null
      }
    });



    this.scrubberService.setupScrubbers.asObservable().pipe(takeUntilDestroyed(this.destroyRef)).subscribe((value : Asset | undefined) => {
      if(value){
        const f : number = value.shownFrom || 0
        const u : number = value.shownUntil || 0
        requestAnimationFrame(() => {
          this.handleLeftScrub(f)
          this.handleRightScrub(f == 0 && u == 0 ? 100 : u)
        });
      }
    })

    this.setupDragEvents(this.leftScrubLocation.nativeElement, true)
    this.setupDragEvents(this.rightScrubLocation.nativeElement, false)

    if (this.deviceDetector.isTouchOnlyDevice()) {
      const touchStart$ = fromEvent<TouchEvent>(scrubberElement, 'touchstart');
      const touchMove$ = fromEvent<TouchEvent>(document, 'touchmove');
      const touchEnd$ = fromEvent<TouchEvent>(document, 'touchend');
    
      touchStart$.pipe(
        tap(() => this.isTouching = true), // Set isTouching to true on touch start
        switchMap(startEvent => {
          const startPercent = this.calculatePercent(startEvent.touches[0].clientX);
          return touchMove$.pipe(
            map(moveEvent => this.calculatePercent(moveEvent.touches[0].clientX)),
            takeUntil(touchEnd$),
            startWith(startPercent) // Emit initial percent on touch start
          );
        })
      ).subscribe(percent => {
        this.scrubberService.scrubToPercent(percent);
      });
    
      touchEnd$.pipe(
        tap(() => this.isTouching = false) // Set isTouching to false on touch end
      ).subscribe();
    
    } else {
      const mouseDown$ = fromEvent<MouseEvent>(scrubberElement, 'mousedown');
      const mouseMove$ = fromEvent<MouseEvent>(document, 'mousemove');
      const mouseUp$ = fromEvent<MouseEvent>(document, 'mouseup');
    
      mouseDown$.pipe(
        tap(() => this.isTouching = true), // Set isTouching to true on mouse down
        switchMap(startEvent => {
          const startPercent = this.calculatePercent(startEvent.clientX);
          return mouseMove$.pipe(
            map(moveEvent => this.calculatePercent(moveEvent.clientX)),
            takeUntil(mouseUp$),
            startWith(startPercent) // Emit initial percent on mouse down
          );
        })
      ).subscribe(percent => {
        this.scrubberService.scrubToPercent(percent, true);
      });
    
      mouseUp$.pipe(
        tap(() => this.isTouching = false) // Set isTouching to false on mouse up
      ).subscribe();
    }
  }

  public getReversedAssets(): any[] {
    const assets = this.scrubberService.info.value?.activeGrid?.assets || [];
    return [...assets].reverse(); // Use a spread operator to create a new reversed array
  }

  public assetBgStart(html : HTMLElement, asset : Asset){
    const toReturn : number = html.offsetWidth * ((asset.shownFrom || 0) / 100)
    //console.log("START", toReturn)
    return toReturn
  }

  public setActiveAsset(asset : Asset){
    if(this.scrubberService.info.value?.activeGrid){
      this.scrubberService.info.value.activeGrid.activeAsset = asset
      this.scrubberService.setupScrubbers.next(asset)
      if(asset && asset.editMode){
        this.toolbarService.editModeSubject.next({mode : asset.editMode, asset : asset})
      }
    }
  }

  public assetBgEnd(html : HTMLElement, asset : Asset){
    const toReturn = (html.offsetWidth * ((asset.shownUntil || 0) / 100)) - this.assetBgStart(html, asset)
    //console.log("END", toReturn)
    return toReturn
    //return (html.offsetWidth * ((asset.shownUntil || 0) / 100)) - this.assetBgStart(html, asset)
  }

  private calculatePercent(clientX: number): number {
    const rect = this.scrubberArea.nativeElement.getBoundingClientRect();
    const scrubberWidth = rect.width;
    const percent = ((clientX - rect.left) / scrubberWidth) * 100;
    return Math.max(0, Math.min(100, percent));
  }

  public scrubberWidth: number = 0;

  public setScrubberWidth() {
    const scrubberElement = this.scrubberArea.nativeElement;
    const boundingRect = scrubberElement.getBoundingClientRect()
    const scrubberWidth = boundingRect.width;
    const scrubberLeft = 0
    const currentTime = this.scrubberService.info.value?.currentTime || 0;
    const duration = this.scrubberService.info.value?.video?.duration || 0;
    const percentDone = (currentTime / duration) * 100;
    this.scrubberWidth = (percentDone / 100) * (scrubberWidth - scrubberLeft);
    //console.log("WIDTH", this.scrubberWidth, "/", scrubberWidth);
  }







  private setupDragEvents(element: HTMLElement, isLeftScrub: boolean) {
    if (this.deviceDetector.isTouchOnlyDevice()) {
      const touchStart$ = fromEvent<TouchEvent>(element, 'touchstart');
      const touchMove$ = fromEvent<TouchEvent>(document, 'touchmove');
      const touchEnd$ = fromEvent<TouchEvent>(document, 'touchend');
  
      touchStart$.pipe(
        switchMap(startEvent => {
          const startPercent = this.calculatePercent(startEvent.touches[0].clientX);
          return touchMove$.pipe(
            map(moveEvent => this.calculatePercent(moveEvent.touches[0].clientX)),
            takeUntil(touchEnd$),
            startWith(startPercent)
          );
        })
      ).subscribe(percent => {
        if (isLeftScrub) {
          this.handleLeftScrub(percent);
        } else {
          this.handleRightScrub(percent);
        }
      });
  
    } else {
      const mouseDown$ = fromEvent<MouseEvent>(element, 'mousedown');
      const mouseMove$ = fromEvent<MouseEvent>(document, 'mousemove');
      const mouseUp$ = fromEvent<MouseEvent>(document, 'mouseup');
  
      mouseDown$.pipe(
        switchMap(startEvent => {
          const startPercent = this.calculatePercent(startEvent.clientX);
          return mouseMove$.pipe(
            map(moveEvent => this.calculatePercent(moveEvent.clientX)),
            takeUntil(mouseUp$),
            startWith(startPercent)
          );
        })
      ).subscribe(percent => {
        if (isLeftScrub) {
          this.handleLeftScrub(percent);
        } else {
          this.handleRightScrub(percent);
        }
      });
    }
  }



  private handleLeftScrub(percent: number) {
    const scrubberArea = this.scrubberArea.nativeElement;
    const scrubberAreaRect = scrubberArea.getBoundingClientRect();
    const scrubberAreaWidth = scrubberAreaRect.width;
    const leftScrub = this.leftScrubLocation.nativeElement;
    const leftScrubRect = leftScrub.getBoundingClientRect();
    const leftScrubWidth = leftScrubRect.width;
    const rightScrub = this.rightScrubLocation.nativeElement;
    const rightScrubRect = rightScrub.getBoundingClientRect();
    const rightScrubLeft = rightScrubRect.left - scrubberAreaRect.left; // Convert to relative position
  
    // Calculate new position centered on the mouse
    const newPercent = Math.max(0, Math.min(100, percent));
    const desiredLeft = (newPercent / 100) * scrubberAreaWidth - (leftScrubWidth / 2);
  
    // Buffer value (adjust as needed)
    const buffer = 0; // Adjust this value as necessary
  
    // Ensure the left scrubber is within bounds and stops before the right scrubber with a buffer
    const leftPosition = Math.max(0, Math.min(desiredLeft, rightScrubLeft - leftScrubWidth - buffer));
    
    this.scrubberService.setAssetStartPercent(percent)

    leftScrub.style.left = `${leftPosition}px`;
  }
  
  private handleRightScrub(percent: number) {
    //console.log("RIGHT", percent)
    const scrubberArea = this.scrubberArea.nativeElement;
    const scrubberAreaRect = scrubberArea.getBoundingClientRect();
    const scrubberAreaWidth = scrubberAreaRect.width;

    const rightScrub = this.rightScrubLocation.nativeElement;
    const rightScrubRect = rightScrub.getBoundingClientRect();
    const rightScrubWidth = rightScrubRect.width;

    const leftScrub = this.leftScrubLocation.nativeElement;
    const leftScrubRect = leftScrub.getBoundingClientRect();
    const leftScrubRight = leftScrubRect.right - scrubberAreaRect.left; // Convert to relative position

    // Calculate new position based on percentage
    const newPercent = Math.max(0, Math.min(100, percent));
    const desiredRight = (newPercent / 100) * scrubberAreaWidth;

    // Ensure the right scrubber is within bounds
    // Buffer value (adjust as needed)
    const buffer = 0; // Adjust this value as necessary

    // Compute the final position considering the buffer and bounds
    const rightPosition = Math.min(
      scrubberAreaWidth - rightScrubWidth - buffer,
      Math.max(desiredRight - rightScrubWidth / 2, leftScrubRight + buffer)
    );

    // Set the scrubber position
    rightScrub.style.left = `${rightPosition}px`;

    // Update the service with the new percent value
    this.scrubberService.setAssetEndPercent(percent);
}
}