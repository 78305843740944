import { Component } from '@angular/core';
import {FormBuilder, FormGroup, Validators, FormsModule, ReactiveFormsModule} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import {MatTableModule} from '@angular/material/table';
import { CommonModule } from '@angular/common'
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { PaymentComponent } from '../payment/payment.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PaymentService, Subscription, SubscriptionType } from '../../service/payment.service';
import { DialogRef } from '@angular/cdk/dialog';

@Component({
  selector: 'app-upgrade',
  standalone: true,
  imports: [MatCardModule, MatTableModule, MatButtonModule, MatIconModule, MatDialogModule, MatSlideToggleModule, FormsModule, ReactiveFormsModule, MatTooltipModule],
  templateUrl: './upgrade.component.html',
  styleUrl: './upgrade.component.scss'
})
export class UpgradeComponent {
  public yearly : boolean = true

  private readonly dc : string[] = ['feature', 'free', 'basic', 'pro']
  public displayedColumns : string[] = []
  public  readonly dataSource = [
    { feature: 'All templates', free: 'Yes', basic: 'Yes', pro: 'Yes'},
    { feature: 'All stickers, emojis, shapes', free: 'Yes', basic: 'Yes', pro: 'Yes'},
    { feature: 'Disable ads', free: 'No', basic: 'Yes', pro: 'Yes'},
    { feature: 'Remove watermark', free: 'No', basic: 'Yes', pro: 'Yes'},
    { feature: 'Saving memes', free: 'No', basic: 'Yes', pro: 'Yes'},
    { feature: 'Sharing memes to social', free: 'No', basic: 'Yes', pro: 'Yes'},
    { feature: 'Create gifs', free: 'No', basic: 'No', pro: 'Yes'},
    { feature: 'Price', free: 'Free', basic: '', pro: ''},
    { feature: 'button:yearly', basic: 'button:basic', pro: 'button:pro'}
  ]

  constructor (private dialog : MatDialog, private paymentService : PaymentService, private dialogRef : DialogRef<UpgradeComponent>){
      this.updatePrices(this.yearly)
  }

  public needsPro = false
  public set allowedTiers(tiers: SubscriptionType[]) {
    const np : boolean = tiers.every(tier => tier === SubscriptionType.PRO_YEARLY || tier === SubscriptionType.PRO_MONTHLY)
    if(np){
      this.displayedColumns = this.dc.filter(item => item !== 'basic')
    }else{
      this.displayedColumns = this.dc
    }
    this.updatePrices
    this.needsPro = np
  }

  getCellClass(value: string) {
    return value === 'Yes' ? 'yes-cell' : value === 'No' ? 'no-cell' : 'default-cell';
  }

  selectPlan(plan: 'basic' | 'pro') {
    let subscription : Subscription
    const cycle : string = this.yearly ? 'yearly' : 'monthly'
    switch(plan){
      case 'basic':
        subscription = cycle == 'yearly' ? PaymentService.getSubscription(SubscriptionType.BASIC_YEARLY) : PaymentService.getSubscription(SubscriptionType.BASIC_MONTHLY)
        break
      case 'pro':
        subscription = cycle == 'yearly' ? PaymentService.getSubscription(SubscriptionType.PRO_YEARLY) : PaymentService.getSubscription(SubscriptionType.PRO_MONTHLY)
        break
    }
    this.dialogRef.close()
    this.dialog.open(PaymentComponent, {maxHeight : '90vh', width : '90vw', maxWidth : '600px', data : subscription, autoFocus : 'name'})
  }

  continueToPayment() {
    // Handle payment continuation logic
  }

  updatePrices(yearly : boolean){
    this.yearly = yearly
    if(this.yearly){
      this.dataSource[this.dataSource.length - 2].basic = `$${PaymentService.getSubscription(SubscriptionType.BASIC_YEARLY).monthlyPrice} / mo`
      this.dataSource[this.dataSource.length - 2].pro = `$${PaymentService.getSubscription(SubscriptionType.PRO_YEARLY).monthlyPrice} / mo`
    }else{
      this.dataSource[this.dataSource.length - 2].basic = `$${PaymentService.getSubscription(SubscriptionType.BASIC_MONTHLY).monthlyPrice} / mo`
      this.dataSource[this.dataSource.length - 2].pro = `$${PaymentService.getSubscription(SubscriptionType.PRO_MONTHLY).monthlyPrice} / mo`
    }
  }
}
