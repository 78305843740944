import { Observable, Subject } from "rxjs";
import { DashboardComponent } from "../component/dashboard/dashboard.component";
import { FontService } from "../service/font.service";
import { Prop, Sticker, TemplateAsset, Viewport } from "../service/sticker.service";
import { ToolbarService } from "../service/toolbar.service";
import { AssetType, GridSection } from "../vo/GridSection";
import { AspectRatio } from "../vo/Pickers";
import { booleanAttribute, NgZone } from "@angular/core";

export function createAssetsFromJSON(gs: GridSection, toolbarService: ToolbarService, fontService: FontService, sticker: Sticker | undefined, zone: NgZone, isPlatformBrowser: boolean = false, s?: Subject<boolean>): Observable<boolean> {
    const sub: Subject<boolean> = s ? s : new Subject()


    if (gs.media && isPlatformBrowser) {
        if (!gs.media.loaded) {
            zone.runOutsideAngular(() => {
                setTimeout(() => {
                    zone.run(() => {
                        createAssetsFromJSON(gs, toolbarService, fontService, sticker, zone, isPlatformBrowser, sub)
                    })
                }, 100);
            });
            return sub
        }
    }

    const stkr : Sticker | undefined = sticker ? sticker : gs.media?.sticker

    if(stkr && stkr.template && stkr.template.width && stkr.template.height) {
        const { horAR, verAR } = getSimplifiedAspectRatio(stkr.template.width, stkr.template.height)
        gs.horizontalAspectRatio = horAR
        gs.verticalAspectRatio = verAR
    } else if (sticker && sticker.template && sticker.template.aspectRatio && sticker.template.aspectRatio.includes(":")) {
        /*
            const horAR = Number(sticker.template.aspectRatio.split(":")[0])
            const verAR = Number(sticker.template.aspectRatio.split(":")[1])
            gs.horizontalAspectRatio = horAR
            gs.verticalAspectRatio = verAR
            //dashboard.setAspectRatio(new AspectRatio(horAR, verAR, sticker.template.aspectRatio))
            //gs.horizontalAspectRatio = Number(horAR)
            //gs.verticalAspectRatio = Number(verAR)
            */
    } else {
        gs.horizontalAspectRatio = 1
        gs.verticalAspectRatio = 1
    }

    const to: number = isPlatformBrowser ? 500 : 0

    setTimeout(() => {
        const currentViewPort: Viewport = { width: gs.widthInPX || 0, height: gs.heightInPX || 0 }
        if (sticker && sticker.template && sticker.template.assets) sticker.template.assets.forEach((templateAsset: TemplateAsset) => {

            const asset = gs.addAsset(AssetType.TEXT, toolbarService, false)


            const w = gs.widthInPX || 0 //gs.view?.offsetWidth || 0
            const h = gs.heightInPX || 0 //gs.view?.offsetHeight || 0

            asset.data.text = templateAsset.value
            asset.data.color = 'black'

            ////reset some stuff here
            asset.data.textStrokeWidth = 0

            if (!sticker.template.viewport) {
                //throw new Error("Must define viewport on every template".toUpperCase())
            }
            let diff
            //these props need to be set last as they are affected other props
            const p: Prop | undefined = templateAsset.props.find(obj => obj.prop === 'size')
            if (p) {
                const val: number = Number(p.value)
                const adjusted: number = adjustNumber(currentViewPort, sticker.template.viewport, val)
                diff = adjusted - val
                asset.data.size = adjusted
                asset.data.height = adjusted + 10
            } else {
                throw new Error("Must defined size property in template".toUpperCase())
            }

            templateAsset.props.forEach((prop: Prop) => {
                switch (prop.prop) {
                    case "width":
                        asset.data.width = w * (Number(prop.value) / 100)
                        break
                    case "x":
                    case "y":
                        asset.data.transform = updateTransformWithDOMMatrix(
                            asset.data.transform || '',
                            prop.prop,
                            prop.value as string,
                            w,
                            h,
                            diff
                        );
                        break;
                    case "color":
                        asset.data.color = String(prop.value)
                        break
                    case "backgroundColor":
                        asset.data.backgroundColor = String(prop.value)
                        break
                    case "size":
                        break
                    case "fontFamily":
                        fontService.loadFont({ name: String(prop.value) })
                        asset.data.fontFamily = String(prop.value)
                        break
                    case "transform":
                        asset.data.transform = String(prop.value)
                        break
                    case "textStrokeWidth":
                        const strokeVal: number = Number(prop.value)
                        const adjustedStokeVal: number = adjustNumber(currentViewPort, sticker.template.viewport, strokeVal)
                        asset.data.textStrokeWidth = adjustedStokeVal
                        break
                    case "centerHorizontally":
                        asset.data.centerHorizontally = prop.value === "true";
                        break
                }
            })
        })
        gs.activeAsset = undefined
        sub.next(true)
    }, to);
    if (!sticker) sub.next(false)
    return sub
}
function updateTransformWithDOMMatrix(
    transform: string,
    type: "x" | "y",
    value: string,
    w: number,
    h: number,
    diff: number
): string {
    // Create a new DOMMatrix from the existing transform
    let matrix = new DOMMatrix(transform);

    switch (type) {
        case "x":
            matrix.e = w * (Number(value) / 100); // Update translateX
            break;
        case "y":
            matrix.f = (h * (Number(value) / 100)) + (diff / 2); // Update translateY
            break;
    }

    // Return the updated transform value
    return matrix.toString();
}

export function adjustNumber(currentViewport: Viewport, targetViewport?: Viewport, number: number = 0): number {
    if(targetViewport){
        const widthScalingFactor = currentViewport.width / targetViewport.width;
        const heightScalingFactor = currentViewport.height / targetViewport.height;

        // Use the larger scaling factor to decrease the size proportionally
        const scalingFactor = Math.max(widthScalingFactor, heightScalingFactor);

        // Adjust the number (e.g., font size)
        const adjustedNumber = number * scalingFactor;

        return adjustedNumber;
    }else{
        return number
    }
}

function gcd(a: number, b: number): number {
    return b === 0 ? a : gcd(b, a % b)
}

function getSimplifiedAspectRatio(width: number, height: number): { horAR: number, verAR: number } {
    const divisor = gcd(width, height)
    const horAR = width / divisor
    const verAR = height / divisor
    return { horAR, verAR }
}


/*

text ?: string
    color ?: string
    fontFamily ?: string
    editing : boolean
    size ?: number
    fontWeight ?: number
    backgroundColor ?: string
    borderColor ?: string
    borderWidth ?: number
    padding ?: number
    lineHeight ?: number
    letterSpacing ?: number
    wordSpacing ?: number
    textDecorationStyle ?: string
    textDecorationLine ?: string
    textStrokeWidth ?: number
    textStrokeColor ?: string
    blendMode ?: string
    filter ?: string
    centerVertically ?: boolean
    centerHorizontally ?: boolean
    applyTextShadow ?: boolean
    */